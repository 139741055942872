import { MAPPING_EMPLOYEE_FIELDS } from './InsertVariablesButton.constants';

export const extractAndMapVariablesOptimized = (text) => {
  const regex = /\[(.*?)\]/g;
  const result = new Map();
  let match;

  while ((match = regex.exec(text)) !== null) {
    const variable = match[1];
    if (MAPPING_EMPLOYEE_FIELDS.hasOwnProperty(variable)) {
      const mappedKey = MAPPING_EMPLOYEE_FIELDS[variable].key;
      result.set(mappedKey, (result.get(mappedKey) || 0) + 1);
    }
  }

  return {
    uniqueVariables: Array.from(result.keys()),
    variableCounts: Object.fromEntries(result),
  };
};
