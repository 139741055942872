//@ts-check
import { useState } from 'react';
import { IconButton } from '../../../../../../../newComponents/IconButton';
import { Popover } from '../../../../../../../newComponents/Popover';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../../../newComponents/Typography';
import { Timeline } from '../../../../../../../newComponents/Timeline';
import { HistoryTemplateItem } from './HistoryTemplateItem';
import { useScreenSize } from '../../../../../../../Hooks';
import { HistoryTemplateMobile } from './HistoricalTemplateMobile';

export const HistoricalComponent = ({ template }) => {
  const { isMobile } = useScreenSize();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'templates-simple-popover' : undefined;

  if (!template) return null;

  let history = template?.history ?? [];

  if (template?.status === 'PUBLISHED') {
    history = history.filter((item) => item.action === 'PUBLISHED');
  }

  if (isMobile) {
    return (
      <Stack>
        <IconButton
          icon={
            template?.status === 'PUBLISHED'
              ? 'information_line'
              : 'chat_4_line'
          }
          onClick={handleClick}
        />
        <HistoryTemplateMobile
          open={open}
          onClose={handleClose}
          history={history}
          template={template}
        />
      </Stack>
    );
  }

  return (
    <>
      <IconButton
        icon={
          template?.status === 'PUBLISHED' ? 'information_line' : 'chat_4_line'
        }
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        arrowDirection="left"
        sx={{}}
      >
        <Stack p={3} spacing={3} sx={{ width: 350, height: 250 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h6">{template?.title}</Typography>
            </Stack>
            <IconButton icon="close_line" onClick={handleClose} />
          </Stack>
          <Stack height={400} overflow="auto">
            <Timeline
              sx={{
                '& .MuiTimelineItem-root:before': {
                  flex: 0,
                  padding: 0,
                },
                '&.MuiTimeline-root': {
                  padding: 0,
                },
              }}
            >
              {history?.map((item, index) => (
                <HistoryTemplateItem
                  key={index}
                  action={item.action}
                  comments={item.comments}
                  createdBy={item.createdBy}
                  timestamps={item.timestamps}
                />
              ))}
            </Timeline>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
