//@ts-check
import { useState } from 'react';
import { templatesMenuActionsItems } from '../Templates.constants';
import { MenuItem } from '../../../../../../newComponents/MenuItem';
import { ListItemIcon } from '../../../../../../newComponents/ListItemIcon';
import { Icon } from '../../../../../../components/Icons/Icons';
import { ListItemText } from '../../../../../../newComponents/ListItemText';
import { Typography } from '../../../../../../newComponents/Typography';
import { usePermissionChecker } from '../../../../../../Hooks';
import { globalSnackbarVar } from '../../../../../../cache.reactiveVars';
import { IconButton } from '../../../../../../newComponents/IconButton';
import { Menu } from '../../../../../../newComponents/Menu';
import { useGetTemplate } from '../../../../../RHPod/Documents/Templates/TemplateHooks';
import { useBlockableActions } from '../../../../../../components/ModuleBlockableComponent/useBlockableActions';
import { SORA_MODULES } from '../../../../../../components/ModuleBlockableComponent/ModuleBlockable.constants';
import { buildTemplateToUpdate } from '../Templates.helpers';
import {
  deleteTemplateDialogVar,
  updateTemplateDialogVar,
} from '../Templates.vars';
import { Tooltip } from '../../../../../../newComponents/Tooltip';
import { Stack } from '@mui/material';

export const TemplatesActions = ({ template }) => {
  const userHasPermissionsToUpdate = usePermissionChecker({
    permission: 'templates',
    action: 'update',
  });
  const { handleGetTemplate } = useGetTemplate();
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    onClick: documentsModuleIsBlockedOnClick,
    isModuleAvailable: isDocumentsModuleAvailable,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (anchorEl) return setAnchorEl(null);
    setAnchorEl(event.currentTarget);
  };

  const handleAction = async (action) => {
    if (!isDocumentsModuleAvailable) {
      return documentsModuleIsBlockedOnClick();
    }

    if (!action) return setAnchorEl(null);
    if (!template) {
      globalSnackbarVar({
        show: true,
        message: 'No se ha seleccionado ninguna plantilla',
        severity: 'error',
        duration: 4000,
      });
      return setAnchorEl(null);
    }
    if (action === 'SETTINGS' || action === 'CONTENT') {
      await handleGetTemplate({
        templateId: template._id,
        onSuccess: (info) => {
          updateTemplateDialogVar({
            open: true,
            currentTemplate: buildTemplateToUpdate({ template: info }),
            updateType: action,
          });
        },
      });
      setAnchorEl(null);
      return;
    }

    if (action === 'DELETE') {
      deleteTemplateDialogVar({
        open: true,
        templateId: template._id,
      });
      setAnchorEl(null);
    }
  };

  return (
    <Stack>
      <IconButton id="basic-menu" icon="more_2_line" onClick={handleClick} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClick}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {templatesMenuActionsItems.map((action, i) =>
          template?.name === 'COMPLETED' &&
          action?.action !== 'DELETE' ? null : (
            <Tooltip
              key={i}
              arrow
              placement="top"
              title={
                userHasPermissionsToUpdate
                  ? ''
                  : 'No tienes permiso para esta acción'
              }
            >
              <Stack>
                <MenuItem
                  key={i}
                  onClick={() => handleAction(action.action)}
                  disabled={!userHasPermissionsToUpdate}
                >
                  <ListItemIcon>
                    <Icon icon={action.icon} color={action.color} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2" color={action.color}>
                      {action.label}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </Stack>
            </Tooltip>
          ),
        )}
      </Menu>
    </Stack>
  );
};
