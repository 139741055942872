import { ApproveOrRejectTemplateActions } from './TemplatesPreview/ApproveOrRejectTemplateActions';
import { PublishTemplateAction } from './TemplatesPreview/PublishTemplateAction';

/**
 * @param {object} params
 * @param {import('./Templates.types').AuthorizerType} params.admin
 * @param {object} params.statusAuth
 * @returns {import('./Templates.types').AuthorizerType}
 */
export const buildAdminAuthorizer = ({ admin, statusAuth }) => {
  if (!admin) return null;
  return {
    _id: admin?._id || admin?.user?._id,
    user: {
      _id: admin?._id || admin?.user?._id,
      names: admin?.user?.names,
      lastNameP: admin?.user?.lastNameP,
      lastNameM: admin?.user?.lastNameM ?? '',
      rfc: admin?.user?.rfc || '',
      curp: admin?.user?.curp || '',
      fullName: admin?.user?.fullName || '',
      profilePicture: admin?.user?.profilePicture || '',
      workSpaceStatus: admin?.user?.workSpaceStatus || '',
    },
    status: statusAuth || '',
  };
};

/**
 * @param {object} params
 * @param {object} params.template
 * @returns {import('./Templates.types').TemplateReturnType|{}}
 */
export const buildTemplateToUpdate = ({ template }) => {
  if (!template) return {};
  return {
    _id: template._id,
    title: template.title,
    description: template?.description,
    color: template.color?.primary,
    secondaryColor: template.color?.secondary,
    category: template.categories?.category,
    subcategory: template.categories?.subcategory,
    myEditor: template.htmlContent || '',
    authorizers: template?.authorizers || template?.authorizations || [],
    authorizeInOrder: Boolean(template?.orderConfig?.authorizeInOrder),
  };
};

/**
 * @param {object} params
 * @param {import('./Templates.types').AuthorizerType[]} params.oldAuths
 * @param {import('./Templates.types').AuthorizerType[]} params.currAuths
 * @param {boolean} params.oldOrder
 * @param {boolean} params.currOrder
 * @returns {boolean}
 */
export const verifyIfAuthorizersIsDirty = ({
  oldAuths = [],
  currAuths = [],
  oldOrder,
  currOrder,
}) => {
  const booleanOldOrder = Boolean(oldOrder);
  const booleanCurrOrder = Boolean(currOrder);

  if (booleanOldOrder !== booleanCurrOrder) return true;

  const oldIds = oldAuths.map((auth) => auth?._id || auth?.user?._id);
  const currIds = currAuths.map((auth) => auth?._id || auth?.user?._id);

  if (!oldIds?.length && currIds?.length) return true;
  if (oldIds?.length && !currIds?.length) return true;
  if (oldIds?.length !== currIds?.length) return true;

  if (JSON.stringify(oldIds) !== JSON.stringify(currIds)) return true;

  return false;
};

/**
 * @param {object} params
 * @param {import('./Templates.types').AuthorizerType[]} params.authorizers
 * @returns {import('./Templates.types').AuthorizerType[]}
 */
export const sortAuthorizersByStatus = ({ authorizers = [] }) => {
  const sorted = authorizers?.sort((a, b) => {
    if (a.status === 'AUTHORIZED' && b.status !== 'AUTHORIZED') {
      return -1;
    }

    if (a.status !== 'AUTHORIZED' && b.status === 'AUTHORIZED') {
      return 1;
    }

    const indexA = authorizers.findIndex(
      (item) => item.user?._id === a.user?._id,
    );
    const indexB = authorizers.findIndex(
      (item) => item?.user?._id === b.user?._id,
    );
    return indexA - indexB;
  });

  return sorted;
};

export const sortAuthorizersDefault = ({ oldAuths = [], currAuths = [] }) => {
  const sorted = currAuths.sort((a, b) => {
    const indexA = oldAuths.findIndex((item) => item?.user?._id === a._id);
    const indexB = oldAuths.findIndex((item) => item?.user?._id === b._id);

    // Si ambos elementos están en oldAuths, ordenarlos según ese orden
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // Si solo uno de los elementos está en oldAuths, ponerlo primero
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    // Si ninguno de los elementos está en oldAuths, mantener su orden relativo
    return 0;
  });

  return sorted;
};

/**
 * @param {object} params
 * @param {boolean} params.userHasPermissionToAuthorize
 * @param {boolean} params.isOrdered
 * @param {import('./Templates.types').AuthorizerType} params.currAuthorizer
 * @param {import('./Templates.types').TemplateOrderConfigType} params.orderConfig
 * @returns {object}
 */
export const helperMessage = ({
  userHasPermissionToAuthorize,
  isOrdered,
  currAuthorizer,
  orderConfig,
}) => {
  if (!userHasPermissionToAuthorize) {
    return {
      disabled: true,
      message: 'No tienes permisos para autorizar plantillas',
    };
  }

  if (isOrdered && currAuthorizer && currAuthorizer?.status === 'APPROVED') {
    return {
      disabled: true,
      message: 'Aprobada',
    };
  }

  if (isOrdered && currAuthorizer) {
    const isCurrenAuth =
      orderConfig?.currentAuthorizer === currAuthorizer?.user?._id;

    if (!isCurrenAuth) {
      return {
        disabled: true,
        message: 'No es tu turno para autorizar',
      };
    }
  }

  if (currAuthorizer && currAuthorizer?.status !== 'PENDING') {
    return {
      disabled: true,
      message: 'Ya has autorizado o rechazado esta plantilla',
    };
  }

  return {
    disabled: false,
    message: '',
  };
};

/**
 * @param {object} params
 * @param {boolean} params.userHasPermissionToAuthorize
 * @param {string} params.status
 * @returns {object}
 */
export const helperMessagePublishTemplate = ({
  userHasPermissionToAuthorize,
  status,
}) => {
  if (!userHasPermissionToAuthorize) {
    return {
      disabled: true,
      message: 'No tienes permisos para esta acción',
    };
  }

  if (status !== 'READY_TO_PUBLISH') {
    return {
      disabled: true,
      message: 'La plantilla no está lista para publicar',
    };
  }

  return {
    disabled: false,
    message: '',
  };
};

/**
 * @param {object} params
 * @param {import('./Templates.types').TemplateReturnType} params.template
 * @param {Function} params.approveTemplate
 * @param {Function} params.rejectTemplate
 * @param {Function} params.onOpenTemplate
 */
export const RenderTemplatePreviewItem = ({
  template,
  approveTemplate,
  rejectTemplate,
  onOpenTemplate,
}) => {
  if (!template) return null;
  if (template?.status === 'PUBLISHED' && onOpenTemplate) {
    return (
      <PublishTemplateAction
        template={template}
        onOpenTemplate={onOpenTemplate}
      />
    );
  }

  if (template?.status === 'PUBLISHED') return null;

  if (template?.status === 'PENDING') {
    return (
      <ApproveOrRejectTemplateActions
        template={template}
        approveTemplate={approveTemplate}
        rejectTemplate={rejectTemplate}
      />
    );
  }

  if (template?.status === 'READY_TO_PUBLISH') {
    return (
      <PublishTemplateAction
        template={template}
        onOpenTemplate={onOpenTemplate}
      />
    );
  }

  return null;
};
