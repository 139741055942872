//@ts-check
export const ROW_HEIGHT = 72;

/** @type {import('./Templates.types').AuthorizationsStatusObjectType} */
export const authorizationStatus = {
  PENDING: {
    color: 'default',
    text: 'Pendiente de autorizar',
    colorText: 'warning',
  },
  REJECTED: {
    color: 'error',
    text: 'Rechazado:',
    colorText: 'error',
  },
  AUTHORIZED: {
    color: 'success',
    text: 'Autorizado:',
    colorText: 'success',
  },
};

/** @type {import('./Templates.types').GlobalStatusAuthorizersObjectType} */
export const globalStatusAuthorizers = {
  PENDING: {
    text: 'Esperando autorizadores',
    color: 'warning',
    icon: 'time_line',
  },
  REJECTED: {
    text: 'Rechazado',
    color: 'error',
    icon: 'close_circle_line',
  },
  READY_TO_PUBLISH: {
    text: 'Listo para publicar',
    color: 'success',
    icon: 'checkbox_circle_line',
  },
  PUBLISHED: {
    text: 'Publicado',
    color: 'success',
    icon: 'checkbox_circle_line',
  },
};

/**
 * @param {object} params
 * @param {import('../../../../../theme').CustomTheme} params.theme
 * @returns {import('./Templates.types').TemplateTabItem[]}
 */
export const TEMPLATES_TABS = ({ theme }) => [
  {
    label: 'Pendientes por revisar',
    key: 'PENDING',
    color: 'warning',
    textColor: theme.newPalette.warning.dark,
  },
  {
    label: 'Listas para publicar',
    key: 'READY_TO_PUBLISH',
    color: 'default',
    textColor: theme.newPalette.grey.grey600,
  },
  {
    label: 'Publicadas',
    key: 'PUBLISHED',
    color: 'success',
    textColor: theme.newPalette.success.dark,
  },
];

/** @type {import('./Templates.types').TemplatesMenuActionsItems} */
export const templatesMenuActionsItems = [
  {
    label: 'Configurar plantilla',
    icon: 'pencil_line',
    color: 'text',
    action: 'SETTINGS',
  },
  {
    label: 'Editar contenido de plantilla',
    icon: 'edit_box_line',
    color: 'text',
    action: 'CONTENT',
  },
  {
    label: 'Eliminar',
    icon: 'delete_bin_line',
    color: 'error',
    action: 'DELETE',
  },
];

export const TEMPLATE_ACTIONS = [
  'SENT',
  'UPDATED_AFTER_REJECTED',
  'UPDATED_WITH_PENDING_AUTHS',
  'UPDATED_AFTER_IS_READY_TO_PUBLISH',
  'UPDATED_AFTER_IS_PUBLISHED',
];
