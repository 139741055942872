export const documentTypeContainer = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '25px',
};

export const categoryBoxContainer = {
  borderRadius: '6px 0px 0px 6px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const categoryContainer = {
  paddingLeft: '10px',
  paddingRight: '10px',
  height: 'auto',
};

export const subcategoryBoxContainer = {
  borderRadius: '0px 6px 6px 0px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const subcategoryContainter = {
  paddingLeft: '10px',
  paddingRight: '10px',
  height: 'auto',
};
