// @ts-check
import { Dialog } from '../../../newComponents/Dialog';
import { DialogTitle, Stack } from '@mui/material';
import {
  DefaultErrorComponent,
  ErrorBoundary,
} from '../../../components/ErrorBoundary/ErrorBoundary';
import {
  documentCreatorDialogVar,
  resetDocumentCreatorDialog,
  setDocumentCreatorDialogVar,
} from './DocumentCreatorDialog.vars';
import { useReactiveVar } from '@apollo/client';
import { Typography } from '../../../newComponents/Typography';
import ConfirmationDialog from '../../../components/Dialogs/ConfirmationDialog';
import DocumentCreator from '../../../containers/RHPod/Documents/DocumentCreator/DocumentCreator';
import { IconButton } from '../../../newComponents/IconButton';
import { resetSelectTemplateToInsertVar } from '../../../containers/MyCompany/Settings/Preferences/Templates/Templates.vars';

export function DocumentCreatorDialog() {
  const documentCreatorDialog = useReactiveVar(documentCreatorDialogVar);
  const { open, openConfirmDialog, onUploadSuccess } = documentCreatorDialog;

  const handleCloseDocument = (confirm) => {
    setDocumentCreatorDialogVar({ openConfirmDialog: false });
    resetSelectTemplateToInsertVar();
    if (confirm) {
      resetDocumentCreatorDialog();
      resetSelectTemplateToInsertVar();
    }
  };

  /** @type {import('../../../theme').CustomTheme} */
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={() => resetDocumentCreatorDialog()}
      open={open}
      slideMode
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton
            icon="close_line"
            onClick={() =>
              setDocumentCreatorDialogVar({ openConfirmDialog: true })
            }
          />
          <Typography variant="h6" sx={{ pl: 2 }}>
            Crear documento
          </Typography>
        </Stack>
      </DialogTitle>
      <>
        <ErrorBoundary fallback={<DefaultErrorComponent />}>
          <DocumentCreator
            handleConfirmClose={() =>
              setDocumentCreatorDialogVar({ openConfirmDialog: true })
            }
            handleCloseAll={resetDocumentCreatorDialog}
            onUploadSuccess={onUploadSuccess}
          />
        </ErrorBoundary>
        <ConfirmationDialog
          open={openConfirmDialog}
          onClose={handleCloseDocument}
          title="¿Quieres salir del creador de documentos"
          content="Tu progreso no se guardará y tendrás que comenzar de nuevo"
          cancelLabel="Volver"
          acceptLabel="Salir"
        />
      </>
    </Dialog>
  );
}
