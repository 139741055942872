//@ts-check
import { Card, Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import { Fragment, useState } from 'react';
import { Avatar } from '../../../../../newComponents/Avatar/Avatar';
import { Popover } from '../../../../../newComponents/Popover';
import { Typography } from '../../../../../newComponents/Typography/Typography';
import { CardHeader } from '../../../../Landing/Development/Components/Card/CardHeader';
import { Chip } from '../../../../../newComponents/Chip';
import { useQuery } from '@apollo/client';
import { GET_EMPLOYEES_BY_COLLECTIVE_COUNT } from '../../gql';

/**
 * @typedef {import('../../../../../theme').CustomTheme} CustomTheme
 */

const stylesCard = (width, rows) => {
  const cardSx = {
    overflow: 'initial',
    borderRadius: '10px',
    minWidth: width,
    maxWidth: width,
    ...(!rows && {
      height: 117,
    }),
    ...(rows && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    }),
  };
  return cardSx;
};

const CustomAvatarPopover = ({
  palette,
  open,
  anchorEl,
  handlePopoverClose,
}) => {
  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
      arrowDirection="right"
    >
      <Stack>
        <Typography variant="subtitle2">Firmado aleatorio</Typography>
        <Stack px={3}>
          <ul className="bullets">
            <li>
              <Typography variant="body2" color={palette.text.secondary}>
                Se crea una copia del documento por cada empleado
              </Typography>
            </li>
            <li>
              <Typography variant="body2" color={palette.text.secondary}>
                El firmante de empresa debe firmar cada copia
              </Typography>
            </li>
          </ul>
        </Stack>
      </Stack>
    </Popover>
  );
};

export const TableCardHeader = ({
  showCardHeader,
  width,
  rows,
  totalCompanyEmployees,
  cardTitle,
  cardSubHeader,
  headerBgColor,
  orderSignature,
  avatar,
  success = false,
}) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;

  const companySignersCount = rows.filter(
    (row) => row.type === 'companySigners',
  ).length;

  const rowsGroupedByType = rows.reduce((acc, row) => {
    const rowId = ['Group', 'WorkTitle'].includes(row.type)
      ? row.name
      : row._id;

    if (acc[row.type]?.length > 0) {
      acc[row.type].push(rowId);
    } else {
      acc[row.type] = [rowId];
    }

    return acc;
  }, {});

  const { data } = useQuery(GET_EMPLOYEES_BY_COLLECTIVE_COUNT, {
    variables: {
      input: {
        employees: rowsGroupedByType.Employee,
        workCenterIds: rowsGroupedByType.WorkCenter,
        groups: rowsGroupedByType.Group,
        workTitles: rowsGroupedByType.WorkTitle,
      },
    },
  });

  const totalSigners =
    (rowsGroupedByType['ALL_EMPLOYEES']
      ? totalCompanyEmployees
      : (data?.getEmployeesByCollectiveCount ?? 0)) + companySignersCount;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Card sx={stylesCard(width, rows)}>
      <CardHeader
        sx={{
          '&.MuiCardHeader-root': {
            py: 3,
            pl: 3,
            pr: 2,
            '& .MuiCardHeader-action': {
              margin: 0,
            },
            borderBottom: `1px solid ${palette.grey.transparent24}`,
          },
        }}
        title={<Typography variant="subtitle2">{cardTitle}</Typography>}
        subheader={
          <Typography variant="body2" color={palette.text.secondary}>
            {cardSubHeader}
          </Typography>
        }
        avatar={
          <Fragment>
            <Avatar
              sx={{
                '&.MuiAvatar-colorDefault': {
                  backgroundColor: headerBgColor,
                },
              }}
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={orderSignature ? undefined : handlePopoverOpen}
              onMouseLeave={orderSignature ? undefined : handlePopoverClose}
            >
              {avatar}
            </Avatar>
            <CustomAvatarPopover
              palette={palette}
              open={open}
              anchorEl={anchorEl}
              handlePopoverClose={handlePopoverClose}
            />
          </Fragment>
        }
        action={
          <Chip
            label={`${totalSigners} firmantes`}
            variant="soft"
            size="small"
            color={success ? 'success' : 'primary'}
          />
        }
      />
    </Card>
  );
};
