import { Collapse, Stack, useTheme } from '@mui/material';
import { Button } from '../../../../../../newComponents/Button';
import { IconButton } from '../../../../../../newComponents/IconButton';
import type { SelectedUsersProps } from '../../CreateDocumentDialog.types';
import { getUserIdByType } from '../../CreateDocumentDialog.helpers';

export const SelectedUsers = (props: SelectedUsersProps) => {
  const {
    users = [],
    setUsers,
    selected = [],
    setSelected,
    tab = 'success',
    defaultEmployee = null,
  } = props;
  const theme = useTheme();

  const removeAllItems = () => {
    const filteredItems = users.filter((user) => {
      const userId = getUserIdByType(user);
      return !selected.includes(userId) || userId === defaultEmployee?.userId;
    });

    const selectedItems = selected.filter((selectedId) => {
      return (
        !users.some((user) => selectedId === getUserIdByType(user)) ||
        selectedId === defaultEmployee?.userId
      );
    });

    setUsers(filteredItems, tab);
    setSelected(selectedItems, tab);
  };

  return (
    <Collapse in={Boolean(selected.length > 0)}>
      <Stack
        minHeight="76px"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          backgroundColor: theme.newPalette.primary.lighter,
        }}
        px={selected?.length ? 2 : 3}
      >
        <Button
          variant="text"
          color="primary"
          size="large"
          sx={{
            '&.MuiButton-textPrimary': {
              padding: 0,
              pointerEvents: 'none',
            },
          }}
        >
          {selected.length} firmantes seleccionados
        </Button>
        <IconButton
          onClick={removeAllItems}
          icon="delete_bin_line"
          color="error"
        />
      </Stack>
    </Collapse>
  );
};
