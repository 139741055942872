// @ts-check
import React from 'react';
import { Box, Stack } from '@mui/material';
import { Tooltip } from '../../newComponents/Tooltip';
import { Typography } from '../../newComponents/Typography';
import { EmployeeAvatar } from '../EmployeeAvatar';

const defaultSx = {
  minWidth: 0,
  maxWidth: '100%',
  textOverflow: 'ellipsis',
};

/** @param {import('./EmployeeListItem.types').EmployeeListItemProps} props */
export const EmployeeListItem = (props) => {
  const {
    name,
    secondaryText,
    EmployeeAvatarProps = {},
    PrimaryTextProps = {},
    SecondaryTextProps = {},
  } = props;

  const { sx: primaryTextSx, ...primaryTextProps } = PrimaryTextProps;
  const { sx: secondaryTextSx, ...secondaryTextProps } = SecondaryTextProps;

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      gap={2}
      width="100%"
      flexWrap="nowrap"
    >
      <EmployeeAvatar name={name} {...EmployeeAvatarProps} />
      <Box flexGrow={1} minWidth={0} overflow="hidden">
        <Tooltip title={name} placement="top">
          <Box width="fit-content" maxWidth="100%">
            <Typography
              variant="subtitle2"
              noWrap
              {...primaryTextProps}
              sx={[
                defaultSx,
                ...(Array.isArray(primaryTextSx)
                  ? primaryTextSx
                  : [primaryTextSx]),
              ]}
            >
              {name}
            </Typography>
          </Box>
        </Tooltip>
        {secondaryText && (
          <Typography
            variant="body2"
            color="text.secondary"
            {...secondaryTextProps}
            sx={[
              defaultSx,
              ...(Array.isArray(secondaryTextSx)
                ? secondaryTextSx
                : [secondaryTextSx]),
            ]}
          >
            {secondaryText}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
