import { TableCell, TableRow, styled } from '@mui/material';

/**
 * @param {object} params
 * @param {import('../../../../../theme').CustomTheme} params.theme
 * @returns {object}
 */
export const gridTemplatesTableStyle = ({ theme }) => {
  return {
    backgroundColor: theme.newPalette.background.neutral,
    borderRadius: '0px 0px 12px 12px',
    '& .MuiDataGrid-main': {
      padding: '0px 10px',
      borderRadius: '8px',
    },
    '& .MuiDataGrid-row': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.newPalette.common.white,
      borderRadius: '8px',
      minHeight: '60px !important',
      maxHeight: '60px !important',
      marginBottom: '10px',
    },
    '& .MuiDataGrid-columnHeaders': {
      paddingLeft: '10px',
      borderBottom: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
      borderTop: 'none',
    },
  };
};

export const AuthorizerTableRow = styled((props) => (
  <TableRow {...props} component="div" />
))(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const AuthorizersTableCell = styled((props) => (
  <TableCell {...props} component="div" />
))(() => ({
  display: 'flex',
  alignItems: 'center',
  border: 'none',
}));

/**
 * @returns {object}
 */
export const menuTemplatesActionsStyles = {
  '.MuiDialog-paper': {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 'auto',
    m: 0,
    borderRadius: '8px',
    width: '100%',
  },
};
