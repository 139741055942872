import { Box, Card, Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Avatar } from '../../../../../newComponents/Avatar';
import { Icon } from '../../../../../components/Icons/Icons';
import { Typography } from '../../../../../newComponents/Typography';
import { Button } from '../../../../../newComponents/Button';
import { ScrollableTable } from '../../../../../newComponents/ScrollableTable';
import { VERIFY_EMPLOYEES_COLUMNS } from './VerifyEmployeesColumns';
import { downloadCsvFile } from '../../../../../businessComponents/Dialogs/SingleDocumentUploadDialog/SingleDocumentUploadErrorsTable.helpers';
import {
  createDocumentConterVar,
  setSelectedEmployeeWithErrorVar,
} from '../CreateDocumentDialog.vars';
import {
  resetEmployeeInfoDialogVar,
  setEmployeeInfoDialogVar,
} from '../../../Employees/Employees.vars';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { useScreenSize } from '../../../../../Hooks';
import { IconButton } from '../../../../../newComponents/IconButton';
import type { CreateDocumentVerifyEmployeesProps } from '../CreateDocumentDialog.types';

const SELECTION_INFO_STATE = {
  allSelected: false,
  totalIdsSelected: 0,
  idsChecked: [],
  idsUnchecked: [],
};

export const CreateDocumentVerifyEmployees = (
  props: CreateDocumentVerifyEmployeesProps,
) => {
  const { errors = [] } = props;
  const theme = useTheme();
  const { isMobile } = useScreenSize();

  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);

  const [selectionInfo, setSelectionInfo] = useState(SELECTION_INFO_STATE);

  const handleInfoDialog = (item) => {
    if (!item?.employee?.user?._id || !item?.employee?._id) return;
    setSelectedEmployeeWithErrorVar(item);
    setEmployeeInfoDialogVar({
      open: true,
      employeeId: item.employee?.user._id,
      tab: 'generalData',
      employeeWorkSpaceId: item.employee._id,
      onClose: resetEmployeeInfoDialogVar,
    });
  };

  const getCsvFile = () => {
    const csvData = [
      [
        'Nombre',
        'Apellido Paterno',
        'Apellido Materno',
        'RFC',
        'CURP',
        'Datos faltantes',
      ],
    ];

    errors.forEach((item) => {
      const { employee } = item;
      const user = employee?.user;
      if (user) {
        const errorsJoined = item.errors?.length
          ? item.errors?.join(' | ')
          : '';

        csvData.push([
          user.names,
          user.lastNameP,
          user.lastNameM,
          user.rfc,
          user.curp,
          errorsJoined,
        ]);
      }
    });

    const csvContent = csvData.map((row) => row.join(',')).join('\n');
    downloadCsvFile(csvContent, `lista-de-errores`);
  };

  const handleCloseDownloadDialog = () => {
    setOpenDownloadDialog(false);
  };

  useEffect(() => {
    if (!errors.length) {
      createDocumentConterVar('SIGNERS');
    }
  }, [errors]);

  const columns = VERIFY_EMPLOYEES_COLUMNS({ total: 0, handleInfoDialog });

  const height = errors?.length ? Math.min(errors.length * 76, 800) : 800;

  return (
    <Stack
      component={Card}
      sx={{ borderRadius: 3, boxShadow: theme.newPalette.shadow.card }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={3}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            color="error"
            sx={{
              '&.MuiAvatar-colorError': {
                background: theme.newPalette.error.transparent24,
                color: theme.newPalette.error.main,
              },
            }}
          >
            <Icon icon="error_warning_line" height="20px" color="error" />
          </Avatar>
          <Stack>
            <Typography variant="subtitle2">Errores encontrados</Typography>
            {!isMobile && (
              <Typography variant="body2" color="text.secondary">
                Puedes dar clic en un empleado y completar su información o dar
                clic en botón descargar tabla de errores.
              </Typography>
            )}
          </Stack>
        </Stack>
        {isMobile ? (
          <IconButton
            icon="file_excel_2_line"
            color="text"
            onClick={() => setOpenDownloadDialog(true)}
          />
        ) : (
          <Button
            variant="outlined"
            color="default"
            startIcon={<Icon icon="file_excel_2_line" />}
            onClick={() => setOpenDownloadDialog(true)}
          >
            Descargar excel de errores
          </Button>
        )}
      </Stack>

      <Box height={height + 60}>
        <ScrollableTable
          columns={columns}
          rows={errors}
          rowHeight={76}
          totalRows={errors?.length || 0}
          onLoadMore={() => {}}
          getRowId={(row) => row?.employee?._id || row?.employee?.user?._id}
          checkboxSelectionState={selectionInfo}
          onCheckboxSelectionChange={setSelectionInfo}
          checkboxSelection={false}
          addHoverRowStyle
        />
      </Box>

      <ConfirmDialog
        open={openDownloadDialog}
        onClose={handleCloseDownloadDialog}
        confirmLabel="Descargar"
        cancelLabel=""
        onCancel={handleCloseDownloadDialog}
        onConfirm={() => {
          getCsvFile();
          handleCloseDownloadDialog();
        }}
        title="Ignorar errores"
        confirmButtonToRight
        handleCloseIconButton
        DialogProps={{
          fullScreen: false,
        }}
      >
        <Stack>
          <Typography variant="body1" color="text.secondary">
            1. Descargar la plantilla
          </Typography>
          <Typography variant="body1" color="text.secondary">
            2. Modifica o agrega los datos
          </Typography>
          <Typography variant="body1" color="text.secondary">
            3. Envía el archivo actualizado a soporte@sora.mx
          </Typography>
        </Stack>
      </ConfirmDialog>
    </Stack>
  );
};
