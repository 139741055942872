import { useEffect, useState } from 'react';
import { Button } from '../../newComponents/Button';
import { Icon } from '../../components/Icons/Icons';
import { useScreenSize } from '../../Hooks';
import { getHmtlFromCreator } from '../../containers/RHPod/Documents/DocumentCreator/helper';
import { IconButton } from '../../newComponents/IconButton';
import { openPreviewTemplateDialogVar } from '../../containers/MyCompany/Settings/Preferences/Templates/Templates.vars';
import { TemplatePreviewDialog } from '../../containers/MyCompany/Settings/Preferences/Templates/TemplatesPreview/TemplatePreviewDialog';
import type { PreviewTemplateButtonProps } from './PreviewTemplateButton.types';

export const PreviewTemplateButton = (props: PreviewTemplateButtonProps) => {
  const { buttonProps = {}, html } = props;
  const { isMobile } = useScreenSize();
  const [htmlTemplate, setHtmlTemplate] = useState('');

  const createHtml = ({
    addUserAsVariable = false,
    employee = null,
    preview = true,
  }) => {
    const htmlPopulate = getHmtlFromCreator({
      data: {},
      preview,
      content: htmlTemplate,
      useFeVariables: Boolean(addUserAsVariable),
      employeeToReplace: employee,
    });
    return htmlPopulate;
  };

  const handleOpenPreviewDialog = () => {
    const htmlContent = createHtml({ addUserAsVariable: false });
    setHtmlTemplate(htmlContent);
    openPreviewTemplateDialogVar({ open: true, template: null });
  };

  const handleClosePreviewDialog = () => {
    setHtmlTemplate(html || '');
  };

  useEffect(() => {
    setHtmlTemplate(html);
  }, [html]);

  return (
    <>
      {isMobile ? (
        <IconButton
          icon="eye_line"
          color="text"
          onClick={handleOpenPreviewDialog}
          tooltipArrow
          tooltipText={!htmlTemplate ? 'No hay contenido en la plantilla' : ''}
          tooltipPlacement="left"
          disabled={!htmlTemplate}
          {...buttonProps}
        />
      ) : (
        <Button
          variant="outlined"
          color="default"
          startIcon={<Icon icon="eye_line" />}
          onClick={handleOpenPreviewDialog}
          tooltipArrow
          tooltipText={!htmlTemplate ? 'No hay contenido en la plantilla' : ''}
          tooltipPlacement="left"
          disabled={!htmlTemplate}
          {...buttonProps}
        >
          Previsualizar
        </Button>
      )}

      <TemplatePreviewDialog
        htmlContent={htmlTemplate}
        replaceVariables
        onClose={handleClosePreviewDialog}
      />
    </>
  );
};
