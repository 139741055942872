//@ts-check
import { Stack } from '@mui/material';
import { AvatarGroup } from '../../../../../../newComponents/AvatarGroup';
import { Tooltip } from '../../../../../../newComponents/Tooltip';
import { ResumeAuthorizersPopover } from './ResumeTemplateAuthorizers/ResumeAuthorizersPopover';
import { authorizationStatus } from '../Templates.constants';
import { Typography } from '../../../../../../newComponents/Typography';
import { setTemplateItemVar, updateTemplateDialogVar } from '../Templates.vars';
import {
  buildAdminAuthorizer,
  buildTemplateToUpdate,
} from '../Templates.helpers';
import { usePermissionChecker } from '../../../../../../Hooks';
import { useBlockableActions } from '../../../../../../components/ModuleBlockableComponent/useBlockableActions';
import { SORA_MODULES } from '../../../../../../components/ModuleBlockableComponent/ModuleBlockable.constants';
import { globalSnackbarVar } from '../../../../../../cache.reactiveVars';
import { EmployeeAvatar } from '../../../../../../layoutComponents/EmployeeAvatar/EmployeeAvatar';

export const AvatarGroupComponent = ({ row }) => {
  const userHasPermissionsToUpdate = usePermissionChecker({
    permission: 'templates',
    action: 'update',
  });

  const {
    onClick: documentsModuleIsBlockedOnClick,
    isModuleAvailable: isDocumentsModuleAvailable,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);

  const authorizations = row?.authorizations ?? [];

  const handleOpenAuthorizers = () => {
    if (!isDocumentsModuleAvailable) {
      return documentsModuleIsBlockedOnClick();
    }

    if (!userHasPermissionsToUpdate) {
      globalSnackbarVar({
        show: true,
        message: 'No tienes permisos para esta acción',
        severity: 'error',
        duration: 5000,
      });

      return;
    }

    const mapAuthorizers = authorizations.map((auth) => {
      const admin = buildAdminAuthorizer({
        admin: auth,
        statusAuth: auth.status,
      });
      return admin;
    });

    setTemplateItemVar({
      authorizers: mapAuthorizers ?? [],
      isOrdered: Boolean(row?.orderConfig?.authorizeInOrder),
      templateToUpdate: row,
    });

    updateTemplateDialogVar({
      open: true,
      currentTemplate: buildTemplateToUpdate({ template: row }),
      updateType: 'AUTHORIZERS',
    });
  };

  if (!authorizations?.length) {
    if (!userHasPermissionsToUpdate) {
      return (
        <Tooltip
          arrow
          placement="top"
          title="No tienes permisos para esta acción"
        >
          <Typography variant="body2">---</Typography>
        </Tooltip>
      );
    }
    return (
      <Typography
        variant="body2"
        sx={{ cursor: 'pointer' }}
        onClick={handleOpenAuthorizers}
      >
        ---
      </Typography>
    );
  }

  return (
    <Stack
      width="100%"
      justifyContent="center"
      alignItems="center"
      direction="row"
      position="relative"
      sx={{ cursor: 'pointer' }}
    >
      <Stack position="absolute" left={24}>
        <ResumeAuthorizersPopover row={row} />
      </Stack>
      <Stack>
        <AvatarGroup
          onClick={handleOpenAuthorizers}
          size="tiny"
          total={authorizations?.length}
          max={4}
        >
          {authorizations?.map((auth, index) => {
            const currStatus = authorizationStatus[auth.status] || 'default';
            const user = auth?.user;
            const fullName = user?.fullName || '';
            return (
              <EmployeeAvatar
                key={index}
                size="tiny"
                profilePicture={user?.profilePicture}
                color={currStatus?.color}
                name={fullName}
              />
            );
          })}
        </AvatarGroup>
      </Stack>
    </Stack>
  );
};
