//@ts-check
import React, { forwardRef, useRef } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList } from 'react-window';
import { MenuItem } from '../../../../../../newComponents/MenuItem';
import { Skeleton, Stack } from '@mui/material';
import { EmployeeAvatar } from '../../../../../../businessComponents/EmployeeAvatar';
import { Typography } from '../../../../../../newComponents/Typography';
import { ListItem } from '../../../../../../newComponents/ListItem';

const AuthorizerItem = (props) => {
  const { row, style, handleSelect, selectedItems, loading } = props;
  const user = row?.user;
  const name = `${user?.names} ${user?.lastNameP} ${user?.lastNameM}`;

  const isSelected = selectedItems?.some((item) => item?._id === row?._id);

  if (!row || loading) {
    return (
      <ListItem style={style} sx={{ padding: 1 }}>
        <Stack direction="row" alignItems="center" gap={2} width="100%">
          <Skeleton variant="circular" width={50} height={40} />
          <Stack width="95%">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          </Stack>
        </Stack>
      </ListItem>
    );
  }

  return (
    <MenuItem key={row?._id} style={style} onClick={() => handleSelect(row)}>
      <Stack direction="row" alignItems="center" gap={2}>
        <EmployeeAvatar
          name={name}
          profilePicture={user?.profilePicture}
          selected={Boolean(isSelected)}
          size="medium"
        />
        <Typography variant="subtitle2">{name}</Typography>
      </Stack>
    </MenuItem>
  );
};

/**
 * @type {React.ForwardRefExoticComponent}
 */
export const AdminListboxComponent = forwardRef((props, ref) => {
  const infiniteLoaderRef = useRef(null);

  const {
    role,
    options,
    total,
    handleLoadMore,
    handleSelect,
    origin,
    selectedItems,
    loading,
    ...other
  } = props;

  return (
    <div
      ref={ref}
      {...other}
      role={role}
      style={{ padding: 0, maxHeight: '100%' }}
    >
      <InfiniteLoader
        ref={infiniteLoaderRef}
        isItemLoaded={(index) => index < options.length}
        itemCount={total}
        threshold={0}
        loadMoreItems={handleLoadMore}
      >
        {({ onItemsRendered, ref: refLoader }) => (
          <FixedSizeList
            ref={refLoader}
            height={options?.length ? Math.min(options?.length * 65, 300) : 65}
            width={'100%'}
            itemSize={60}
            itemCount={total}
            onItemsRendered={onItemsRendered}
            style={{ margin: '5px 0px' }}
          >
            {({ index, style }) => (
              <AuthorizerItem
                style={style}
                row={options[index]}
                handleSelect={handleSelect}
                origin={origin}
                selectedItems={selectedItems}
                loading={loading}
              />
            )}
          </FixedSizeList>
        )}
      </InfiniteLoader>
    </div>
  );
});
