//@ts-check
import { TableHead } from '@mui/material';
import { AuthorizerTableRow, AuthorizersTableCell } from '../Templates.styles';
import { useCustomTheme } from '../../../../../../Hooks/useCustomTheme';
import { Checkbox } from '../../../../../../newComponents/Checkbox';
import { setTemplateItemVar, templateItemVar } from '../Templates.vars';
import { useReactiveVar } from '@apollo/client';
import { useState } from 'react';

export const TableHeaderAuthorizers = () => {
  const theme = useCustomTheme();
  const templateItem = useReactiveVar(templateItemVar);
  const [checked, setChecked] = useState(false);

  const handleSelectionModelChange = (isChecked) => {
    const currAuthorizers = templateItem?.authorizers ?? [];
    const selected = templateItem?.selectedAuthorizers ?? [];
    if (!isChecked && currAuthorizers?.length === selected?.length) {
      setChecked(false);
      return setTemplateItemVar({ selectedAuthorizers: [] });
    }

    if (!isChecked && currAuthorizers?.length !== selected?.length) {
      setChecked(true);
      return setTemplateItemVar({ selectedAuthorizers: currAuthorizers });
    }

    if (isChecked && currAuthorizers?.length && !selected?.length) {
      setChecked(true);
      return setTemplateItemVar({ selectedAuthorizers: currAuthorizers });
    }

    if (isChecked && currAuthorizers?.length !== selected?.length) {
      setChecked(true);
      return setTemplateItemVar({ selectedAuthorizers: currAuthorizers });
    }
  };

  const authorizers = templateItem?.authorizers ?? [];
  const selectedAuthorizers = templateItem?.selectedAuthorizers ?? [];
  const allItemsSelected = authorizers.length === selectedAuthorizers?.length;

  return (
    <TableHead
      component="div"
      sx={{ backgroundColor: theme.newPalette.primary.lighter }}
    >
      <AuthorizerTableRow sx={{ position: 'relative', overflow: 'auto' }}>
        <AuthorizersTableCell sx={{ pl: 2, pr: 1, minWidth: '50px' }}>
          <Checkbox
            sx={{
              '&.MuiCheckbox-root': {
                color: theme.newPalette.primary.main,
              },
            }}
            value={checked}
            checked={Boolean(allItemsSelected)}
            indeterminate={
              authorizers?.length !== selectedAuthorizers?.length &&
              Boolean(selectedAuthorizers?.length)
            }
            onChange={(e) => handleSelectionModelChange(e.target.checked)}
          />
        </AuthorizersTableCell>
        <AuthorizersTableCell
          sx={{ color: theme.newPalette.primary.main, flex: 1, minWidth: 250 }}
        >
          Nombre
        </AuthorizersTableCell>
        <AuthorizersTableCell
          sx={{ minWidth: 50, textAlign: 'right', justifyContent: 'flex-end' }}
        />
      </AuthorizerTableRow>
    </TableHead>
  );
};
