// @ts-check
import React from 'react';
import { Box, Stack } from '@mui/material';
import { Tooltip } from '../../newComponents/Tooltip';
import { Typography } from '../../newComponents/Typography';
import { WorkTitleAvatar } from '../WorkTitleAvatar';

const defaultSx = {
  minWidth: 0,
  maxWidth: '100%',
  textOverflow: 'ellipsis',
};

/** @param {import('./WorkTitleListItem.types').WorkTitleListItemProps} props */
export const WorkTitleListItem = (props) => {
  const {
    color,
    name,
    secondaryText,
    AvatarProps = {},
    PrimaryTextProps = {},
    SecondaryTextProps = {},
  } = props;

  const { sx: primaryTextSx, ...primaryTextProps } = PrimaryTextProps;
  const { sx: secondaryTextSx, ...secondaryTextProps } = SecondaryTextProps;

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      gap={2}
      width="100%"
      flexWrap="nowrap"
    >
      <WorkTitleAvatar color={color} {...AvatarProps} />
      <Box flexGrow={1} minWidth={0} overflow="hidden">
        <Tooltip title={name} placement="top">
          <Box width="fit-content" maxWidth="100%">
            <Typography
              variant="subtitle2"
              noWrap
              {...primaryTextProps}
              sx={[
                defaultSx,
                ...(Array.isArray(primaryTextSx)
                  ? primaryTextSx
                  : [primaryTextSx]),
              ]}
            >
              {name}
            </Typography>
          </Box>
        </Tooltip>
        {secondaryText && (
          <Tooltip title={secondaryText} placement="top">
            <Box width="fit-content" maxWidth="100%">
              <Typography
                variant="body2"
                color="text.secondary"
                noWrap
                {...secondaryTextProps}
                sx={[
                  defaultSx,
                  ...(Array.isArray(secondaryTextSx)
                    ? secondaryTextSx
                    : [secondaryTextSx]),
                ]}
              >
                {secondaryText}
              </Typography>
            </Box>
          </Tooltip>
        )}
      </Box>
    </Stack>
  );
};
