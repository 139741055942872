//@ts-check
import { useQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_ALL_COMPANY_ADMINS } from '../../../../Company/company.gql';
import {
  currentCompanyVar,
  globalSnackbarVar,
} from '../../../../../../cache.reactiveVars';
import { Autocomplete } from '../../../../../../newComponents/Autocomplete';
import { useCustomTheme } from '../../../../../../Hooks/useCustomTheme';
import { InputAdornment, Paper } from '@mui/material';
import { TextInput } from '../../../../../../newComponents/TextInput';
import { Icon } from '../../../../../../components/Icons/Icons';
import { CircularProgress } from '../../../../../../newComponents/Progress';
import { AdminListboxComponent } from './AdminListBoxComponent';

const CustomPaper = (props) => {
  const theme = useCustomTheme();
  const palette = theme.newPalette;
  return (
    <Paper
      sx={{
        boxShadow: palette.shadow.dropdown,
        borderRadius: '12px',
      }}
      {...props}
    />
  );
};

export const AdminsAutocomplete = ({
  placeholder = 'Buscar...',
  handleAdd,
  unSelectItem,
  items = [],
}) => {
  const currenCompany = useReactiveVar(currentCompanyVar);
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);
  const perPage = 10;

  const { data, loading, fetchMore } = useQuery(GET_ALL_COMPANY_ADMINS, {
    skip: !currenCompany || !currenCompany?._id,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      page: 0,
      perPage: perPage,
      filter: {
        companyId: currenCompany?._id,
        search: search,
        paginationIsRequired: true,
        permissions: 'authorizeTemplates.update',
      },
    },
    onError: () => {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al obtener lista de administradores',
        severity: 'error',
        duration: 5000,
      });
    },
  });

  const handleChange = (value) => {
    if (value?.includes(',')) return;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (!value?.length) {
      setSearch('');
      return;
    }

    if (value?.length >= 3) {
      const newTimeoutId = setTimeout(() => {
        setSearch(value);
      }, 700);

      //@ts-ignore
      setTimeoutId(newTimeoutId);
    }
  };

  const handleSelect = (admin) => {
    const isSelected = selectedItems.some((item) => item?._id === admin?._id);

    if (isSelected) {
      const filteredItems = selectedItems.filter(
        (item) => item?._id !== admin?._id,
      );
      setSelectedItems(filteredItems);
      if (unSelectItem) unSelectItem(admin);
    } else {
      setSelectedItems([...selectedItems, admin]);
      if (handleAdd) handleAdd(admin);
    }
  };

  const handleLoadMore = () => {
    const adminsLength = data?.getAllCompanyAdmins?.admins?.length || 0;
    const total = data?.getAllCompanyAdmins?.total || 0;
    const newAdminsPage = Math.ceil(adminsLength / perPage);
    if (adminsLength < total) {
      fetchMore({
        variables: {
          perPage: perPage,
          page: newAdminsPage,
        },
      });
    }
  };

  useEffect(() => {
    setSelectedItems(items);
  }, [items]);

  const total = data?.getAllCompanyAdmins?.total || 0;
  const options = data?.getAllCompanyAdmins?.admins || [];

  return (
    <Autocomplete
      selectOnFocus
      clearOnBlur={false}
      variant="outlined"
      fullWidth
      onInputChange={(e, newValue) => {
        handleChange(newValue);
      }}
      onChange={handleChange}
      loadingText={loading ? 'Cargando...' : ''}
      noOptionsText={loading ? '' : 'No hay elementos'}
      getOptionLabel={(option) => `${JSON.stringify(option)}`}
      ListboxComponent={AdminListboxComponent}
      ListboxProps={{
        // @ts-ignore
        options: total === 0 ? [] : options,
        total,
        handleLoadMore,
        handleSelect,
        origin,
        selectedItems,
        loading,
      }}
      PaperComponent={CustomPaper}
      options={total === 0 ? [] : options}
      renderInput={(params) => (
        <TextInput
          {...params}
          placeholder={placeholder}
          variant="outlined"
          name="authorizers"
          InputProps={{
            ...params.InputProps,
            sx: {
              borderRadius: '8px',
            },
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <Icon icon="search_line" />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <>
                {loading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
