//@ts-check
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../../../../cache.reactiveVars';
import { REJECT_TEMPLATE } from '../Templates.gql';
import { updateTemplatesVar } from '../Templates.vars';
import { TemplateToReject } from '../../../../../RHPod/Documents/Templates/Template.types';

export type RejectTemplateInput = {
  data: TemplateToReject;
  onSuccess: () => void;
};

export const useRejectTemplate = () => {
  const user = useReactiveVar(userVar);
  const [rejectTemplate] = useMutation(REJECT_TEMPLATE);

  const handleRejectTemplate = async (input: RejectTemplateInput) => {
    if (!user?._id) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message:
          'No se ha podido obtener la información del usuario al rechazar plantilla',
      });
      return;
    }

    try {
      const { data: inputData, onSuccess } = input;

      if (!inputData?.templateId) {
        globalSnackbarVar({
          show: true,
          message: 'Faltan datos para rechazar la plantilla',
          severity: 'error',
          duration: 5000,
        });
        return;
      }

      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Rechanzando plantilla...',
      });

      const response = await rejectTemplate({
        variables: {
          input: {
            userId: user?._id,
            templateId: inputData.templateId,
            comments: inputData.comments,
          },
        },
      });

      const data = response?.data?.rejectTemplate;

      if (data?.__typename === 'ErrorToRejectTemplate') {
        globalSnackbarVar({
          show: true,
          message: data?.message,
          severity: 'error',
          duration: 5000,
        });

        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Plantilla rechazada con éxito',
        severity: 'success',
        duration: 5000,
      });

      updateTemplatesVar(!updateTemplatesVar());
      if (onSuccess) onSuccess();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Error al rechazar la plantilla. Contacta a SORA',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleRejectTemplate };
};
