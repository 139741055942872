import { gql } from '@apollo/client';

export const GET_EMPLOYEES_AUTOCOMPLETE = gql`
  query getCompanyEmployeesAutocomplete {
    allUsers(
      page: 0
      perPage: 99999
      filter: { active: true, rfc: null, workCenterId: null }
      sortField: EMPLOYEENAME
      sortOrder: ASC
    ) {
      _id
      id
      rfc
      fullName
      names
      lastNameM
      lastNameP
    }
  }
`;

export const GET_WORKCENTERS = gql`
  query getWorkcenters {
    allWorkCenters {
      _id
      id
      companyId
      name
      adminUsers {
        adminId
      }
      adminData {
        _id
        names
        lastNameP
        lastNameM
      }
      code
      employeeCount
      addressData {
        address
        streetName
        internalNumber
        externalNumber
        colony
        postalCode
        federalEntity {
          country
          state
          city
        }
      }
    }
  }
`;

export const GET_WORKCENTER = gql`
  query getWorkcenter($id: String) {
    WorkCenter(id: $id) {
      id
      adminUsers {
        adminId
      }
      addressData {
        address
        streetName
        internalNumber
        externalNumber
        colony
        postalCode
        federalEntity {
          country
          state
          city
        }
      }
    }
  }
`;
export const GET_EMPLOYEE_DATA_CHANGE_REQUEST = gql`
  query getPendingDataChangeRequest($userId: String) {
    getPendingDataChangeRequest(userId: $userId) {
      _id
      status
      userId
      creatorId
      companyId
      employeeDocumentId
      dismissed
      reason
    }
  }
`;
export const DISMISS_ACTIVE_USER_DATA_MODIFICATION_REQUEST = gql`
  mutation dismissActiveUserDataModificationRequest($requestId: String!) {
    dismissActiveUserDataModificationRequest(requestId: $requestId) {
      __typename
    }
  }
`;
export const REJECT_ACTIVE_USER_DATA_CHANGE_REQUEST = gql`
  mutation rejectActiveUserDataChangeRequest(
    $requestId: String!
    $reason: String
  ) {
    rejectActiveUserDataChangeRequest(requestId: $requestId, reason: $reason) {
      __typename
    }
  }
`;
export const CANCEL_ACTIVE_USER_DATA_MODIFICATION_REQUEST = gql`
  mutation cancelActiveUserDataModificationRequest($requestId: String!) {
    cancelActiveUserDataModificationRequest(requestId: $requestId) {
      __typename
    }
  }
`;

export const CREATE_WORKCENTER = gql`
  mutation CreateWorkCenter($input: WorkCenterInput) {
    createWorkCenter(input: $input) {
      __typename
      ... on ValidationError {
        field
        validationMessage
      }
      ... on WorkCenterAlreadyExists {
        message
      }
      ... on WorkCenter {
        _id
        companyId
        name
        code
        addressData {
          address
          streetName
          internalNumber
          externalNumber
          colony
          postalCode
          federalEntity {
            country
            state
            city
          }
        }
        adminUsers {
          adminId
        }
      }
    }
  }
`;

export const CREATE_ADMIN = gql`
  mutation CreateWorkCenterAdmin($input: CreateWorkCenterAdminInput) {
    createWorkCenterAdmin(input: $input) {
      __typename
      ... on UserNotFound {
        message
      }
      ... on WorkCenterNotFound {
        message
      }
      ... on WorkCenterAlreadyExists {
        message
      }
      ... on WorkCenter {
        _id
        name
      }
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation DeleteWorkCenterAdmin($adminId: String, $workCenterId: String) {
    deleteWorkCenterAdmin(adminId: $adminId, workCenterId: $workCenterId) {
      id
      name
    }
  }
`;

export const UPLOAD_USERS = gql`
  mutation UploadUsers($file: Upload!) {
    uploadUsers(file: $file) {
      names {
        providedValue
        usedValue
        errorMessage
      }
      lastNameM {
        providedValue
        usedValue
        errorMessage
      }
      lastNameP {
        providedValue
        usedValue
        errorMessage
      }
      rfc {
        providedValue
        usedValue
        errorMessage
      }
      curp {
        providedValue
        usedValue
        errorMessage
      }
      payrollId {
        providedValue
        usedValue
        errorMessage
      }
      workTitle {
        providedValue
        usedValue
        errorMessage
      }
      workCenterName {
        providedValue
        usedValue
        errorMessage
      }
      workCenterId {
        providedValue
        usedValue
        errorMessage
      }
      email {
        providedValue
        usedValue
        errorMessage
      }
      phone {
        providedValue
        usedValue
        errorMessage
      }
      alta {
        providedValue
        usedValue
        errorMessage
      }
      groups {
        providedValue
        usedValue
        errorMessage
      }
      recordChecklistName {
        providedValue
        usedValue
        errorMessage
      }
      contractType {
        providedValue
        usedValue
        errorMessage
      }
      workDayType {
        providedValue
        usedValue
        errorMessage
      }
      salary {
        providedValue
        usedValue
        errorMessage
      }
      socialSecurityNumber {
        providedValue
        usedValue
        errorMessage
      }
      employmentStartDate {
        providedValue
        usedValue
        errorMessage
      }
      birthdate {
        providedValue
        usedValue
        errorMessage
      }
      gender {
        providedValue
        usedValue
        errorMessage
      }
      maritalStatus {
        providedValue
        usedValue
        errorMessage
      }
      country {
        providedValue
        usedValue
        errorMessage
      }
      zipCode {
        providedValue
        usedValue
        errorMessage
      }
      state {
        providedValue
        usedValue
        errorMessage
      }
      city {
        providedValue
        usedValue
        errorMessage
      }
      colony {
        providedValue
        usedValue
        errorMessage
      }
      street {
        providedValue
        usedValue
        errorMessage
      }
      externalNumber {
        providedValue
        usedValue
        errorMessage
      }
      internalNumber {
        providedValue
        usedValue
        errorMessage
      }
    }
  }
`;

export const CREATE_USERS = gql`
  mutation createUsers($input: [CreateUserInput]) {
    createUsers(input: $input) {
      __typename
      ... on GetUsersSuccess {
        newUsers {
          id
          rfc
          fullName
          workCenterName
        }
        warnings
      }
      ... on FailUsersResult {
        newUsers {
          id
          rfc
          fullName
          workCenterName
        }
        failUsers {
          rfc
          message
        }
      }
    }
  }
`;

export const UPLOAD_WORKCENTERS = gql`
  mutation UploadWorkCenters($file: Upload!) {
    uploadWorkCenters(file: $file) {
      workCenterValidation {
        id
        name
        code
        address
        streetName
        internalNumber
        externalNumber
        colony
        postalCode
        city
        state
        country
        validationResult {
          code
          name
          address
          streetName
          internalNumber
          externalNumber
          colony
          postalCode
          country
          state
          city
        }
      }
      workCenterAdminsValidation {
        _id
        names
        lastNameP
        lastNameM
        rfc
        curp
        email
        phone
        wcCode
        userType
        payrollId
        workTitle
        validationResult {
          passed
          errors {
            wcCode
          }
        }
        newUserValidation {
          passed
          validationErrors {
            names
            lastNameP
            lastNameM
            rfc
            curp
            wcCode
            email
            phone
            payrollId
            workTitle
          }
        }
      }
    }
  }
`;

export const CREATE_WORKCENTERS = gql`
  mutation CreateWorkCenters($input: CreateWorkCentersInput) {
    createWorkCenters(input: $input) {
      __typename
      ... on WorkCentersCodeAlreadyExists {
        message
        workCentersCode
      }
      ... on CreateWorkCentersErrors {
        errors
      }
      ... on Success {
        message
      }
    }
  }
`;

export const UPDATE_WORKCENTER = gql`
  mutation UpdateWorkCenterData($input: UpdateWorkCenterDataInput) {
    updateWorkCenterData(input: $input) {
      __typename
      ... on ValidationError {
        field
        validationMessage
      }
      ... on WorkCenterAlreadyExists {
        message
      }
      ... on WorkCenter {
        _id
        companyId
        name
        code
        addressData {
          address
          streetName
          internalNumber
          externalNumber
          colony
          postalCode
          federalEntity {
            country
            state
            city
          }
        }
        adminUsers {
          active
          adminId
        }
        active
      }
    }
  }
`;

export const USER_COUNT = gql`
  query userCount($filter: UserFilter) {
    _allUsersMeta(filter: $filter) {
      count
    }
  }
`;

export const WORKCENTER_COUNT = gql`
  query workCenterCount {
    _allWorkCentersMeta {
      count
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: String!) {
    getUserProfile(userId: $id) {
      __typename
      ... on UserNotFound {
        message
      }
      ... on UserProfile {
        user {
          id
          rfc
          curp
          names
          lastNameP
          lastNameM
          fullName
          workTitle
          alta
          workCenterName
          workCenterId
          payrollId
          active
          email
          phone
          status
          profilePicture
          blocked
          groups
          lastLogin
          hasPassword
          hasFinishedRegistration
          hasEverBeenActive
          previousReference {
            id
            role
          }
          isEmployee
          isAdminFromBajaEmployee
          legalStatus {
            name
            createdBy {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
            }
            startDate
            endDate
          }
          sentOnboardingNotification {
            dateSent
            sentBy {
              _id
              names
              lastNameP
              lastNameM
              rfc
              curp
            }
          }
        }
        counts {
          signedDocuments
          unsignedDocuments
          signedReceipts
          unsignedReceipts
          totalDocuments
          totalReceipts
        }
      }
    }
  }
`;

// export const CREATE_EMPLOYEE = gql`
//   mutation CreateUserMutation($input: CreateUserInput) {
//     createUser(input: $input) {
//       id
//       _id
//       rfc
//       names
//       lastNameP
//       lastNameM
//       fullName
//       alta
//       payrollId
//       workCenterName
//       workTitle
//       curp
//       active
//       lastNameM
//       createdDate
//     }
//   }
// `;

export const CREATE_EMPLOYEE = gql`
  mutation CreateUser($input: CreateUserInput) {
    createUser(input: $input) {
      __typename
      ... on ValidationError {
        field
        validationMessage
      }
      ... on InvalidWorkCenter {
        message
      }
      ... on UserAlreadyExists {
        message
      }
      ... on User {
        id
        _id
        rfc
        names
        lastNameP
        lastNameM
        fullName
        alta
        payrollId
        workCenterName
        workTitle
        curp
        active
        lastNameM
        createdDate
        warnings
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($input: UserDataInput) {
    setUserData(input: $input) {
      id
      rfc
      curp
      names
      lastNameM
      lastNameP
      fullName
      active
      workTitle
      alta
      workCenterName
      payrollId
    }
  }
`;

// credits
export const MY_CREDITS = gql`
  query MyCredits {
    myCredits {
      ... on MyCredits {
        __typename
        credits {
          _id
          concept
          finished
          folioNumber
          type
          initialAmount
          finalAmount
          creditPayments {
            paymentWeek
            paymentDate
            previousBalance
            paymentAmount
            currentBalance
          }
        }
      }
    }
  }
`;

export const ALL_CREDITS = gql`
  query AllCredits($page: Int, $perPage: Int, $filter: AllCreditsFilter) {
    allCredits(page: $page, perPage: $perPage, filter: $filter) {
      ... on AllCredits {
        total
        credits {
          _id
          employeeRfc
          employeeFullName
          folioNumber
          concept
          type
          finished
          initialAmount
          finalAmount
          creditPayments {
            _id
            paymentWeek
            paymentDate
            previousBalance
            paymentAmount
            currentBalance
            timestamps {
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export const DELETE_CREDITS = gql`
  mutation DeleteCredits($input: [DeleteCreditsInput!]) {
    deleteCredits(input: $input) {
      ... on CreditsDeleted {
        message
        totalDeleted
      }
      ... on NoCreditsDeleted {
        message
      }
    }
  }
`;

export const DELETE_CREDIT_PAYMENTS = gql`
  mutation DeleteCreditPayments($input: [DeleteCreditPaymentsInput!]) {
    deleteCreditPayments(input: $input) {
      ... on CreditPaymentsDeleted {
        message
        totalDeleted
      }
      ... on NoCreditPaymentsDeleted {
        message
      }
    }
  }
`;

export const EXPORT_RECEIPTS = gql`
  mutation ExportReceiptsMutation($input: ExportReceiptsInput) {
    exportReceipts(input: $input) {
      __typename
      ... on ExportMaxCountError {
        message
        link
      }
      ... on ExportLink {
        link
      }
    }
  }
`;

export const EXPORT_DOCUMENTS = gql`
  mutation ExportDocumentsMutation($input: ExportDocumentsInput) {
    exportDocuments(input: $input) {
      __typename
      ... on ExportMaxCountError {
        message
        link
      }
      ... on ExportLink {
        link
      }
    }
  }
`;

export const EXPORT_USERS = gql`
  mutation ExportUsersMutation($input: ExportUsersInput) {
    exportUsers(input: $input) {
      __typename
      ... on ExportMaxCountError {
        message
        link
      }
      ... on ExportLink {
        link
      }
    }
  }
`;

export const DOWNLOAD_CARTA = gql`
  query Query(
    $type: DownloadRequestTypeEnum!
    $userId: String!
    $searchId: String
  ) {
    downloadUserFile(type: $type, userId: $userId, searchId: $searchId)
  }
`;

export const DOWNLOAD_ADMIN_FILE = gql`
  query downloadAdminFile($type: DownloadAdminFileEnum!) {
    downloadAdminFile(type: $type)
  }
`;

export const DOWNLOAD_CARTAS = gql`
  query downloadUserCartas($input: DownloadUserCartas!) {
    downloadUserCartas(input: $input)
  }
`;

export const SIFE_RESEND_ACTIVATION = gql`
  mutation Mutation($rfc: String!) {
    sifeResendActivation(rfc: $rfc) {
      __typename
      ... on Success {
        message
      }
      ... on UserNotFound {
        message
      }
      ... on UserAlreadyActive {
        message
      }
    }
  }
`;

export const VERIFY_CERTIFICATE = gql`
  mutation verifyCertificate($certificate: String!) {
    verifyCertificate(certificate: $certificate)
  }
`;

export const UPDATE_EMPLOYEE_DATA = gql`
  mutation updateEmployeeData($input: EmployeeData!) {
    updateEmployeeData(employeeData: $input) {
      __typename

      ... on GeneralError {
        message
      }
    }
  }
`;
export const UPDATE_ACTIVE_USER_DATA = gql`
  mutation updateActiveUserData($inputData: updateActiveUserDataInput!) {
    updateActiveUserData(input: $inputData) {
      __typename
      ... on GeneralError {
        message
      }
    }
  }
`;
export const UPDATE_EMPLOYEE_CONTACT_METHODS = gql`
  mutation updateEmployeeContactMethods($input: EmployeeContactMethods!) {
    updateEmployeeContactMethods(input: $input) {
      __typename

      ... on UserNotFound {
        message
      }

      ... on NeedsContactMethods {
        message
      }

      ... on EmailAlreadyInUse {
        message
      }

      ... on UserIsRegisteredInMoreThanOneCompany {
        message
      }

      ... on Success {
        message
      }
    }
  }
`;
export const UPDATE_EMPLOYEE_NOMINA_DATA = gql`
  mutation updateEmployeeNominaData($input: EmployeeNominaData!) {
    updateEmployeeNominaData(input: $input) {
      __typename
    }
  }
`;

export const VALIDATE_USER_INFO = gql`
  mutation validateUserInfo($input: ValidateUserInfoInput!) {
    validateUserInfo(input: $input) {
      __typename

      ... on ErrorToValidateUserInfo {
        message
      }

      ... on FinalResponseValidateUserInfo {
        message
        success
        user {
          __typename
          id
          _id
          fullName
          names
          lastNameP
          lastNameM
          rfc
          profilePicture
          workCenterName
        }
      }
    }
  }
`;

export const GENERATE_TEMPORAL_PASSWORD = gql`
  mutation generateTemporalPassword($input: GenerateTemporalPasswordInput!) {
    generateTemporalPassword(input: $input) {
      __typename
      ... on GenerateTemporalPasswordError {
        success
        message
      }

      ... on GenerateTemporalPasswordSuccess {
        success
        message
        temporalPassword
      }
    }
  }
`;

export const ALL_EMPLOYEES_LIST = gql`
  query allEmployees($filter: FilterInput, $page: Int, $perPage: Int) {
    allEmployees(filter: $filter, page: $page, perPage: $perPage) {
      ... on EmployeesResult {
        total
        totalCompanyEmployeesCount
        employees {
          _id
          id
          names
          lastNameP
          lastNameM
          fullName
          profilePicture
          workCenterName
          workCenterId
          workTitle
          rfc
          email
          curp
          payrollId
          groups
          recordChecklistAssigned {
            _id
            name
          }
          phone
          status
        }
      }
    }
  }
`;

export const VALIDATE_EMPLOYEES = gql`
  mutation employeesValidator($input: EmployeesValidationInput!) {
    employeesValidator(input: $input) {
      __typename
      ... on ErrorToValdateEmployeesInfo {
        message
      }
      ... on EmployeesValidationResult {
        errors {
          success
          message
          user
          id
          _id
        }
        employees {
          _id
          id
          fullName
          profilePicture
          workCenterName
          workCenterId
          groups
          workTitle
          rfc
          email
          curp
          payrollId
          type
        }
      }
    }
  }
`;

export const ALL_WORKCENTERS_LIST = gql`
  query getAllWorkCenters(
    $page: Int
    $perPage: Int
    $filter: FilterInputWorkCenterFinder
  ) {
    getAllWorkCenters(page: $page, perPage: $perPage, filter: $filter) {
      ... on WorkCentersResult {
        total
        workCenters {
          _id
          id
          name
          code
          employeeCount
        }
      }
    }
  }
`;

export const CHANGE_EMPLOYEE_LEGAL_STATUS = gql`
  mutation changeEmployeeLegalStatus($input: ChangeEmployeeLegalStatusInput!) {
    changeEmployeeLegalStatus(input: $input) {
      __typename
      success
      message
      oldLegalStatus {
        name
        createdBy {
          _id
          names
          lastNameP
          lastNameM
          rfc
          curp
        }
        startDate
        endDate
      }
      newLegalStatus {
        name
        createdBy {
          _id
          names
          lastNameP
          lastNameM
          rfc
          curp
        }
        startDate
        endDate
      }
    }
  }
`;

export const GET_COMPANY_EMPLOYEES = gql`
  query getCompanyEmployees($input: GetCompanyEmployeesInput) {
    getCompanyEmployees(input: $input) {
      ... on GetCompanyEmployeesSuccess {
        companyEmployees {
          _id
          userId
          alta
          fullName
          names
          lastNameP
          lastNameM
          payrollId
          rfc
          curp
          workTitle
          currentStatus {
            name
            endDate
            startDate
          }
          currentLegalStatus {
            name
            endDate
            startDate
          }
          currentAbsence {
            absenceId
            name
            start
            end
            reason
          }
          bajaDate
          blocked
          createdAt
          workCenterId
          workCenter {
            _id
            code
            name
          }
          email
          profilePicture
          sentOnboardingNotification
          documentCounts {
            totalSignableDocuments
            totalSignedDocuments
            totalDocuments
            signedDocumentsPercent
          }
          receiptCounts {
            totalReceipts
            totalSignedReceipts
            totalPendingSignReceipts
            signedReceiptsPercent
          }
          signedFilesPercent
          currentAbsence {
            absenceId
            name
          }
        }
      }
    }
  }
`;

export const GET_WORKCENTERS_BY_COMPANY_IDS = gql`
  query getWorkCentersByCompanyIds(
    $page: Int
    $perPage: Int
    $filter: GetWorkCentersByCompanyIdsFilterInput
  ) {
    getWorkCentersByCompanyIds(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      ... on GetWorkCentersByCompanyIdsResult {
        total
        workCenters {
          _id
          id
          companyId
          companyName
          clientName
          name
          code
        }
      }
    }
  }
`;

export const GET_EMPLOYEES_COUNT_TO_SEND_ONBOARD_NOTIF = gql`
  query getEmployeesCountToSendOnboardNotif(
    $input: GetEmployeesCountToSendOnboardNotifInput!
  ) {
    getEmployeesCountToSendOnboardNotif(input: $input) {
      ... on GetEmployeesCountToSendOnboardNotifResult {
        emailCount
        phoneCount
        noContactCount
      }
    }
  }
`;

export const SEND_MASSIVE_ONBOARDING_NOTIFICATION = gql`
  mutation sendMassiveOnboardingNotification(
    $input: SendMassiveOnboardingNotificationInput!
  ) {
    sendMassiveOnboardingNotification(input: $input) {
      __typename
      ... on ErrorToSendMassiveOnboardingNotification {
        message
      }
      ... on SendMassiveOnboardingNotificationResult {
        isSuccess
        message
        totalSuccess
        errors {
          user
          message
        }
      }
    }
  }
`;

export const GET_USER_WORKSPACE = gql`
  query getUserWorkspace($input: GetUserWorkspaceInput!) {
    getUserWorkspace(input: $input) {
      __typename
      ... on ErrorToGetUserWorkspace {
        message
      }
      ... on GetUserWorkspaceResult {
        workSpace {
          _id
          sendMassiveOnboardingNotification {
            dateSent
          }
        }
      }
    }
  }
`;
