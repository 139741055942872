import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { VALIDATE_EMPLOYEES } from '../../containers/RHPod/Employees/Employees.gql';
import { globalSnackbarVar } from '../../cache.reactiveVars';
import { EMPLOYEE_ROW } from './EmployeesFinder.constants';
import { employeesFinderVar } from './EmployeesFinder.vars';

export const useEmployeesIdsValidator = ({
  onEmployeesValidated,
  onEmployeesValidatedError,
}) => {
  const [isValidating, setIsValidating] = useState(false);
  const [employeesValidator] = useMutation(VALIDATE_EMPLOYEES);

  /**
   * @param {string[]} employeesIds
   */
  const validateEmployeesIds = async (employeesIds) => {
    try {
      setIsValidating(true);
      employeesFinderVar({ ...employeesFinderVar(), loading: true });
      const response = await employeesValidator({
        variables: { input: { employeesToValidate: employeesIds } },
      });
      const data = response?.data?.employeesValidator;
      if (data?.__typename === 'ErrorToValdateEmployeesInfo') {
        return globalSnackbarVar({
          show: true,
          message: data?.message || 'Ocurrió un error, contacta a SORA',
          severity: 'warning',
          duration: 5000,
        });
      }
      if (data?.employees?.length && onEmployeesValidated) {
        const employees = data.employees;
        // onEmployeesValidated(employees);
        const mapFunc = (row) => ({ ...row, type: EMPLOYEE_ROW });
        const newEmployees = employees.map(mapFunc);
        onEmployeesValidated(newEmployees);
        employeesFinderVar({
          ...employeesFinderVar(),
          data: { options: newEmployees, total: employees.length },
          loading: false,
        });
      }
      if (data?.errors?.length && onEmployeesValidatedError) {
        const errors = data?.errors || [];
        const mapErrors = errors?.map((error) => ({
          ...error,
          type: EMPLOYEE_ROW,
        }));
        onEmployeesValidatedError(mapErrors);
      }
    } catch (error) {
      console.error('Error validating employees ids', error);
      return globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al obtener empleados',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      setIsValidating(false);
      employeesFinderVar({
        ...employeesFinderVar(),
        loading: false,
      });
    }
  };

  return {
    validateEmployeesIds,
    isValidating,
  };
};
