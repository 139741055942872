import { useEffect, useRef, useState } from 'react';
import { ButtonGroup } from '../../../../../newComponents/ButtonGroup';
import { Button } from '../../../../../newComponents/Button';
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  Stack,
  useTheme,
} from '@mui/material';
import { Icon } from '../../../../../components/Icons/Icons';
import { MenuItem } from '../../../../../newComponents/MenuItem';
import { Typography } from '../../../../../newComponents/Typography';
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from '@mui/x-date-pickers';
import { esES } from '@mui/x-date-pickers/locales';
import { es } from 'date-fns/locale';
import { globalSnackbarVar } from '../../../../../cache.reactiveVars';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { todayAt0Minutes } from '../../../../../utils/utils';
import type { SendDocumentButtonProps } from '../CreateDocumentDialog.types';
import { usePermissionChecker, useScreenSize } from '../../../../../Hooks';
import { Tooltip } from '../../../../../newComponents/Tooltip';

type Option = {
  label: string;
  icon: 'time_line' | 'send_plane_line';
};

const options: Option[] = [
  {
    label: 'Enviar ahora',
    icon: 'send_plane_line',
  },
  {
    label: 'Programar envío',
    icon: 'time_line',
  },
];

export const SendDocumentButton = (props: SendDocumentButtonProps) => {
  const { scheduledFor, handleContent, onSubmit, onSchedule } = props;
  const theme = useTheme();
  const { isMobile } = useScreenSize();

  const userHasPermissionToUpdate = usePermissionChecker({
    permission: 'docs',
    action: 'update',
  });

  const anchorRef = useRef(null);
  const datePickerRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [date, setDate] = useState(null);

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.click();
    }
  };

  const handleClick = (index) => {
    onSubmit();
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    if (index === 1) {
      openDatePicker();
    }
  };

  const handleToggle = (e) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (!scheduledFor) {
      setSelectedIndex(0);
    }
  }, [scheduledFor]);

  return (
    <>
      <Button
        variant="outlined"
        size={isMobile ? 'small' : 'large'}
        color="default"
        onClick={() => handleContent('SIGNERS')}
      >
        Volver
      </Button>
      <Box position="relative">
        <Tooltip
          title={
            userHasPermissionToUpdate
              ? ''
              : 'No tienes permisos para realizar esta acción'
          }
          placement="left"
          arrow
        >
          <span>
            <ButtonGroup disabled={!userHasPermissionToUpdate}>
              <Button
                color="primary"
                size={isMobile ? 'medium' : 'large'}
                variant="contained"
                onClick={() => handleClick(selectedIndex)}
                startIcon={<Icon icon={options[selectedIndex].icon} />}
                ref={anchorRef}
              >
                {options[selectedIndex].label}
              </Button>
              <Button
                color="primary"
                size={isMobile ? 'medium' : 'large'}
                variant="contained"
                aria-haspopup="menu"
                onClick={handleToggle}
                ref={anchorRef}
              >
                <Icon icon={open ? 'arrow_up_s_line' : 'arrow_down_s_line'} />
              </Button>
            </ButtonGroup>
          </span>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="bottom-start"
          style={{
            zIndex: 1,
            width: '100%',
          }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'left-start',
              }}
            >
              <Paper
                sx={{ padding: 1, boxShadow: theme.newPalette.shadow.dropdown }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.label}
                        disabled={index === 2}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        <Stack direction={'row'} gap={1}>
                          <Icon icon={option.icon} />
                          <Typography variant="body2">
                            {option.label}
                          </Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          localeText={
            esES.components.MuiLocalizationProvider.defaultProps.localeText
          }
          adapterLocale={es}
        >
          <MobileDateTimePicker
            sx={{ display: 'none' }}
            disablePast={true}
            ampm={true}
            ref={datePickerRef}
            views={['year', 'month', 'day', 'hours']}
            value={date}
            onChange={(newValue) => {
              if (newValue && newValue < new Date()) {
                globalSnackbarVar({
                  show: true,
                  message: 'La fecha no puede ser anterior a la fecha actual',
                  severity: 'error',
                });
                setDate(todayAt0Minutes());
                onSchedule(todayAt0Minutes());
              } else {
                setDate(newValue);
                onSchedule(newValue);
              }
            }}
            onAccept={(newValue) => {
              if (newValue && newValue < new Date()) {
                globalSnackbarVar({
                  show: true,
                  message: 'La fecha no puede ser anterior a la fecha actual',
                  severity: 'error',
                });
                setDate(todayAt0Minutes());
              } else {
                setDate(newValue);
              }
            }}
          />
        </LocalizationProvider>
      </Box>
    </>
  );
};
