import { useMutation, useReactiveVar } from '@apollo/client';
import { CREATE_TEMPLATE } from '../../gql';
import type { TemplateToCreate } from '../Template.types';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../../../cache.reactiveVars';
import { updateTemplatesVar } from '../../../../MyCompany/Settings/Preferences/Templates/Templates.vars.js';

export type CreateTemplateInput = {
  data: TemplateToCreate;
  onSuccess: () => void;
};

export const useTemplateCreation = () => {
  const company = useReactiveVar(currentCompanyVar);
  const user = useReactiveVar(userVar);
  const [CreateTemplate] = useMutation(CREATE_TEMPLATE);

  const handleCreateTemplate = async (input: CreateTemplateInput) => {
    if (!company?._id || !user?._id) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message:
          'No se ha podido obtener la información de la empresa o del usuario al crear plantilla',
      });
      return;
    }

    try {
      const { data, onSuccess } = input;

      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Guardando plantilla...',
      });

      let authIds = [];

      if (data?.authorizers?.length) {
        authIds = data.authorizers.map((auth) => auth?._id || auth?.user?._id);
      }

      const creationRes = await CreateTemplate({
        variables: {
          input: {
            companyId: company?._id,
            creatorId: user?._id,
            title: data.title,
            description: data.description,
            htmlContent: data.myEditor,
            color: {
              primary: data.color,
              secondary: data.secondaryColor,
            },
            categories: {
              category: data.category || '',
              subcategory: data.subcategory || '',
            },
            authorizers: authIds ?? [],
            authorizeInOrder: Boolean(data.authorizeInOrder),
          },
        },
      });

      const { createTemplate } = creationRes.data;

      if (createTemplate?.__typename === 'ErrorToCreateTemplate') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: createTemplate.message,
        });
        return;
      }

      if (createTemplate.__typename === 'TemplateCreated') {
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Plantilla creada',
        });
        onSuccess();
      }
      updateTemplatesVar(!updateTemplatesVar());
    } catch (error) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleCreateTemplate };
};
