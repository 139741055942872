import type {
  SignatureParams,
  TitleAndSubHeader,
} from '../CreateDocumentDialog.types';

export const getTitleAndSubHeaderCompanySigners = ({
  employeeSignFirst,
  orderSignature,
}: Omit<SignatureParams, 'companySignersSignInOrder'>): TitleAndSubHeader => {
  if (!orderSignature) {
    return {
      title: 'Firmantes de empresa',
      subHeader: 'Todos firman de forma aleatoria',
    };
  }

  if (employeeSignFirst) {
    return {
      title: 'Firmantes de empresa',
      subHeader: 'Este grupo firma segundo',
    };
  }

  return {
    title: 'Firmantes de empresa',
    subHeader: 'Este grupo firma primero',
  };
};

// Función getForcedIndex
export const getForcedIndexCompanySigners = ({
  employeeSignFirst,
  orderSignature,
  companySignersSignInOrder,
}: SignatureParams): number | undefined => {
  if (orderSignature && employeeSignFirst && companySignersSignInOrder) {
    return 0;
  }

  if (orderSignature && employeeSignFirst && !companySignersSignInOrder) {
    return 2;
  }

  if (orderSignature && !employeeSignFirst && !companySignersSignInOrder) {
    return 1;
  }

  return undefined;
};

export const getTitleAndSubHeaderSigners = ({
  employeeSignFirst,
  orderSignature,
}: Omit<SignatureParams, 'companySignersSignInOrder'>): TitleAndSubHeader => {
  if (!orderSignature) {
    return {
      title: 'Firmantes',
      subHeader: 'Todos firman de forma aleatoria',
    };
  }

  if (employeeSignFirst) {
    return {
      title: 'Empleados',
      subHeader: 'Este grupo firma primero',
    };
  }

  return {
    title: 'Empleados',
    subHeader: 'Este grupo firma segundo',
  };
};

export const getForcedIndexSigners = ({
  employeeSignFirst,
  orderSignature,
  companySignersSignInOrder,
  signersCounter,
}: SignatureParams): number | undefined => {
  if (orderSignature && employeeSignFirst) {
    return 1;
  }

  if (orderSignature && !employeeSignFirst && companySignersSignInOrder) {
    return (signersCounter?.companySigners ?? 0) + 1;
  }

  if (orderSignature && !employeeSignFirst && !companySignersSignInOrder) {
    return 2;
  }

  return undefined;
};
