import { ListItem } from '../../../../../../newComponents/ListItem';
import { ListItemIcon } from '../../../../../../newComponents/ListItemIcon';
import { Checkbox } from '../../../../../../newComponents/Checkbox';
import { Stack } from '@mui/material';
import { Label } from '../../../../../../newComponents/Label';

import { RenderAvatarItem } from './RenderAvatarItem';
import { RenderTextItem } from './RenderTextItem';

export const UserOverlay = ({
  selected,
  users = [],
  selectedUsers = [],
  isOrdered,
  forcedIndex,
}) => {
  const isChecked = selectedUsers.some((user) => {
    if (selected?._id) {
      return user?._id === selected?._id;
    }

    return user?.name === selected?.name;
  });
  const index = users.findIndex((user) => {
    if (selected?._id) {
      return user?._id === selected?._id;
    }

    return user?.name === selected?.name;
  });

  const newIndex =
    forcedIndex === 0 ? 1 + (index + 1) : (forcedIndex ?? index + 1);

  return (
    <ListItem
      sx={{
        background: (theme) => theme.palette.background.paper,
        boxShadow: (theme) => theme.shadows[24],
        cursor: 'grabbing',
        height: 68,
      }}
    >
      <ListItemIcon>
        <Checkbox color="primary" checked={Boolean(isChecked)} />
      </ListItemIcon>
      <Stack direction="row" alignItems="center" spacing={2}>
        {isOrdered ? (
          <Label variant="outlined" color="default" label={newIndex} />
        ) : null}
        <RenderAvatarItem row={selected} />
        <RenderTextItem user={selected} />
      </Stack>
    </ListItem>
  );
};
