import { useMutation, useReactiveVar } from '@apollo/client';
import { UPDATE_TEMPLATE } from '../../gql';
import type { TemplateToUpdate } from '../Template.types';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../../../cache.reactiveVars';
import { updateTemplatesVar } from '../../../../MyCompany/Settings/Preferences/Templates/Templates.vars.js';

export type UpdateTemplateInput = {
  data: TemplateToUpdate;
  onSuccess: () => void;
  excludeUpdates?: string[];
  content: string;
};

export const useTemplateUpdate = () => {
  const user = useReactiveVar(userVar);
  const [UpdateTemplate] = useMutation(UPDATE_TEMPLATE);

  const handleUpdateTemplate = async (input: UpdateTemplateInput) => {
    if (!user?._id) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Falta información del usuario para actualizar plantilla',
      });
      return;
    }

    try {
      const { data, onSuccess, excludeUpdates = [], content } = input;
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Guardando cambios...',
      });

      let authIds = [];
      if (data?.authorizers?.length) {
        authIds = data.authorizers.map((auth) => auth?._id || auth.user?._id);
      }

      const updateRes = await UpdateTemplate({
        variables: {
          input: {
            _id: data._id,
            userId: user._id,
            title: data.title,
            description: data.description,
            htmlContent: data.myEditor,
            color: {
              primary: data.color,
              secondary: data.secondaryColor,
            },
            categories: {
              category: data.category,
              subcategory: data.subcategory,
            },
            authorizers: authIds ?? [],
            authorizeInOrder: Boolean(data.authorizeInOrder),
            excludeUpdates: excludeUpdates,
            content: content,
          },
        },
      });

      const response = updateRes.data?.updateTemplate;
      if (response?.__typename === 'ErrorToUpdateTemplate') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: response?.message || 'No se pudo actualizar la plantilla',
        });
        return;
      }

      globalSnackbarVar({
        show: true,
        severity: 'success',
        message: 'Plantilla actualizada',
      });
      onSuccess();
      updateTemplatesVar(!updateTemplatesVar());
    } catch (error) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleUpdateTemplate };
};
