import { useLazyQuery } from '@apollo/client';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { GET_SIMPLE_EMPLOYEE_INFO } from '../EmployeeInfo/EmployeeInfo.gql';

export type EmployeeInfo = {
  employeeId: string;
  employeeWorkSpaceId: string | null;
};

export type GetSimpleEmployeeInfoInput = {
  data: EmployeeInfo;
  onSuccess: (response?: any) => void;
};

export const useGetSimpleEmployeeInfo = () => {
  const [getSimpleEmployeeInfo] = useLazyQuery(GET_SIMPLE_EMPLOYEE_INFO);

  const handleSimpleEmployeeInfo = async (
    input: GetSimpleEmployeeInfoInput,
  ) => {
    try {
      if (!input.data.employeeId) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Falta información del empleado',
        });
        return;
      }

      const { data, onSuccess } = input;

      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo información del empleado...',
      });

      const res = await getSimpleEmployeeInfo({
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: {
          input: {
            employeeId: data.employeeId,
            employeeWorkSpaceId: null,
          },
        },
      });

      const response = res.data?.getEmployeeInfo?.employeeInfo;

      onSuccess({ employee: response });
    } catch (error) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleSimpleEmployeeInfo };
};
