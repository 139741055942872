import { Stack } from '@mui/material';
import { Typography } from '../../../../../../newComponents/Typography';
import { RenderExpandedText } from '../../../../../../components/ExpandedText/RenderExpandedText';
import {
  COMPANY_SIGNER_ROW,
  EMPLOYEE_ROW,
  GROUP_ROW,
  WORK_CENTER_ROW,
  WORK_TITLE_ROW,
} from '../../../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';
import type { RenderItemTextProps } from '../../CreateDocumentDialog.types';

const MAP_SECONDARY_TEXT = {
  [WORK_TITLE_ROW]: 'Puesto de trabajo',
  [WORK_CENTER_ROW]: 'Centro de trabajo',
  [GROUP_ROW]: 'Grupo',
  [COMPANY_SIGNER_ROW]: 'Firmante de empresa',
  [EMPLOYEE_ROW]: (user) => `${user?.workCenterName || ''}`,
};

export const RenderTextItem = (props: RenderItemTextProps) => {
  const { user, containerMaxWidth = 350 } = props;
  const type = user?.type;

  if (user?.success === false) {
    return (
      <>
        <Typography variant="subtitle2">{user?.user}</Typography>
        <Typography variant="body2" color="error">
          {user.message}
        </Typography>
      </>
    );
  }

  return (
    <Stack justifyContent="center" width="100%">
      <RenderExpandedText
        containerMaxWidth={containerMaxWidth}
        text={user?.fullName || user?.name}
        arrow
      >
        <Typography variant="subtitle2" component="span">
          {user?.fullName || user?.name}
        </Typography>
      </RenderExpandedText>
      <Stack
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mt="2px"
      >
        <Typography variant="body2" color="text.secondary">
          {user?.type === EMPLOYEE_ROW
            ? MAP_SECONDARY_TEXT[type](user)
            : MAP_SECONDARY_TEXT[type]}
        </Typography>
      </Stack>
    </Stack>
  );
};
