import type { Variable } from './InsertVariablesButton.types';

export const VARIABLES: Variable[] = [
  // TODO: Add date to variables {
  //   text: 'Fecha de hoy',
  //   variable: 'Fecha_de_hoy',
  //   category: 'date',
  // },
  {
    text: '[Nombre del Empleado]',
    variable: 'Nombre_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_NAME',
    dbName: 'names',
  },
  {
    text: '[RFC del Empleado]',
    variable: 'RFC_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_RFC',
    dbName: 'rfc',
  },
  {
    text: '[No. de nómina del Empleado]',
    variable: 'No_nomina_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_PAYROLL_ID',
    dbName: 'payrollId',
  },
  {
    text: '[Puesto del Empleado]',
    variable: 'Puesto_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_WORKTITLE',
    dbName: 'workTitle',
  },
  {
    variable: 'Centro_de_Trabajo_del_Empleado',
    text: '[Centro de Trabajo del Empleado]',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_WORKCENTER',
    dbName: 'workCenterId',
  },
  {
    text: '[CURP del Empleado]',
    variable: 'CURP_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_CURP',
    dbName: 'curp',
  },
  {
    text: '[Fecha de nacimiento del empleado]',
    variable: 'Fecha_de_nacimiento_del_empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_BIRTHDATE',
    dbName: 'birthdate',
  },
  {
    text: '[Edad del Empleado]',
    variable: 'Edad_del_Empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_AGE',
    dbName: 'age',
  },
  {
    text: '[Sexo]',
    variable: 'Sexo',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_GENDER',
    dbName: 'gender',
  },
  {
    text: '[Estado Civil del empleado]',
    variable: 'Estado_Civil_del_empleado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_MARITAL_STATUS',
    dbName: 'maritalStatus',
  },
  {
    text: '[País de Nacimiento]',
    variable: 'Pais_de_Nacimiento',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_COUNTRY',
    dbName: 'country',
  },
  {
    text: '[Código Postal]',
    variable: 'Codigo_Postal',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_ZIP_CODE',
    dbName: 'zipCode',
  },
  {
    text: '[Estado]',
    variable: 'Estado',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_STATE',
    dbName: 'state',
  },
  {
    text: '[Ciudad]',
    variable: 'Ciudad',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_CITY',
    dbName: 'city',
  },
  {
    text: '[Colonia]',
    variable: 'Colonia',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_COLONY',
    dbName: 'colony',
  },
  {
    text: '[Calle]',
    variable: 'Calle',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_STREET',
    dbName: 'street',
  },
  {
    text: '[Número exterior]',
    variable: 'Numero_exterior',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_EXTERNAL_NUMBER',
    dbName: 'externalNumber',
  },
  {
    text: '[Número interior]',
    variable: 'Numero_interior',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_INTERNAL_NUMBER',
    dbName: 'internalNumber',
  },
  {
    text: '[Salario]',
    variable: 'Salario',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_SALARY',
    dbName: 'salary',
  },
  {
    text: '[Número de Seguridad Social]',
    variable: 'Numero_de_Seguridad_Social',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_SOCIAL_SECURITY_NUMBER',
    dbName: 'socialSecurityNumber',
  },
  {
    text: '[Tipo de Contrato]',
    variable: 'Tipo_de_Contrato',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_CONTRACT_TYPE',
    dbName: 'contractType',
  },
  {
    text: '[Tipo de Jornada]',
    variable: 'Tipo_de_Jornada',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_WORK_DAY_TYPE',
    dbName: 'workDayType',
  },
  {
    text: '[Fecha de Ingreso]',
    variable: 'Fecha_de_Ingreso',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_EMPLOYMENT_START_DATE',
    dbName: 'employmentStartDate',
  },
  {
    text: '[Antigüedad]',
    variable: 'Antiguedad',
    category: 'employee',
    color: 'success',
    key: 'EMPLOYEE_JOB_SENIORITY',
    dbName: 'jobSeniority',
  },
  {
    text: '[Nombre de la Empresa]',
    variable: 'Nombre_de_la_Empresa',
    category: 'company',
    color: 'warning',
    key: 'COMPANY_NAME',
    dbName: 'name',
  },
];

type FailedEmployeeFields = {
  [key: string]: {
    text: string;
    db: string;
  };
};

export const FAILED_EMPLOYEE_FIELDS: FailedEmployeeFields =
  VARIABLES.reduce<FailedEmployeeFields>((acc, variable: Variable) => {
    if (variable.category === 'employee') {
      acc[variable.key] = {
        text: variable.text,
        db: variable.dbName,
      };
    }
    return acc;
  }, {});

export const MAPPING_EMPLOYEE_FIELDS = VARIABLES.reduce((acc, variable) => {
  if (variable.category === 'employee') {
    acc[variable.variable] = {
      key: variable.key,
    };
  }
  return acc;
});
