//@ts-check
import { Autocomplete, Divider, InputAdornment, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Typography } from '../../newComponents/Typography';
import { GET_EMPLOYEE_RECORDS_TAGS } from '../../containers/MyCompany/gql';
import { MenuItem } from '../../newComponents/MenuItem';
import { Checkbox } from '../../newComponents/Checkbox';
import { TextInput } from '../../newComponents/TextInput';
import { Icon } from '../../components/Icons/Icons';
import { getFlatTags } from './SingleEmployeeRecordTagSelector.helpers';
import { CircularProgress } from '../../newComponents/Progress';
import { SingleEmployeeRecordTagGroupHeader } from './SingleEmployeeRecordTagGroupHeader';

/**
 * @typedef {object} Subcategory
 * @property {string} __typename - The GraphQL typename.
 * @property {string} _id - The unique identifier for the subcategory.
 * @property {string} subcategory - The name of the subcategory.
 * @property {string} description - The description of the subcategory.
 */

/**
 * @typedef {object} GetEmployeeRecordsTagsResult
 * @property {string} __typename - The GraphQL typename.
 * @property {string} _id - The unique identifier for the category.
 * @property {string} category - The name of the category.
 * @property {string} description - The description of the category.
 * @property {string} color - The color associated with the category.
 * @property {Subcategory[]} subcategories - The list of subcategories under this category.
 */

export function SingleEmployeeRecordTagSelector({
  selectedTag,
  onSelectTag,
  hideAbsenceItems,
}) {
  const [open, setOpen] = React.useState(false);
  const [getEmployeeRecordTags, { data, loading }] = useLazyQuery(
    GET_EMPLOYEE_RECORDS_TAGS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (open && !data && !loading) {
      getEmployeeRecordTags();
    }
  }, [open, data, loading, getEmployeeRecordTags]);

  let tags = data?.getEmployeeRecordsTags || [];

  if (hideAbsenceItems) {
    tags = tags.filter((tag) => tag?.category !== 'Ausencias');
  }

  const flatTags = getFlatTags(tags);

  /** @type {import('../../theme').CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  return (
    <Autocomplete
      id="grouped-category"
      fullWidth
      clearOnBlur={false}
      disableClearable
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={flatTags}
      value={selectedTag}
      onChange={onSelectTag}
      groupBy={(option) => option.category}
      loading={loading}
      loadingText={
        <Typography variant="subtitle2" color="text.secondary">
          Buscando tipos de documento...
        </Typography>
      }
      noOptionsText={!flatTags?.length ? 'Sin elementos' : 'Sin coincidencias'}
      getOptionLabel={(option) => {
        return option?.subcategory || option?.category;
      }}
      isOptionEqualToValue={(option, value) => {
        return option.combinedId === value.combinedId;
      }}
      renderOption={(props, option, { selected }) => {
        const { key, ...restProps } = props;
        return (
          <MenuItem key={key} {...restProps} sx={{ gap: 1, width: '100%' }}>
            <Checkbox checked={selected} />
            <Typography component="span" variant="body2">
              {option.subcategory}
            </Typography>
          </MenuItem>
        );
      }}
      renderInput={(params) => {
        return (
          <TextInput
            {...params}
            variant="outlined"
            placeholder="Elegir tipos"
            fullWidth
            InputProps={{
              ...(params.InputProps || {}),
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="bookmark_line" height="20px" color="grey" />
                </InputAdornment>
              ),
              ...(loading
                ? {
                    endAdornment: (
                      <React.Fragment>
                        <CircularProgress size={20} />
                      </React.Fragment>
                    ),
                  }
                : {}),
            }}
          />
        );
      }}
      popupIcon={
        <Icon
          icon="arrow_down_s_line"
          style={{ margin: '0px' }}
          height="20px"
          color="grey"
        />
      }
      slotProps={{ ...slotProps(palette) }}
      renderGroup={(params) => {
        return (
          <li key={params.key}>
            <SingleEmployeeRecordTagGroupHeader tags={flatTags}>
              {params.group}
            </SingleEmployeeRecordTagGroupHeader>
            <Divider />
            <ul>
              <Typography variant="body2">{params.children}</Typography>
            </ul>
          </li>
        );
      }}
    />
  );
}

const slotProps = (palette, width) => {
  return {
    popper: {
      sx: {
        '& .MuiAutocomplete-listbox': {
          maxHeight: '250px !important',
          boxSizing: 'border-box',
          '& ul': {
            padding: 0,
            margin: 0,
          },
        },
        width: '100%',
      },
    },
    paper: {
      sx: {
        borderRadius: '6px',
        boxShadow: palette.shadow.dialog,
        width: '100%',
      },
    },
  };
};
