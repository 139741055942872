//@ts-check
import { useQuery, useReactiveVar } from '@apollo/client';
import { useCustomTheme } from '../../../../../../Hooks/useCustomTheme';
import {
  currentCompanyVar,
  globalSnackbarVar,
} from '../../../../../../cache.reactiveVars';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { ALL_TEMPLATES_FROM_TABLE } from '../../../../../RHPod/Documents/gql';
import { Box, InputAdornment, Stack } from '@mui/material';
import { TabsDialog } from '../../../../../RHPod/Dashboard/common/TabsDialog';
import { TEMPLATES_TABS } from '../Templates.constants';
import { TextInput } from '../../../../../../newComponents/TextInput';
import { Icon } from '../../../../../../components/Icons/Icons';
import { DataGrid } from '../../../../../../newComponents/DataGrid';
import { esES } from '@mui/x-data-grid';
import {
  TEMPLATES_TABLE_COLUMNS,
  TEMPLATES_TABLE_COLUMNS_PUBLISHED,
} from './TemplatesTableColumns';
import { gridTemplatesTableStyle } from '../Templates.styles';
import {
  openPreviewTemplateDialogVar,
  updateTemplatesVar,
} from '../Templates.vars';
import { GET_ALL_TEMPLATES_COUNTERS } from '../Templates.gql';
import { TemplatePreviewDialog } from '../TemplatesPreview/TemplatePreviewDialog';
import { Checkbox } from '../../../../../../newComponents/Checkbox';
import {
  SORA_MODULES,
  useBlockableActions,
} from '../../../../../../components/ModuleBlockableComponent';
import { useScreenSize } from '../../../../../../Hooks';
import { ClipboardImg } from '../../../../../../components/Illustrations/Illustrations';
import { Typography } from '../../../../../../newComponents/Typography';

const NoRowsOverlay = () => {
  const { isMobile } = useScreenSize();
  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      sx={{ ml: 2 }}
    >
      <ClipboardImg
        width={isMobile ? '100px' : '200px'}
        height={isMobile ? '100px' : '200px'}
      />
      <Typography variant="h5" textAlign="center">
        No se han encontrado resultados
      </Typography>
    </Stack>
  );
};

const TemplatesTable = () => {
  const theme = useCustomTheme();
  const { isMobile } = useScreenSize();
  const currentCompany = useReactiveVar(currentCompanyVar);
  const updateTemplates = useReactiveVar(updateTemplatesVar);
  const {
    onClick: documentsModuleIsBlockedOnClick,
    isModuleAvailable: isDocumentsModuleAvailable,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS);

  const [tab, setTab] = useState('PENDING');
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [delayedSearch, setDelayedSearch] = useState('');
  const [timer, setTimer] = useState(null);
  const [selectedTemplates, setSelectedTemplates] = useState([]);

  const { loading, refetch, fetchMore, data } = useQuery(
    ALL_TEMPLATES_FROM_TABLE,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        page: 0,
        perPage: 10,
        filter: {
          companyId: currentCompany?._id,
          search: delayedSearch,
          status: tab,
        },
      },
      onError: (err) => {
        // @ts-ignore
        if (err.networkError?.statusCode === 401) {
          globalSnackbarVar({
            show: true,
            severity: 'error',
            message: 'Acceso denegado',
          });
        }
      },
    },
  );

  const {
    loading: loadingCounters,
    data: countersData,
    refetch: refetchCounters,
  } = useQuery(GET_ALL_TEMPLATES_COUNTERS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        companyId: currentCompany?._id,
        search: delayedSearch,
      },
    },
    onError: (err) => {
      // @ts-ignore
      if (err.networkError?.statusCode === 401) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Acceso denegado',
        });
      }
    },
  });

  const handlePageChange = (newPage) => {
    if (newPage < page) return setPage(newPage);
    fetchMore({
      variables: {
        page: newPage,
        perPage: perPage,
      },
    });

    if (!loading) setPage(newPage);
  };

  const handlePerPageChage = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(0);
    fetchMore({
      variables: {
        perPage: newPerPage,
        page: 0,
      },
    });
  };

  const handleSearchChange = (value) => {
    setSearch(value);
    if (value?.length < 3 && value !== '') return;

    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      setPage(0);
      setDelayedSearch(value);
    }, 1500);

    //@ts-ignore
    setTimer(newTimer);
  };

  const handleChangeTab = (e, newTab) => {
    setTab(newTab);
    setPage(0);
  };

  const handlePreviewTemplateDialog = useCallback(
    (template) => {
      if (!template) return null;
      if (!isDocumentsModuleAvailable) {
        return documentsModuleIsBlockedOnClick();
      }
      openPreviewTemplateDialogVar({
        open: true,
        template: template,
      });
    },
    [isDocumentsModuleAvailable, documentsModuleIsBlockedOnClick],
  );

  useEffect(() => {
    setPage(0);
    refetch({
      page: 0,
      perPage: perPage,
      filter: {
        companyId: currentCompany?._id,
        search: delayedSearch,
        status: tab,
      },
    });
    refetchCounters({
      filter: {
        companyId: currentCompany?._id,
        search: delayedSearch,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTemplates]);

  const total = data?.allTemplates?.total || 0;
  const templates = data?.allTemplates?.templates || [];

  const slicedTemplates = templates.slice(
    page * perPage,
    page * perPage + perPage,
  );

  const columns = useMemo(() => {
    return TEMPLATES_TABLE_COLUMNS({
      handlePreviewTemplateDialog,
    });
  }, [handlePreviewTemplateDialog]);

  const publishedColumns = useMemo(() => {
    return TEMPLATES_TABLE_COLUMNS_PUBLISHED({
      handlePreviewTemplateDialog,
    });
  }, [handlePreviewTemplateDialog]);

  const currentColumns = tab === 'PUBLISHED' ? publishedColumns : columns;

  return (
    <Stack
      id="templates-table"
      sx={{
        boxShadow: theme.newPalette.shadow.z12,
        width: '100%',
      }}
    >
      <TabsDialog
        tab={tab}
        handleChangeTab={handleChangeTab}
        tabs={TEMPLATES_TABS({ theme })}
        objectToMap={countersData?.getAllTemplatesCounters}
        loading={loadingCounters}
      />
      <Stack
        p={3}
        sx={{
          borderLeft: `1px solid ${theme.newPalette.grey.grey200}`,
          borderRight: `1px solid ${theme.newPalette.grey.grey200}`,
        }}
        direction={{ xs: 'column', sm: 'column', md: 'row' }}
        alignItems="center"
        spacing={2}
      >
        <TextInput
          name="templates_search"
          placeholder="Buscar"
          variant="outlined"
          value={search}
          onChange={(event) => handleSearchChange(event.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon="search_line" />
              </InputAdornment>
            ),
          }}
          size={isMobile ? 'small' : 'medium'}
        />
      </Stack>
      <Box sx={{ display: 'flex', height: 650 }}>
        <DataGrid
          columns={currentColumns}
          rows={total > 0 ? slicedTemplates : []}
          rowCount={total}
          getRowId={(row) => row._id || row.id}
          page={page}
          pageSize={perPage}
          onPageChange={(newPage) => handlePageChange(newPage)}
          onPageSizeChange={(newPageSize) => handlePerPageChage(newPageSize)}
          rowsPerPageOptions={[10, 25, 50]}
          rowBuffer={10}
          paginationMode="server"
          keepNonExistentRowsSelected
          loading={loading}
          components={{
            BaseCheckbox: forwardRef((props, ref) => {
              return <Checkbox {...props} ref={ref} />;
            }),
            NoRowsOverlay: NoRowsOverlay,
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          onSelectionModelChange={(selectedAbsence) => {
            setSelectedTemplates(selectedAbsence);
          }}
          selectionModel={selectedTemplates}
          checkboxSelection={false}
          rowHeight={60}
          headerHeight={56}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          hideFooterSelectedRowCount
          disableSelectionOnClick
          sx={{ ...gridTemplatesTableStyle({ theme }) }}
        />
      </Box>

      <TemplatePreviewDialog />
    </Stack>
  );
};

export default TemplatesTable;
