//@ts-check

import React, { useEffect } from 'react';
import { Dialog } from '../../../../components/Dialogs/Dialog';
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  createEmployeeAbsenceDialogVar,
  resetCreateEmployeeAbsenceDialogVar,
} from '../EmployeeAbsence.vars';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { IconButton } from '../../../../newComponents/IconButton';
import { Button } from '../../../../newComponents/Button';
import { useForm } from 'react-hook-form';
import { CreateEmployeeAbsenceForm } from './CreateEmployeeAbsenceForm';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { useScreenSize } from '../../../../Hooks/useScreenSize';
import { CREATE_EMPLOYEE_ABSENCE } from '../EmployeeAbsence.gql';
import { useRefetchAbsenceQueries } from '../hooks/useRefetchQueries';

/** @type {import('../EmployeeAbsence.types').FormEmployeeAbsence} */
const INITIAL_FORM_VALUES = {
  employee: null,
  start: null,
  end: null,
  updateStatusToEndAbsent: true,
  reason: '',
  description: '',
  file: null,
  errors: [],
};

export const CreateEmployeeAbsenceDialog = () => {
  const { isMobile } = useScreenSize();
  const { open, employee } = useReactiveVar(createEmployeeAbsenceDialogVar);
  const currentCompany = useReactiveVar(currentCompanyVar);
  const refetchAbsenceQueries = useRefetchAbsenceQueries();
  const [createEmployeeAbsence] = useMutation(CREATE_EMPLOYEE_ABSENCE);

  const form = useForm({
    mode: 'onChange',
    defaultValues: INITIAL_FORM_VALUES,
    reValidateMode: 'onChange',
  });

  const { isValid } = form.formState;
  const [watchErrors] = form.watch(['errors']);

  const handleClose = () => {
    form.reset();
    resetCreateEmployeeAbsenceDialogVar();
  };

  const onSubmit = async () => {
    const absenceValues = form.getValues();
    const start = absenceValues?.start;
    const end = absenceValues?.end;

    if (start && end) {
      if (start?.getTime() > end?.getTime()) {
        return globalSnackbarVar({
          show: true,
          message: 'La fecha de inicio no puede ser mayor a la fecha de fin',
          severity: 'error',
          duration: 5000,
        });
      }
    }

    globalBackdropVar({ open: true, text: 'Creando ausencia...' });
    try {
      const response = await createEmployeeAbsence({
        variables: {
          input: {
            companyId: currentCompany?._id,
            employeeId:
              absenceValues.employee?.userId || absenceValues.employee?._id,
            start: absenceValues.start,
            end: absenceValues.end,
            updateStatusToEndAbsent: absenceValues.updateStatusToEndAbsent,
            reason: absenceValues.reason,
            description: absenceValues.description,
            file: absenceValues.file?.length ? absenceValues.file[0] : null,
          },
        },
      });

      const data = response?.data?.createEmployeeAbsence;

      if (data?.__typename === 'ErrorToCreateEmployeeAbsence') {
        globalSnackbarVar({
          show: true,
          message: data.message || 'Error al crear ausencia, contacta a SORA.',
          severity: 'error',
          duration: 5000,
        });
        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Ausencia creada',
        severity: 'success',
        duration: 5000,
      });

      if (refetchAbsenceQueries) {
        refetchAbsenceQueries();
      }
      handleClose();
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Error al crear ausencia, contacta a SORA',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  useEffect(() => {
    if (!employee) return;
    form.setValue('employee', employee);
  }, [employee, form]);

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={handleClose}
      showCloseButton={false}
      zoomTransition={false}
    >
      <DialogTitle
        component="div"
        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
      >
        <IconButton
          icon={isMobile ? 'arrow_left_s_line' : 'close_line'}
          onClick={handleClose}
        />
        Registrar ausencia
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ p: '24px' /* display: 'flex', alignItems: 'stretch'  */ }}
      >
        <CreateEmployeeAbsenceForm form={form} />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          variant="contained"
          size="large"
          onClick={onSubmit}
          disabled={!isValid || Boolean(watchErrors?.length)}
        >
          Finalizar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
