//@ts-check
import { useEffect, useState } from 'react';
import { Dialog } from '../../../../../../components/Dialogs/Dialog';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { useScreenSize } from '../../../../../../Hooks';
import { IconButton } from '../../../../../../newComponents/IconButton';
import { Typography } from '../../../../../../newComponents/Typography';
// import { device } from '../../../../../../globalTheme';
// import styled from '@emotion/styled';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { GET_TEMPLATE } from '../../../../../RHPod/Documents/gql';
import {
  approveTemplateDialogVar,
  openPreviewTemplateDialogVar,
  rejectTemplateDialogVar,
  resetOpenPreviewTemplateDialogVar,
} from '../Templates.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../../cache.reactiveVars';
import { RejectTemplateDialog } from './RejectTemplateDialog';
import { AuthorizeTemplateDialog } from './AuthorizeTemplateDialog';
import { PublishTemplateAction } from './PublishTemplateAction';
import { PublishTemplateDialog } from './PublishTemplateDIalog';
import { RenderTemplatePreviewItem } from '../Templates.helpers';
import { ReplaceVariablesButton } from '../../../../../../businessComponents/ReplaceVariablesButton';
import { extractAndMapVariablesOptimized } from '../../../../../../businessComponents/InsertVariablesButton/insertVariableButton.helpers';

export const TemplatePreviewDialog = ({
  onOpenTemplate = undefined,
  replaceVariables = false,
  htmlContent = '',
  handleSelectRow = null,
  onClose = null,
}) => {
  const { isMobile } = useScreenSize();
  const { open, template } = useReactiveVar(openPreviewTemplateDialogVar);
  const [currTemplate, setCurrTemplate] = useState(null);
  const [replacedHtml, setReplacedHtml] = useState(htmlContent);
  const [getTemplateQuery] = useLazyQuery(GET_TEMPLATE);

  const getTemplateFn = async () => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Abriendo plantilla...',
      });

      const templateRes = await getTemplateQuery({
        query: GET_TEMPLATE,
        variables: { _id: template?._id },
        fetchPolicy: 'network-only',
      });

      const { getTemplate } = templateRes.data;
      if (getTemplate.__typename === 'TemplateResult') {
        return setCurrTemplate(getTemplate);
      }
      if (getTemplate.__typename === 'ResourceNotFound') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'La plantilla no se encuentra registrada',
        });
      }
    } catch (err) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const rejectTemplate = () => {
    rejectTemplateDialogVar({
      open: true,
      templateId: currTemplate?._id,
    });
  };

  const approveTemplate = () => {
    approveTemplateDialogVar({
      open: true,
      templateId: currTemplate?._id,
    });
  };

  const handleClose = () => {
    setReplacedHtml('');
    setCurrTemplate(null);
    if (onClose) onClose();
    resetOpenPreviewTemplateDialogVar();
  };

  useEffect(() => {
    if (open && template) {
      getTemplateFn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, template]);

  useEffect(() => {
    if (open) {
      setReplacedHtml(htmlContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!replacedHtml && !currTemplate) return null;

  const vars = extractAndMapVariablesOptimized(
    htmlContent || currTemplate?.htmlContent,
  );
  const selectedVars = vars?.uniqueVariables ?? [];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      zoomTransition={false}
      showCloseButton={false}
    >
      <DialogTitle component="div">
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">{currTemplate?.title}</Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            {!replaceVariables ? null : (
              <ReplaceVariablesButton
                handleSelectRow={handleSelectRow}
                setTemplate={setReplacedHtml}
                htmlTemplate={htmlContent || currTemplate?.htmlContent || ''}
                buttonProps={{ disabled: !Boolean(selectedVars.length) }}
              />
            )}
            {isMobile ? null : (
              <RenderTemplatePreviewItem
                template={currTemplate}
                approveTemplate={approveTemplate}
                rejectTemplate={rejectTemplate}
                onOpenTemplate={onOpenTemplate}
              />
            )}
            {isMobile && currTemplate?.status === 'READY_TO_PUBLISH' ? (
              <PublishTemplateAction
                template={currTemplate}
                onOpenTemplate={onOpenTemplate}
              />
            ) : null}
            <IconButton
              icon={isMobile ? 'arrow_left_s_line' : 'close_line'}
              onClick={handleClose}
            />
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        <Stack
          sx={{
            maxWidth: '100%',
            // height: '100vh',
            backgroundColor: 'dimgray',
            overflowY: 'auto',
            padding: '10px',
            width: '100vw',
          }}
        >
          <div
            className="document"
            dangerouslySetInnerHTML={{
              __html: replacedHtml || currTemplate?.htmlContent,
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        {isMobile && currTemplate?.status === 'PENDING' ? (
          <Stack width="100%" direction="row" alignItems="center" spacing={2}>
            {!replaceVariables ? null : (
              <ReplaceVariablesButton
                handleSelectRow={handleSelectRow}
                setTemplate={setReplacedHtml}
                htmlTemplate={htmlContent || currTemplate?.htmlContent || ''}
                buttonProps={{ disabled: !Boolean(selectedVars.length) }}
              />
            )}

            <RenderTemplatePreviewItem
              template={currTemplate}
              approveTemplate={approveTemplate}
              rejectTemplate={rejectTemplate}
              onOpenTemplate={onOpenTemplate}
            />
          </Stack>
        ) : null}
      </DialogActions>
      <RejectTemplateDialog />
      <AuthorizeTemplateDialog />
      <PublishTemplateDialog />
    </Dialog>
  );
};
