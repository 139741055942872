//@ts-check
import { DialogContent, Stack } from '@mui/material';
import { Dialog } from '../../../../../../../components/Dialogs/Dialog';
import { menuActionsStyles } from '../../../../../../RHPod/Absence/EmployeeAbsence.styles';
import { Typography } from '../../../../../../../newComponents/Typography';
import { IconButton } from '../../../../../../../newComponents/IconButton';
import { ListAuthorizersStatus } from './ListAuthorizersStatus';

export const ResumeAuthorizersMobile = ({
  open,
  onClose,
  authorizations = [],
  isOrdered,
  template,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      onCloseXButton={false}
      showCloseButton={false}
      sx={{
        ...menuActionsStyles,
      }}
      zoomTransition={false}
    >
      <DialogContent sx={{ p: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={3}
        >
          <Stack spacing={1}>
            <Typography variant="h6">
              {isOrdered
                ? 'Autorizado por orden de lista'
                : 'Autorizado de forma aleatoria'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {template?.title}
            </Typography>
          </Stack>
          <IconButton icon="close_line" onClick={onClose} />
        </Stack>
        <Stack width="100%" sx={{ maxHeight: 250, overflow: 'auto' }}>
          <ListAuthorizersStatus
            authorizations={authorizations}
            isOrdered={isOrdered}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
