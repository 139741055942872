//@ts-check

import { makeVar } from '@apollo/client';
import { dateRanges } from './Dashboard.constants';
import { FIND_BY_WORKCENTERS } from '../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';

/** @type {import('./Dashboard.types').DashboardFilterTypes} */
const dashboardFilter = {
  companies: [],
  period: dateRanges['historic'],
  initialChartPeriod: dateRanges['lastSixMonths'],
  employees: [],
  workCenters: [],
  workTitles: [],
  groups: [],
};

/** @type {import('./Dashboard.types').DashboardFilterTypes} */
export const dashboardFilterDialog = {
  companies: [],
  period: null,
  initialChartPeriod: null,
  employees: [],
  workCenters: [],
  workTitles: [],
  groups: [],
  categories: [],
};

/** @type {import('./Dashboard.types').DashboardDialogsStateTypes} */
const dashboardDialogsState = {
  signedReceiptsDialog: false,
  signedDocumentsDialog: false,
  onboardingDialog: false,
  receiptsSentDialog: false,
  checkListProgressDialog: false,
  employeeStatusProgressDialog: false,
};

/** @type {import('./Dashboard.types').DashboardResultTypes} */
const dashboardResults = {
  dashboard: null,
  dialogDashboard: null,
  loading: true,
  signedDocumentsDialogResult: null,
  signedReceiptsDialogResult: null,
  employeeStatusDialogResult: null,
};

/** @type {import('./Dashboard.types').CheckListState} */
const checkListState = {
  selectedItem: null,
  selectedDocTag: null,
  checkListDocTypeDialog: false,
};

const globalExportHeader = {
  status: '',
  reportType: '',
};

const findBySelected = {
  findBy: FIND_BY_WORKCENTERS,
  findByDialog: FIND_BY_WORKCENTERS,
  viewToUse: 'DASHBOARD_HEADER',
};

/** @type {import('./Dashboard.types').tags} */
const categories = [];

export const dashboardFilterVar = makeVar(dashboardFilter);
export const dashboardDialogsStateVar = makeVar(dashboardDialogsState);
export const dashboardFilterDialogVar = makeVar(dashboardFilterDialog);
export const dashboardResultsVar = makeVar(dashboardResults);
export const globalExportHeaderVar = makeVar(globalExportHeader);
export const categoriesVar = makeVar(categories);
export const findBySelectedVar = makeVar(findBySelected);
export const checkListStateVar = makeVar(checkListState);
export const filterHasChangedVar = makeVar(false);
export const selectedGlobalCheckListVar = makeVar(null);

export const resetFiltersDashboard = () => {
  dashboardFilterVar(dashboardFilter);
  dashboardDialogsStateVar(dashboardDialogsState);
  dashboardFilterDialogVar(dashboardFilterDialog);
  dashboardResultsVar(dashboardResults);
  globalExportHeaderVar(globalExportHeader);
  findBySelectedVar(findBySelected);
  checkListStateVar(checkListState);
  selectedGlobalCheckListVar(null);
};

export const dashboardReactiveVars = {
  dashboardFilterVar: {
    read: () => dashboardFilterVar(),
  },
  dashboardDialogsStateVar: {
    read: () => dashboardDialogsStateVar(),
  },
  dashboardFilterDialogVar: {
    read: () => dashboardFilterDialogVar(),
  },
  dashboardResultsVar: {
    read: () => dashboardResultsVar(),
  },
  globalExportHeaderVar: {
    read: () => globalExportHeaderVar(),
  },
  categoriesVar: {
    read: () => categoriesVar(),
  },
  findBySelectedVar: {
    read: () => findBySelectedVar(),
  },
  checkListStateVar: {
    read: () => checkListStateVar(),
  },
  filterHasChangedVar: {
    read: () => filterHasChangedVar(),
  },
  getEmployeesByCompanyIds: {
    keyArgs: ['filter'],
    /** @type {import('../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { page = 0, perPage = 10 } }) {
      const existingElements = existing?.employees || [];
      const incomingElements = incoming?.employees || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      result.employees = merged;
      return result;
    },
  },
  getWorkCentersByCompanyIds: {
    keyArgs: ['filter'],
    /** @type {import('../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { page = 0, perPage = 10 } }) {
      const existingElements = existing?.workCenters || [];
      const incomingElements = incoming?.workCenters || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      result.workCenters = merged;
      return result;
    },
  },
  getWorkTitlesByCompanyIds: {
    keyArgs: ['filter'],
    /** @type {import('../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { page = 0, perPage = 10 } }) {
      const existingElements = existing?.workTitles || [];
      const incomingElements = incoming?.workTitles || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      result.workTitles = merged;
      return result;
    },
  },
  getGroupsByCompanyIds: {
    keyArgs: ['filter'],
    /** @type {import('../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { page = 0, perPage = 10 } }) {
      const existingElements = existing?.groups || [];
      const incomingElements = incoming?.groups || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      result.groups = merged;
      return result;
    },
  },
  getReceiptsByCompanyIds: {
    keyArgs: ['filter'],
    /** @type {import('../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { page = 0, perPage = 10 } }) {
      const existingElements = existing?.receipts || [];
      const incomingElements = incoming?.receipts || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      result.receipts = merged;
      return result;
    },
  },

  getDocumentsByCompanyIds: {
    keyArgs: ['filter'],
    /** @type {import('../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { page = 0, perPage = 10 } }) {
      const existingElements = existing?.documents || [];
      const incomingElements = incoming?.documents || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[page * perPage + index] = element;
      });
      const result = { ...incoming };
      result.documents = merged;
      return result;
    },
  },

  getPendingDocumentsByRecordChecklist: {
    keyArgs: ['filter'],
    /** @type {import('../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { pagination } }) {
      const { page, perPage } = pagination;
      const newPage = page - 1;
      const existingElements = existing?.documents || [];
      const incomingElements = incoming?.documents || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[newPage * perPage + index] = element;
      });
      const result = { ...incoming };
      result.documents = merged;
      return result;
    },
  },

  getEmployeesWithoutRecordChecklistDocument: {
    keyArgs: ['filter'],
    /** @type {import('../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { pagination } }) {
      const { page, perPage } = pagination;
      const newPage = page - 1;
      const existingElements = existing?.employees || [];
      const incomingElements = incoming?.employees || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[newPage * perPage + index] = element;
      });
      const result = { ...incoming };
      result.employees = merged;
      return result;
    },
  },

  getCompletedDocumentsByRecordChecklist: {
    keyArgs: ['filter'],
    /** @type {import('../../../cache').MergeFunction} */
    merge(existing = {}, incoming = {}, { args: { pagination } }) {
      const { page, perPage } = pagination;
      const newPage = page - 1;
      const existingElements = existing?.documents || [];
      const incomingElements = incoming?.documents || [];

      const merged = existingElements.length ? existingElements.slice(0) : [];
      incomingElements.forEach((element, index) => {
        merged[newPage * perPage + index] = element;
      });

      const result = { ...incoming };
      result.documents = merged;
      return result;
    },
  },
};
