import { Box, Stack } from '@mui/material';
import { CaptionDefault } from '../../../../components/Typographies/Typography';
import { Typography } from '../../../../newComponents/Typography';
import { Icon } from '../../../../components/Icons/Icons';

export const ActiveEmployeeTooltip = (
  <div>
    <p>Los empleados activos ya activaron su cuenta en Sora:</p>
    <Box pl={2} textAlign="left">
      <ul>
        <li>Cambiaron su contraseña temporal</li>
        <li>Verificaron su identidad</li>
        <li>Crearon su firma electrónica</li>
        <li>Firmaron su carta de aceptación</li>
      </ul>
    </Box>
  </div>
);

export const InactiveEmployeeTooltip = (
  <Box display="flex" flexDirection="column" alignItems="center">
    <CaptionDefault>
      Empleados que faltan por activar su cuenta en Sora.
    </CaptionDefault>
    <CaptionDefault>
      Ponte en contacto con ellos para revisar su estatus en Sora
    </CaptionDefault>
  </Box>
);

export const InfoCreateDocumentTooltip = (
  <div>
    <Box pl={2} textAlign="left">
      <p>Para elegir tus destinatarios puedes seleccionar: </p>
      <ol type="1">
        <li>A todos los empleados activos</li>
        <li>Uno o varios centros de trabajo</li>
        <li>
          Un listado de empleados especificos (pega la lista de RFC). Ej:
          RFC1;RFC2
        </li>
        <li>Buscar individualmente por nombre o RFC</li>
      </ol>
    </Box>
  </div>
);

export const DinamicTooltipToPermissions = (data, type) => {
  return (
    <Box sx={{ p: 2 }} textAlign="left">
      {data?.disabled && !data?.options?.length ? (
        <Typography variant="body2">No disponible por el momento</Typography>
      ) : (
        <>
          <Typography variant="subtitle2">
            {type === 'edit'
              ? 'El usuario puede:'
              : 'El usuario únicamente puede:'}
          </Typography>

          <Stack>
            {data?.options?.map((permission, i) => (
              <Stack key={i} direction="row" alignItems="center" spacing={1}>
                <Icon
                  icon="checkbox_blank_circle_fill"
                  fill="grey"
                  width="5px"
                />
                <Typography variant="caption" color="grey">
                  {permission}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </>
      )}
    </Box>
  );
};
