// @ts-check
import { InMemoryCache } from '@apollo/client';
import { reactiveVars } from './cache.reactiveVars.js';
import { cloneDeep } from 'lodash';

/**
 * @callback MergeFunction
 * @param {Array | object} existing
 * @param {Array | object} incoming
 * @param {*} [options=undefined]
 * @returns {Array}
 */

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        allUsers: {
          keyArgs: ['filter', 'sortField', 'sortOrder', 'perPage'],
          /** @type {MergeFunction} */
          merge(existing = [], incoming = [], { args }) {
            const merged = existing ? existing.slice(0) : [];
            incoming.forEach((element, index) => {
              merged[args.page * args.perPage + index] = element;
            });
            return merged;
          },
        },
        allReceipts: {
          keyArgs: ['filter', 'sortField', 'sortOrder'],
          /** @type {MergeFunction} */
          merge(
            existing = [],
            incoming = [],
            { args: { page = 0, perPage = 10 } },
          ) {
            const merged = existing ? existing.slice(0) : [];
            incoming.forEach((element, index) => {
              merged[page * perPage + index] = element;
            });
            return merged;
          },
        },
        allDocuments: {
          keyArgs: ['filter', 'sortField', 'sortOrder'],
          /** @type {MergeFunction} */
          merge(
            existing = {},
            incoming = {},
            { args: { page = 0, perPage = 10 } },
          ) {
            const existingElements = existing?.documents || [];
            const incomingElements = incoming?.documents || [];

            const merged = existingElements.length
              ? existingElements.slice(0)
              : [];
            incomingElements.forEach((element, index) => {
              merged[page * perPage + index] = element;
            });
            const result = { ...incoming };
            result.documents = merged;
            result.metadata = incoming?.metadata ?? {};

            return result;
          },
        },
        myDocsAndReceipts: {
          keyArgs: ['filter', 'sortField', 'sortOrder'],
          /** @type {MergeFunction} */
          merge(
            existing = [],
            incoming = [],
            { args: { page = 0, perPage = 10 } },
          ) {
            const existingElements = existing?.docsAndReceipts || [];
            const incomingElements = incoming?.docsAndReceipts || [];

            const merged = existingElements.length
              ? existingElements.slice(0)
              : [];
            incomingElements.forEach((element, index) => {
              merged[page * perPage + index] = element;
            });
            const result = { ...incoming };
            result.docsAndReceipts = merged;
            return result;
          },
        },
        myReceipts: {
          keyArgs: ['filter', 'sortField', 'sortOrder'],
          /** @type {MergeFunction} */
          merge(
            existing = [],
            incoming = [],
            { args: { page = 0, perPage = 10 } },
          ) {
            const existingElements = existing?.receipts || [];
            const incomingElements = incoming?.receipts || [];

            const merged = existingElements.length
              ? existingElements.slice(0)
              : [];
            incomingElements.forEach((element, index) => {
              merged[page * perPage + index] = element;
            });
            const result = { ...incoming };
            result.receipts = merged;
            return result;
          },
        },
        myDocuments: {
          keyArgs: ['filter', 'sortField', 'sortOrder'],
          /** @type {MergeFunction} */
          merge(
            existing = [],
            incoming = [],
            { args: { page = 0, perPage = 10 } },
          ) {
            const existingElements = existing?.documents || [];
            const incomingElements = incoming?.documents || [];

            const merged = existingElements.length
              ? existingElements.slice(0)
              : [];
            incomingElements.forEach((element, index) => {
              merged[page * perPage + index] = element;
            });
            const result = { ...incoming };
            result.documents = merged;
            return result;
          },
        },
        allCredits: {
          keyArgs: ['filter'],
          /** @type {MergeFunction} */
          merge(
            existing = {},
            incoming = {},
            { args: { page = 0, perPage = 10 } },
          ) {
            const existingElements = existing?.credits || [];
            const incomingElements = incoming?.credits || [];

            const merged = existingElements.length
              ? existingElements.slice(0)
              : [];
            incomingElements.forEach((element, index) => {
              merged[page * perPage + index] = element;
            });
            const result = { ...incoming };
            result.credits = merged;
            return result;
          },
        },
        allTemplates: {
          keyArgs: ['filter'],
          /** @type {MergeFunction} */
          merge(
            existing = {},
            incoming = {},
            { args: { page = 0, perPage = 10 } },
          ) {
            const existingElements = existing?.templates || [];
            const incomingElements = incoming?.templates || [];

            const merged = existingElements.length
              ? existingElements.slice(0)
              : [];
            incomingElements.forEach((element, index) => {
              merged[page * perPage + index] = element;
            });
            const result = { ...incoming };
            result.templates = merged;
            return result;
          },
        },
        getClientDashboardCommercial: {
          keyArgs: ['filter'],
          /** @type {MergeFunction} */
          merge(
            existing = [],
            incoming = [],
            { args: { page = 0, perPage = 10 } },
          ) {
            const existingElements = existing?.companies || [];
            const incomingElements = incoming?.companies || [];

            const merged = existingElements.length
              ? existingElements.slice(0)
              : [];
            incomingElements.forEach((element, index) => {
              merged[page * perPage + index] = element;
            });
            const result = { ...incoming };
            return result;
          },
        },
        allEmployees: {
          keyArgs: ['filter'],
          /** @type {MergeFunction} */
          merge(
            existing = {},
            incoming = {},
            { args: { page = 0, perPage = 10 } },
          ) {
            const existingElements = existing?.employees || [];
            const incomingElements = incoming?.employees || [];

            const merged = existingElements.length
              ? existingElements.slice(0)
              : [];
            incomingElements.forEach((element, index) => {
              merged[page * perPage + index] = element;
            });
            const result = { ...incoming };
            result.employees = merged;
            return result;
          },
        },
        GetReceiptsByStatus: {
          keyArgs: [
            'input',
            ['companyIds', 'filter', 'advancedFilter', 'sort'],
          ],
          /** @type {MergeFunction} */
          merge(existing = {}, incoming = {}, { args }) {
            const { page = 0, perPage = 10 } = args.input.pagination;
            const existingElements = existing?.receipts || [];
            const incomingElements = incoming?.receipts || [];

            const merged = existingElements.length
              ? existingElements.slice(0)
              : [];
            incomingElements.forEach((element, index) => {
              merged[page * perPage + index] = element;
            });
            const result = { ...incoming };
            result.receipts = merged;
            return result;
          },
        },
        getAllWorkCenters: {
          keyArgs: ['filter'],
          /** @type {MergeFunction} */
          merge(
            existing = {},
            incoming = {},
            { args: { page = 0, perPage = 10 } },
          ) {
            const existingElements = existing?.workCenters || [];
            const incomingElements = incoming?.workCenters || [];

            const merged = existingElements.length
              ? existingElements.slice(0)
              : [];
            incomingElements.forEach((element, index) => {
              merged[page * perPage + index] = element;
            });
            const result = { ...incoming };
            result.workCenters = merged;
            return result;
          },
        },
        getEmployeesCountersByStatus: {
          keyArgs: ['input', ['workCenterId', 'advancedFilters', 'search']],
          merge(existing = [], incoming) {
            return { ...existing, ...incoming }; // Simplistic merge strategy
          },
        },
        getCompanyEmployees: {
          keyArgs: [
            'input',
            [
              'workCenterId',
              'advancedFilters',
              'status',
              'legalStatus',
              'search',
            ],
          ],
          /** @type {MergeFunction} */
          merge(existing = {}, incoming = {}, { args }) {
            const {
              input: {
                sortingAndPaging: { page, perPage },
              },
            } = args;

            const existingElements = existing?.companyEmployees || [];
            const incomingElements = incoming?.companyEmployees || [];

            const merged = existingElements.length
              ? existingElements.slice(0)
              : [];

            incomingElements.forEach((element, index) => {
              merged[page * perPage + index] = element;
            });

            const result = { ...incoming };
            result.companyEmployees = merged;
            return result;
          },
        },
        getCompanyDocuments: {
          keyArgs: ['input', ['documentsTableFilters']],
          /** @type {MergeFunction} */
          merge(existing = {}, incoming = {}, param) {
            const {
              input: {
                documentsTableSortingAndPaging: { page, pageSize },
              },
            } = param.args;

            const existingElements =
              existing?.companyDocuments?.documents || [];
            const incomingElements =
              incoming?.companyDocuments?.documents || [];

            if (incomingElements.length) {
              const merged = existingElements.length
                ? existingElements.slice(0)
                : [];
              incomingElements.forEach((element, index) => {
                merged[page * pageSize + index] = element;
              });

              const result = cloneDeep(incoming);
              result.companyDocuments.documents = merged;
              return result;
            } else {
              return existing;
            }
          },
        },
        ...reactiveVars,
      },
    },
    Credit: {
      fields: {
        creditPayments: {
          merge(existing = [], incoming = []) {
            return [...incoming];
          },
        },
      },
    },
    RecordChecklist: {
      fields: {
        documentTags: {
          merge(existing = [], incoming = []) {
            return [...incoming];
          },
        },
      },
    },
  },
});
