import { memo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { ListItem } from '../../../../../../newComponents/ListItem';
import { UserItem } from './UserItem';
import { useTheme } from '@mui/material';
import type { UserItemWrapperProps } from '../../CreateDocumentDialog.types';
import { getUserIdByType } from '../../CreateDocumentDialog.helpers';

export const UserItemWrapper = memo((props: UserItemWrapperProps) => {
  const theme = useTheme();
  const {
    style,
    index,
    data,
    selected = [],
    setSelected,
    users = [],
    setUsers,
    forcedIndex,
    orderSignature,
    disabled,
    defaultEmployee,
  } = props;
  const row = data[index];

  const isDisabled = Boolean(disabled);

  const id = row?._id || row?.id || row?.name;
  const disabledDefaultEmployee = defaultEmployee?.userId === id;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: row?._id || row?.id || row?.name,
    data: row,
    disabled: isDisabled || Boolean(disabledDefaultEmployee),
  });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: isDisabled || disabledDefaultEmployee ? 'default' : 'grab',
  };

  const onCheckboxChange = (isChecked) => {
    if (isChecked) {
      setSelected([...selected, id]);
      return;
    }

    const filteredItems = selected.filter((selectedId) => selectedId !== id);

    setSelected(filteredItems);
  };

  const removeItem = (item) => {
    if (!item || disabledDefaultEmployee) return;
    const currTab =
      row?.__typename === 'EmployeesValidationError' && row?.success === false;
    const tab = currTab ? 'errors' : 'success';

    const filteredSigners = users.filter(
      (user) => getUserIdByType(user) !== id,
    );

    if (selected?.length) {
      const filteredSelected = selected.filter(
        (selectedId) => selectedId !== id,
      );

      setSelected(filteredSelected);
      setUsers(filteredSigners, tab);

      return;
    }

    setUsers(filteredSigners, tab);
  };

  const isSelected = selected?.some((selectedId) => selectedId === id);

  return (
    <ListItem
      key={row?._id || row?.id || row?.name}
      ref={setNodeRef}
      style={{ ...itemStyle, ...style }}
      {...attributes}
      {...listeners}
      sx={{
        cursor: 'grab',
        visibility: isDragging ? 'hidden' : 'visible',
        '&:hover .MuiButton-root': { visibility: 'visible' },
        '&:hover .MuiIconButton-root': { visibility: 'visible' },
        '&.MuiListItem-root': {
          height: 76,
          gap: 2,
          pl: 2,
          pr: 0,
          '.delete_button': {
            visibility: 'hidden',
          },
        },
        '&.MuiListItem-root:hover': {
          background: theme.newPalette.actionStates.hover,
          '.delete_button': {
            visibility: 'visible',
          },
        },
      }}
    >
      <UserItem
        index={index}
        user={row}
        isSelected={isSelected}
        onSelectUser={onCheckboxChange}
        onRemoveUser={removeItem}
        forcedIndex={forcedIndex}
        orderSignature={orderSignature}
        disabledActions={disabledDefaultEmployee}
      />
    </ListItem>
  );
});
