//@ts-check
import { useMutation, useReactiveVar } from '@apollo/client';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../../../../cache.reactiveVars';
import { PUBLISH_TEMPLATE } from '../Templates.gql';
import { updateTemplatesVar } from '../Templates.vars';
import { TemplateToPublish } from '../../../../../RHPod/Documents/Templates/Template.types';

export type PublishTemplateInput = {
  data: TemplateToPublish;
  onSuccess: () => void;
};

export const usePublishTemplate = () => {
  const user = useReactiveVar(userVar);
  const [publishTemplate] = useMutation(PUBLISH_TEMPLATE);

  const handlePublishTemplate = async (input: PublishTemplateInput) => {
    if (!user?._id) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message:
          'No se ha podido obtener la información del usuario al publicar plantilla',
      });
      return;
    }

    try {
      const { data: inputData, onSuccess } = input;

      if (!inputData?.templateId) {
        globalSnackbarVar({
          show: true,
          message: 'Faltan datos para publicar la plantilla',
          severity: 'error',
          duration: 5000,
        });
        return;
      }

      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Publicando plantilla...',
      });

      const response = await publishTemplate({
        variables: {
          input: {
            userId: user?._id,
            templateId: inputData.templateId,
            comments: inputData.comments,
          },
        },
      });

      const data = response?.data?.publishTemplate;

      if (data?.__typename === 'ErrorToPublishTemplate') {
        globalSnackbarVar({
          show: true,
          message: data?.message,
          severity: 'error',
          duration: 5000,
        });

        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Plantilla publicada con éxito',
        severity: 'success',
        duration: 5000,
      });
      updateTemplatesVar(!updateTemplatesVar());
      if (onSuccess) onSuccess();
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Error al publicar la plantilla. Contacta a SORA',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handlePublishTemplate };
};
