import { useTheme } from '@mui/material';
import { Avatar } from '../../../../../../newComponents/Avatar';
import { Icon } from '../../../../../../components/Icons/Icons';
import {
  COMPANY_SIGNER_ROW,
  GROUP_ROW,
  WORK_CENTER_ROW,
  WORK_TITLE_ROW,
} from '../../../../../../businessComponents/EmployeesFinder/EmployeesFinder.constants';
import { WorkTitleAvatar } from '../../../../../../businessComponents/WorkTitleAvatar';
import { WorkCenterAvatar } from '../../../../../../businessComponents/WorkCenterAvatar';
import { GroupAvatar } from '../../../../../../businessComponents/GroupAvatar';
import { CompanySignerAvatar } from '../../../../../../businessComponents/CompanySignerAvatar';
import { EmployeeAvatar } from '../../../../../../businessComponents/EmployeeAvatar';
import type { RenderAvatarItemProps } from '../../CreateDocumentDialog.types';

export const RenderAvatarItem = (props: RenderAvatarItemProps) => {
  const { row } = props;
  const theme = useTheme();
  if (row?.success === false) {
    return (
      <Avatar
        color="error"
        sx={{
          '&.MuiAvatar-colorError': {
            background: theme.newPalette.error.transparent24,
            color: theme.newPalette.error.main,
          },
        }}
      >
        <Icon icon="error_warning_line" height="20px" color="error" />
      </Avatar>
    );
  }

  if (row.type === WORK_TITLE_ROW) {
    return <WorkTitleAvatar color={row?.color} size="small" />;
  }

  if (row.type === WORK_CENTER_ROW) {
    return <WorkCenterAvatar color={row?.color} size="small" />;
  }

  if (row.type === GROUP_ROW) {
    return <GroupAvatar color={row?.color} size="small" />;
  }

  if (row.type === COMPANY_SIGNER_ROW) {
    return (
      <CompanySignerAvatar
        name={row.fullName}
        profilePicture={row.profilePicture}
        size="small"
        showBadge
      />
    );
  }

  return (
    <EmployeeAvatar
      name={row.fullName}
      profilePicture={row.profilePicture}
      size="small"
    />
  );
};
