//@ts-check
import { Stack } from '@mui/material';
import { SearchAuthorizers } from './SearchAuthorizers';
import { OrderAuthorizersComponent } from './OrderAuthorizersComponent';
import { TableAuthorizers } from './TableAuthorizers';
import { useReactiveVar } from '@apollo/client';
import { templateItemVar } from '../Templates.vars';
import { useEffect } from 'react';

export const TemplateCreationAuthorizersForm = ({ form }) => {
  const templateItem = useReactiveVar(templateItemVar);

  useEffect(() => {
    form.setValue('authorizers', templateItem?.authorizers ?? []);
    form.setValue('authorizeInOrder', Boolean(templateItem.isOrdered));
  }, [templateItem.authorizers, templateItem.isOrdered, form]);

  return (
    <Stack spacing={2} width="100%">
      <SearchAuthorizers />
      <OrderAuthorizersComponent />
      <TableAuthorizers />
    </Stack>
  );
};
