import { Box, useTheme } from '@mui/material';
import { hexToRgbHelper } from '../../utils/utils';
import { useRef, useState } from 'react';
import { Tooltip } from '../../newComponents/Tooltip';
import { TypographyWithRef } from '../../newComponents/Typography/Typography';
import {
  categoryBoxContainer,
  categoryContainer,
  documentTypeContainer,
  subcategoryBoxContainer,
  subcategoryContainter,
} from './EmployeeRecordTag.styles';

export function EmployeeRecordTag({
  employeeRecordTag,
  columnWidth = 200,
}: {
  employeeRecordTag?: { category: string; color: string; subcategory: string };
  columnWidth?: number;
}) {
  const categoryRef = useRef(null);
  const subcategoryRef = useRef(null);
  const [showCategoryTooltip, setShowCategoryTooltip] = useState(false);
  const [showSubcatTooltip, setShowSubcatTooltip] = useState(false);

  function isOverflown(element: HTMLElement | null): boolean {
    if (!element) return false;
    return element.scrollWidth > element.clientWidth;
  }

  const handleMouseEnter = () => {
    const isCategoryOverflow = isOverflown(categoryRef.current);
    const isSubcategoryRef = isOverflown(subcategoryRef.current);
    setShowCategoryTooltip(isCategoryOverflow);
    setShowSubcatTooltip(isSubcategoryRef);
  };

  const categoryName = employeeRecordTag?.category || 'Otros';
  const subcategoryName = employeeRecordTag?.subcategory || 'Sin etiqueta';

  const theme = useTheme();
  const color =
    employeeRecordTag?.color ||
    (categoryName === 'Sora'
      ? theme.newPalette.success.main
      : theme.newPalette.grey.grey400);

  const colorWithOpacity = hexToRgbHelper(color, 0.6);
  const categoryWidth = columnWidth * 0.4 - 5;
  const subcategoryWidth = columnWidth * 0.6 - 5;
  return (
    <Box sx={documentTypeContainer}>
      <Tooltip
        title={showCategoryTooltip ? categoryName : ''}
        placement="bottom"
        disableInteractive={false}
      >
        <Box
          sx={{
            ...categoryBoxContainer,
            backgroundColor: colorWithOpacity,
            width: 'max-content',
            maxWidth: categoryWidth,
          }}
        >
          <TypographyWithRef
            sx={categoryContainer}
            variant="caption"
            bold
            ref={categoryRef}
            onMouseEnter={handleMouseEnter}
            ellipsis
          >
            {categoryName}
          </TypographyWithRef>
        </Box>
      </Tooltip>
      <Tooltip
        title={showSubcatTooltip ? subcategoryName : ''}
        placement="bottom"
        disableInteractive={false}
      >
        <Box
          border={`2px solid ${colorWithOpacity}`}
          sx={{
            ...subcategoryBoxContainer,
            width: 'max-content',
            maxWidth: subcategoryWidth,
          }}
        >
          <TypographyWithRef
            sx={subcategoryContainter}
            variant="caption"
            bold
            ref={subcategoryRef}
            onMouseEnter={handleMouseEnter}
            ellipsis
          >
            {subcategoryName}
          </TypographyWithRef>
        </Box>
      </Tooltip>
    </Box>
  );
}
