import { useEffect, useState } from 'react';
import { HeaderUserList } from './UsersList/HeaderUserList';
import { SelectedUsers } from './UsersList/SelectedUsers';
import { TableUsers } from './UsersList/TableUsers';
import { Card, Stack } from '@mui/material';
import { FormControlLabel } from '../../../../../newComponents/FormControlLabel';
import { Tooltip } from '../../../../../newComponents/Tooltip';
import { Switch } from '../../../../../newComponents/Switch';
import { setGlobalTotalsVar } from '../CreateDocumentDialog.vars';
import type { CompanySignersListProps } from '../CreateDocumentDialog.types';
import {
  getForcedIndexCompanySigners,
  getTitleAndSubHeaderCompanySigners,
} from './CreateDocumentUsersForm.helpers';

export const CompanySignersList = (props: CompanySignersListProps) => {
  const {
    users = [],
    orderSignature,
    setUsers,
    employeeSignFirst,
    companySignersSignInOrder,
    handleCompanySignersSignInOrder,
  } = props;
  const [loadedRowCount, setLoadedRowCount] = useState(20);
  const [selectedSigners, setSelectedSigners] = useState([]);

  const handleLoadMore = () => {
    setLoadedRowCount((prevRowCount) => prevRowCount + 20);
  };

  const { title, subHeader } = getTitleAndSubHeaderCompanySigners({
    employeeSignFirst,
    orderSignature,
  });

  const indexOrder = employeeSignFirst && orderSignature ? 2 : 1;

  const forcedIndex = getForcedIndexCompanySigners({
    employeeSignFirst,
    orderSignature,
    companySignersSignInOrder,
  });

  useEffect(() => {
    setGlobalTotalsVar({ companySigners: users.length });
  }, [users.length]);

  return (
    <Stack component={Card}>
      <HeaderUserList
        avatarIcon="shuffle_line"
        title={title}
        subHeader={subHeader}
        total={users.length}
        color="success"
        orderSignature={orderSignature}
        indexOrder={indexOrder}
      />
      <Stack
        width="100%"
        direction="row"
        justifyContent="flex-end"
        mt={1}
        mb={1}
      >
        <Tooltip
          title={
            'Eliges el orden en que los usuarios deben firmar el documento'
          }
          placement={'top'}
        >
          <div>
            <FormControlLabel
              onChange={handleCompanySignersSignInOrder}
              control={
                <Switch color="primary" checked={companySignersSignInOrder} />
              }
              label="Firmar por orden de lista"
              labelPlacement="end"
            />
          </div>
        </Tooltip>
      </Stack>
      <SelectedUsers
        users={users}
        setUsers={setUsers}
        selected={selectedSigners}
        setSelected={setSelectedSigners}
      />
      <TableUsers
        users={users}
        setUsers={setUsers}
        loadedRowCount={loadedRowCount}
        handleLoadMore={handleLoadMore}
        selected={selectedSigners}
        setSelected={setSelectedSigners}
        color="success"
        forcedIndex={forcedIndex}
        orderSignature={orderSignature}
      />
    </Stack>
  );
};
