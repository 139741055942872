import { Fragment, memo } from 'react';
import { ListItemIcon } from '../../../../../../newComponents/ListItemIcon';
import { Checkbox } from '../../../../../../newComponents/Checkbox';
import { IconButton } from '../../../../../../newComponents/IconButton';
import { Stack } from '@mui/material';

import { Label } from '../../../../../../newComponents/Label';
import { RenderAvatarItem } from './RenderAvatarItem';
import { RenderTextItem } from './RenderTextItem';
import type { UserItemProps } from '../../CreateDocumentDialog.types';

const RenderIndex = ({ forcedIndex, index, orderSignature }) => {
  if (!orderSignature) return null;
  const newIndex =
    forcedIndex === 0 ? 1 + (index + 1) : (forcedIndex ?? index + 1);
  return <Label label={newIndex} color="default" variant="outlined" />;
};

export const UserItem = memo((props: UserItemProps) => {
  const {
    user,
    isSelected,
    onRemoveUser,
    onSelectUser,
    index,
    forcedIndex,
    orderSignature,
    disabledActions,
  } = props;
  return (
    <Fragment>
      <Checkbox
        color="primary"
        checked={isSelected}
        onChange={(e) => {
          onSelectUser(e.target.checked);
        }}
        disabled={Boolean(disabledActions)}
      />
      <Stack direction="row" alignItems="center" gap={2} width="100%">
        <RenderIndex
          index={index}
          forcedIndex={forcedIndex}
          orderSignature={orderSignature}
        />
        <RenderAvatarItem row={user} />
        <Stack flexWrap="wrap" width="100%" whiteSpace="normal">
          <RenderTextItem user={user} />
        </Stack>
      </Stack>
      {disabledActions ? null : (
        <ListItemIcon>
          <IconButton
            icon="delete_bin_line"
            color="error"
            size="small"
            sx={{
              visibility: 'hidden',
            }}
            onClick={() => onRemoveUser(user)}
          />
        </ListItemIcon>
      )}
    </Fragment>
  );
});
