//@ts-check
import { useEffect, useState } from 'react';
import {
  Card,
  Collapse,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { useCustomTheme } from '../../../../../../Hooks/useCustomTheme';
import { useReactiveVar } from '@apollo/client';
import { Avatar } from '../../../../../../newComponents/Avatar';
import { Icon } from '../../../../../../components/Icons/Icons';
import { Typography } from '../../../../../../newComponents/Typography';
import { Label } from '../../../../../../newComponents/Label';
import { IconButton } from '../../../../../../newComponents/IconButton';
import { setTemplateItemVar, templateItemVar } from '../Templates.vars';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  closestCorners,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { TableAuthorizersRow } from './TableAuthorizersRow';
import { ROW_HEIGHT } from '../Templates.constants';
import { TableHeaderAuthorizers } from './TableHeaderAuthorizers';
import { AuthorizerOverlay } from './AuthorizerOverlay';

export const TableAuthorizers = () => {
  const theme = useCustomTheme();
  const templateItem = useReactiveVar(templateItemVar);

  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activeAuth, setActiveAuth] = useState(null);
  const { setNodeRef } = useDroppable({ id: 'AUTHORIZERS_LIST' });

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } }),
  );

  const handleDragStart = (event) => {
    const { active } = event;
    const newTemplate = active.data?.current;
    if (newTemplate) {
      setActiveAuth({ listName: 'AUTHORIZERS_TABLE', ...newTemplate });
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const authorizers = [...rows];
      const oldIndex = authorizers?.findIndex(
        (item) => item._id.toString() === active.id,
      );
      const newIndex = authorizers?.findIndex(
        (item) => item._id.toString() === over.id,
      );
      if (oldIndex < 0 || newIndex < 0 || oldIndex === newIndex) return;

      const authorizer = authorizers[newIndex];
      if (authorizer && authorizer?.status === 'AUTHORIZED') return;

      const modifiedAuths = arrayMove(authorizers, oldIndex, newIndex);
      setActiveAuth(null);
      setRows(modifiedAuths);
      setTemplateItemVar({ authorizers: modifiedAuths });
    }
  };

  const removeAllItems = () => {
    const currAuthorizers = templateItem?.authorizers ?? [];
    const currSelectedAuths = templateItem?.selectedAuthorizers ?? [];

    const filteredItems = currAuthorizers.filter(
      (auth) =>
        !currSelectedAuths.some((selected) => selected?._id === auth?._id),
    );

    const selectedItems = currSelectedAuths.filter(
      (auth) =>
        !currAuthorizers.some((selected) => selected?._id === auth?._id),
    );

    setTemplateItemVar({
      authorizers: filteredItems,
      selectedAuthorizers: selectedItems,
    });
  };

  useEffect(() => {
    setRows(templateItem?.authorizers);
    setSelectedRows(templateItem?.selectedAuthorizers);
  }, [templateItem.authorizers, templateItem.selectedAuthorizers]);

  const authorizersLength = templateItem?.authorizers?.length;

  const mappedRows = rows?.map((row) => {
    return {
      ...row,
      id: row._id,
    };
  });

  return (
    <Stack>
      <Card
        sx={{
          padding: 3,
          borderRadius: authorizersLength > 0 ? '16px 16px 0px 0px' : '16px',
          boxShadow: theme.newPalette.shadow.card,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar>
              <Icon
                icon={templateItem?.isOrdered ? 'list_ordered' : 'shuffle_line'}
                width="24px"
                fill="grey"
              />
            </Avatar>
            <Stack>
              <Typography variant="subtitle2">Autorizadores</Typography>
              <Typography variant="body2" color="text.secondary">
                {templateItem?.isOrdered
                  ? 'Todos autorizan según el listado'
                  : 'Todos autorizan de forma aleatoria'}
              </Typography>
            </Stack>
          </Stack>
          <Label
            label={`${authorizersLength} autorizadores`}
            color="primary"
            variant="soft"
          />
        </Stack>
      </Card>
      <Collapse in={Boolean(selectedRows?.length > 0)}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          minHeight="68px"
          sx={{ background: theme.newPalette.primary.lighter, padding: 2 }}
        >
          <Typography variant="subtitle2" color="primary">
            {selectedRows?.length} autorizadores seleccionados
          </Typography>
          <IconButton
            icon="delete_bin_line"
            size="medium"
            color="primary"
            onClick={removeAllItems}
          />
        </Stack>
      </Collapse>
      <DndContext
        collisionDetection={closestCorners}
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          id="AUTHORIZERS_LIST"
          items={mappedRows}
          strategy={verticalListSortingStrategy}
        >
          <AutoSizer disableHeight>
            {({ width }) => (
              <TableContainer
                component={Card}
                sx={{
                  width,
                  boxShadow: theme.newPalette.shadow.card,
                  ...(authorizersLength === 0 && { height: 0 }),
                  borderRadius: 0,
                }}
              >
                <Table component="div" ref={setNodeRef}>
                  <TableHeaderAuthorizers />
                  <TableBody
                    component="div"
                    sx={{
                      background: theme.newPalette.background.paper,
                    }}
                  >
                    <FixedSizeList
                      height={ROW_HEIGHT * 4}
                      width={width}
                      itemSize={ROW_HEIGHT}
                      itemKey={(index) => mappedRows[index]._id}
                      itemCount={mappedRows.length}
                      itemData={mappedRows}
                      overscanCount={0}
                      style={{ overflowX: 'hidden' }}
                    >
                      {TableAuthorizersRow}
                    </FixedSizeList>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </AutoSizer>
        </SortableContext>

        <DragOverlay>
          {activeAuth && <AuthorizerOverlay template={activeAuth} />}
        </DragOverlay>
      </DndContext>
    </Stack>
  );
};
