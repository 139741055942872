import { Stack } from '@mui/material';
import { useTheme } from '@mui/system';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import { Typographies } from '../../../../../components/Typographies/Typography';
import { DinamicTooltipToPermissions } from '../../../../RHPod/Dashboard/DashboardCard/Tooltips';
import { CustomCheckbox } from '../../Settings.styles';

const cellColor = (palette) => ({
  companyPermissions: {
    backgroundColor: palette.blueColors.blue_10,
    color: palette.blueColors.default_blue,
  },
  rhPodPermissions: {
    backgroundColor: palette.greenColors.green_10,
    color: palette.greenColors.green_70,
  },
  default: {},
});

const CellContainer = ({ params, children }) => {
  const palette = useTheme().customPalette;
  const colors = cellColor(palette);
  let bgColor = null;
  let color = null;

  if (params.row.hasPriority) {
    if (params.row.permissions === 'Administración general de la plataforma') {
      bgColor = colors.companyPermissions.backgroundColor;
      color = colors.companyPermissions.color;
    } else {
      bgColor = colors.rhPodPermissions.backgroundColor;
      color = colors.rhPodPermissions.color;
    }
  }

  return (
    <Stack
      width="100%"
      height="100%"
      backgroundColor={bgColor}
      color={color}
      justifyContent="center"
      p={2}
      style={{
        ...Typographies.DocTableHeader,
        letterSpacing: 0,
      }}
    >
      {children}
    </Stack>
  );
};

export const columns = (handleChange, palette) => [
  {
    field: 'permissions',
    headerName: 'Permiso',
    width: 400,
    sortable: false,
    renderCell: (params) => {
      return <CellContainer params={params}>{params.value}</CellContainer>;
    },
  },
  {
    field: 'update',
    headerName: 'Editar',
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderHeader: (params) => params.colDef.headerName,
    renderCell: (params) => {
      const { row } = params;
      const checkColor =
        row.fullKey === 'rhPodPermissions' ? 'success' : 'primary';
      return !row.hasOwnProperty('allCheckboxs') ? (
        <CellContainer params={params}></CellContainer>
      ) : (
        <Tooltip
          arrow
          title={DinamicTooltipToPermissions(
            row.tooltip.updatePermissions,
            'edit',
          )}
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                background: 'white',
                boxShadow:
                  '0 8px 16px 0 rgba(65, 67, 91, 0.16), 0 4px 8px 0 rgba(65, 67, 91, 0.16)',
              },
            },
          }}
        >
          <span>
            <CustomCheckbox
              color={checkColor}
              name="update"
              onChange={(e) => handleChange(row, e)}
              inputProps={{ 'aria-labelledby': row.id }}
              {...row.allCheckboxs.editCheckbox}
            />
          </span>
        </Tooltip>
      );
    },
  },
  {
    field: 'read',
    headerName: 'Ver',
    flex: 1,
    minWidth: 100,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderHeader: (params) => params.colDef.headerName,
    renderCell: (params) => {
      const { row } = params;
      const checkColor =
        row.fullKey === 'rhPodPermissions' ? 'success' : 'primary';

      return !row.hasOwnProperty('allCheckboxs') ? (
        <CellContainer params={params}></CellContainer>
      ) : (
        <Tooltip
          arrow
          title={DinamicTooltipToPermissions(
            row.tooltip.readPermissions,
            'read',
          )}
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                background: 'white',
                boxShadow:
                  '0 8px 16px 0 rgba(65, 67, 91, 0.16), 0 4px 8px 0 rgba(65, 67, 91, 0.16)',
              },
            },
          }}
        >
          <span>
            <CustomCheckbox
              color={checkColor}
              name="read"
              onChange={(e) => handleChange(row, e)}
              inputProps={{ 'aria-labelledby': row.id }}
              {...row.allCheckboxs.readCheckbox}
            />
          </span>
        </Tooltip>
      );
    },
  },
];

const recordChecklistPermission = () => ({
  permissions: 'Expedientes laborales',
  fullKey: 'rhPodPermissions',
  key: 'recordChecklist',
  tooltip: {
    readPermissions: {
      disabled: false,
      options: ['Ver expedientes laborales'],
    },
    updatePermissions: {
      disabled: false,
      options: [
        'Crear expedientes laborales',
        'Editar expedientes laborales',
        'Eliminar expedientes laborales',
      ],
    },
  },
});

const requestDocsToEmployeesPermission = () => ({
  permissions: 'Solicitar documentos a empleados',
  fullKey: 'rhPodPermissions',
  key: 'requestDocsToEmployees',
  tooltip: {
    readPermissions: {
      disabled: false,
      options: ['Ver cuales documentos se solicitaron al empleado'],
    },
    updatePermissions: {
      disabled: false,
      options: ['Solicitar al empleado que cargue documentos'],
    },
  },
});

const approveDocsFromEmployeesPermission = () => ({
  permissions: 'Aprobar documentos cargados por los empleados',
  fullKey: 'rhPodPermissions',
  key: 'approveDocsFromEmployees',
  tooltip: {
    readPermissions: {
      disabled: false,
      options: ['Ver los documentos cargados por los empleados'],
    },
    updatePermissions: {
      disabled: false,
      options: ['Aprobar o rechazar los documentos cargados por los empleados'],
    },
  },
});

const authorizeTemplatesPermission = () => ({
  permissions: 'Autorizar plantillas',
  fullKey: 'rhPodPermissions',
  key: 'authorizeTemplates',
  tooltip: {
    readPermissions: {
      disabled: true,
      defaultChecked: true,
      options: [],
    },
    updatePermissions: {
      disabled: false,
      options: ['Autorizar plantillas', 'Rechazar plantillas'],
    },
  },
});

const publishTemplatesPermission = () => ({
  permissions: 'Publicar plantillas',
  fullKey: 'rhPodPermissions',
  key: 'publishTemplates',
  tooltip: {
    readPermissions: {
      disabled: true,
      defaultChecked: true,
      options: [],
    },
    updatePermissions: {
      disabled: false,
      options: ['Publicar plantillas', 'Despublicar plantillas'],
    },
  },
});

export const allRowsSuperAdmin = [
  {
    permissions: 'Administración general de la plataforma',
    selectAll: false,
    hasPriority: true,
  },
  {
    permissions: 'Personalizar empresa',
    fullKey: 'companyPermissions',
    key: 'companyData',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver la información de la empresa'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Actualizar los logos', 'Editar datos de la empresa'],
      },
    },
  },
  {
    permissions: 'Agregar o eliminar súper administradores',
    fullKey: 'companyPermissions',
    key: 'superAdmins',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver el listado de súper administradores'],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Crear y eliminar',
          'Editar sus datos',
          'Administrar sus permisos',
        ],
      },
    },
  },
  {
    permissions: 'Activación de módulos',
    fullKey: 'companyPermissions',
    key: 'modules',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver los módulos activos'],
      },
      updatePermissions: {
        disabled: true,
        // permissions: ['Contratar módulos de Sora'],
      },
    },
  },
  {
    permissions: 'Consumo de GB',
    fullKey: 'companyPermissions',
    key: 'consumption',
    tooltip: {
      readPermissions: {
        disabled: true,
      },
      updatePermissions: {
        disabled: true,
      },
    },
  },
  {
    permissions: 'Facturación',
    fullKey: 'companyPermissions',
    key: 'billing',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver la sección de facturación'],
      },
      updatePermissions: {
        disabled: true,
      },
    },
  },
  {
    permissions: 'Roles y permisos',
    fullKey: 'companyPermissions',
    key: 'rolesAndPermissions',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver la configuración de los roles predeterminados'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Configurar roles predeterminados'],
      },
    },
  },
  {
    permissions: 'Representante legal',
    fullKey: 'companyPermissions',
    key: 'legalRepresentative',
    tooltip: {
      readPermissions: {
        disabled: false,
        defaultChecked: true,
        options: ['Ver documentos firmados.'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Firmar documentos de la empresa como representante legal.'],
      },
    },
  },
  {
    permissions: 'Firmar documentos',
    fullKey: 'companyPermissions',
    key: 'signDocuments',
    tooltip: {
      readPermissions: {
        disabled: false,
        defaultChecked: true,
        options: ['Ver documentos firmados.'],
      },
      updatePermissions: {
        disabled: false,
        // defaultChecked: false,
        options: [
          'Firmar documentos de la empresa aunque no sea representante legal.',
        ],
      },
    },
  },
  {
    permissions: 'Administración del módulo laboral',
    selectAll: false,
    hasPriority: true,
  },
  {
    permissions: 'Agregar o eliminar administradores y líderes de CT',
    fullKey: 'rhPodPermissions',
    key: 'hrAdmins',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: [
          'Ver listado de administradores y líderes de centro de trabajo',
        ],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Agregar y eliminar administradores y líderes de centro de trabajo',
          'Editar sus datos',
          'Administrar sus permisos',
        ],
      },
    },
  },
  {
    permissions: 'Dashboard',
    fullKey: 'rhPodPermissions',
    key: 'dashboard',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: [
          'Filtrar por fecha',
          'Filtrar por centro de trabajo',
          'Exportar reportes',
        ],
      },
      updatePermissions: {
        disabled: true,
      },
    },
  },
  {
    permissions: 'Recibos',
    fullKey: 'rhPodPermissions',
    key: 'receipts',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Visualizar contenido de recibos', 'Descargar recibos'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Importar recibos', 'Eliminar recibos no firmados'],
      },
    },
  },
  {
    permissions: 'Documentos',
    fullKey: 'rhPodPermissions',
    key: 'documents',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Visualizar contenido de documentos', 'Descargar documentos'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Importar documentos', 'Eliminar documentos no firmados'],
      },
    },
  },
  requestDocsToEmployeesPermission(),
  approveDocsFromEmployeesPermission(),
  {
    permissions: 'Plantillas',
    fullKey: 'rhPodPermissions',
    key: 'templates',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: [
          'Visualizar las plantillas',
          'Usar plantillas existentes para enviar documentos',
        ],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Crear plantillas',
          'Editar información de las plantillas',
          'Eliminar plantillas',
        ],
      },
    },
  },
  authorizeTemplatesPermission(),
  publishTemplatesPermission(),
  {
    permissions: 'Empleados',
    fullKey: 'rhPodPermissions',
    key: 'employees',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver información del empleado'],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Agregar empleados',
          'Editar su información',
          'Cambiar su estatus',
          'Eliminar empleados',
        ],
      },
    },
  },
  {
    permissions: 'Puestos de trabajo',
    fullKey: 'rhPodPermissions',
    key: 'workTitles',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver puestos de trabajo desde la sección preferencias'],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Agregar puestos de trabajo',
          'Editar puestos de trabajo',
          'Eliminar puestos de trabajo',
        ],
      },
    },
  },
  {
    permissions: 'Grupos',
    fullKey: 'rhPodPermissions',
    key: 'groups',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver grupos desde la sección preferencias'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Agregar grupos', 'Editar grupos', 'Eliminar grupos'],
      },
    },
  },
  recordChecklistPermission(),
  {
    permissions: 'Centros de trabajo',
    fullKey: 'rhPodPermissions',
    key: 'workCenters',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver la información de los centros de trabajo'],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Crear centros de trabajo',
          'Editar su información',
          // 'Asignar Líderes',
          // 'Eliminar Líderes',
        ],
      },
    },
  },
  {
    permissions: 'Reporte de grupo de empresas',
    fullKey: 'rhPodPermissions',
    key: 'companyGroupReports',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Descargar el reporte en formato excel'],
      },
      updatePermissions: { disabled: true },
    },
  },
];

export const allRowsAdminRH = [
  //adminRh
  {
    permissions: 'Administración del módulo laboral',
    selectAll: false,
    hasPriority: true,
  },
  {
    permissions: 'Agregar o eliminar administradores y líderes de CT',
    fullKey: 'rhPodPermissions',
    key: 'hrAdmins',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: [
          'Ver listado de administradores y líderes de centro de trabajo',
        ],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Agregar y eliminar administradores y líderes de centro de trabajo',
          'Editar sus datos',
          'Administrar sus permisos',
        ],
      },
    },
  },
  {
    permissions: 'Dashboard',
    fullKey: 'rhPodPermissions',
    key: 'dashboard',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: [
          'Filtrar por fecha',
          'Filtrar por centro de trabajo',
          'Exportar reportes',
        ],
      },
      updatePermissions: {
        disabled: true,
      },
    },
  },
  {
    permissions: 'Recibos',
    fullKey: 'rhPodPermissions',
    key: 'receipts',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Visualizar contenido de recibos', 'Descargar recibos'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Importar recibos', 'Eliminar recibos no firmados'],
      },
    },
  },
  {
    permissions: 'Documentos',
    fullKey: 'rhPodPermissions',
    key: 'documents',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Visualizar contenido de documentos', 'Descargar documentos'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Importar documentos', 'Eliminar documentos no firmados'],
      },
    },
  },
  requestDocsToEmployeesPermission(),
  approveDocsFromEmployeesPermission(),
  {
    permissions: 'Plantillas',
    fullKey: 'rhPodPermissions',
    key: 'templates',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: [
          'Visualizar las plantillas',
          'Usar plantillas existentes para enviar documentos',
        ],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Crear plantillas',
          'Editar información de las plantillas',
          'Eliminar plantillas',
        ],
      },
    },
  },
  authorizeTemplatesPermission(),
  publishTemplatesPermission(),
  {
    permissions: 'Empleados',
    fullKey: 'rhPodPermissions',
    key: 'employees',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver información del empleado'],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Agregar empleados',
          'Editar su información',
          'Cambiar su estatus',
          'Eliminar empleados',
        ],
      },
    },
  },
  {
    permissions: 'Puestos de trabajo',
    fullKey: 'rhPodPermissions',
    key: 'workTitles',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver puestos de trabajo desde la sección preferencias'],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Agregar puestos de trabajo',
          'Editar puestos de trabajo',
          'Eliminar puestos de trabajo',
        ],
      },
    },
  },
  {
    permissions: 'Grupos',
    fullKey: 'rhPodPermissions',
    key: 'groups',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver grupos desde la sección preferencias'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Agregar grupos', 'Editar grupos', 'Eliminar grupos'],
      },
    },
  },
  recordChecklistPermission(),
  {
    permissions: 'Centros de trabajo',
    fullKey: 'rhPodPermissions',
    key: 'workCenters',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver la información de los centros de trabajo'],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Crear centros de trabajo',
          'Editar su información',
          // 'Asignar Líderes',
          // 'Eliminar Líderes',
        ],
      },
    },
  },
  {
    permissions: 'Reporte de grupo de empresas',
    fullKey: 'rhPodPermissions',
    key: 'companyGroupReports',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Descargar el reporte en formato excel'],
      },
      updatePermissions: { disabled: true },
    },
  },
  {
    permissions: 'Representante legal',
    fullKey: 'rhPodPermissions',
    key: 'legalRepresentative',
    tooltip: {
      readPermissions: {
        disabled: false,
        defaultChecked: true,
        options: ['Ver documentos firmados.'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Firmar documentos de la empresa como representante legal.'],
      },
    },
  },
  {
    permissions: 'Firmar documentos',
    fullKey: 'rhPodPermissions',
    key: 'signDocuments',
    tooltip: {
      readPermissions: {
        disabled: false,
        defaultChecked: true,
        options: ['Ver documentos firmados.'],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Firmar documentos de la empresa aunque no sea representante legal.',
        ],
      },
    },
  },
];

export const allRowsLeaderCT = [
  {
    permissions: 'Administración del centro de trabajo',
    selectAll: false,
    hasPriority: true,
  },
  /* {
    
    permissions: 'Agregar o eliminar líder de centro de trabajo',
    fullKey: 'rhPodPermissions',
    key: 'ctAdmins',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver listado de lideres de centro de trabajo'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Agregar líderes de centro de trabajo'],
      },
    },
  }, */
  {
    permissions: 'Dashboard',
    fullKey: 'rhPodPermissions',
    key: 'dashboard',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: [
          'Filtrar por fecha',
          'Filtrar por centro de trabajo',
          'Exportar reportes',
        ],
      },
      updatePermissions: {
        disabled: true,
      },
    },
  },
  {
    permissions: 'Recibos',
    fullKey: 'rhPodPermissions',
    key: 'receipts',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Visualizar contenido de recibos', 'Descargar recibos'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Importar recibos', 'Eliminar recibos no firmados'],
      },
    },
  },
  {
    permissions: 'Documentos',
    fullKey: 'rhPodPermissions',
    key: 'documents',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Visualizar contenido de documentos', 'Descargar documentos'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Importar documentos', 'Eliminar documentos no firmados'],
      },
    },
  },
  requestDocsToEmployeesPermission(),
  approveDocsFromEmployeesPermission(),
  {
    permissions: 'Plantillas',
    fullKey: 'rhPodPermissions',
    key: 'templates',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: [
          'Visualizar las plantillas',
          'Usar plantillas existentes para enviar documentos',
        ],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Crear plantillas',
          'Editar información de las plantillas',
          'Eliminar plantillas',
        ],
      },
    },
  },
  {
    permissions: 'Autorizar plantillas',
    fullKey: 'rhPodPermissions',
    key: 'authorizeTemplates',
    tooltip: {
      readPermissions: {
        disabled: true,
        defaultChecked: true,
        options: [],
      },
      updatePermissions: {
        disabled: true,
        options: [],
      },
    },
  },
  {
    permissions: 'Publicar plantillas',
    fullKey: 'rhPodPermissions',
    key: 'publishTemplates',
    tooltip: {
      readPermissions: {
        disabled: true,
        defaultChecked: true,
        options: [],
      },
      updatePermissions: {
        disabled: true,
        options: [],
      },
    },
  },
  {
    permissions: 'Empleados',
    fullKey: 'rhPodPermissions',
    key: 'employees',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver información del empleado'],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Agregar empleados',
          'Editar su información',
          'Cambiar su estatus',
          'Eliminar empleados',
        ],
      },
    },
  },
  {
    permissions: 'Puestos de trabajo',
    fullKey: 'rhPodPermissions',
    key: 'workTitles',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver puestos de trabajo desde la sección preferencias'],
      },
      updatePermissions: {
        disabled: false,
        options: [
          'Agregar puestos de trabajo',
          'Editar puestos de trabajo',
          'Eliminar puestos de trabajo',
        ],
      },
    },
  },
  {
    permissions: 'Grupos',
    fullKey: 'rhPodPermissions',
    key: 'groups',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver grupos desde la sección preferencias'],
      },
      updatePermissions: {
        disabled: false,
        options: ['Agregar grupos', 'Editar grupos', 'Eliminar grupos'],
      },
    },
  },
  recordChecklistPermission(),
  {
    permissions: 'Centros de trabajo',
    fullKey: 'rhPodPermissions',
    key: 'workCenters',
    tooltip: {
      readPermissions: {
        disabled: false,
        options: ['Ver la información de los centros de trabajo'],
      },
      updatePermissions: {
        disabled: true,
        /* options: [
          'Crear centros de trabajo',
          'Editar su información',
          'Asignar Líderes',
          'Eliminar Líderes',
        ], */
      },
    },
  },
  {
    permissions: 'Firmar documentos',
    fullKey: 'rhPodPermissions',
    key: 'signDocuments',
    tooltip: {
      readPermissions: {
        disabled: false,
        defaultChecked: true,
        options: ['Ver documentos firmados.'],
      },
      updatePermissions: {
        disabled: false,
        // defaultChecked: false,
        options: [
          'Firmar documentos de la empresa aunque no sea representante legal.',
        ],
      },
    },
  },
];
