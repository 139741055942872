// @ts-check
import { useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { globalSnackbarVar } from '../../cache.reactiveVars';
import {
  COMPANY_SIGNER_ROW,
  FIND_BY_COMPANY_SIGNERS,
} from './EmployeesFinder.constants';
import { employeesFinderVar } from './EmployeesFinder.vars';
import { GET_ADMIN_USERS_FROM_COMPANY } from '../../containers/MyCompany/Company/company.gql';

/**  @param {import('./EmployeesFinder.types').QueryHookProps} params */
export const useAllCompanyAdmins = ({
  searchValue,
  //@ts-ignore
  permissions,
}) => {
  const { companySigners = [], findBySelected } =
    useReactiveVar(employeesFinderVar);

  const [allCompSignersQuery] = useLazyQuery(GET_ADMIN_USERS_FROM_COMPANY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    // variables: {
    //   adminTypes: ['masterAdmin', 'wcAdmin', 'rhAdmin'],
    //   searchPermission: 'signDocuments',
    // },
    onCompleted: (response) => {
      if (response?.getCompanyAdmins) {
        const companyAdmins = response.getCompanyAdmins ?? [];
        const total = companyAdmins?.length || 0;
        const mapFunc = (row) => ({ ...row, type: COMPANY_SIGNER_ROW });
        const newAdmins = companyAdmins.map(mapFunc);
        employeesFinderVar({
          ...employeesFinderVar(),
          companySigners: newAdmins,
          data: { options: newAdmins, total },
          loading: false,
        });
      }
    },
    onError: (error) => {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Hubo un error al obtener administradores',
        duration: 5000,
      });
    },
  });

  const handleFetchCompanyAdmins = () => {
    allCompSignersQuery({
      variables: {
        adminTypes: ['masterAdmin', 'wcAdmin', 'rhAdmin'],
        searchPermission: permissions || 'signDocuments',
      },
    });
  };

  useEffect(() => {
    if (findBySelected === FIND_BY_COMPANY_SIGNERS) {
      const filteredAdmins = companySigners?.filter(
        (admin) =>
          admin?.fullName?.toLowerCase().includes(searchValue.toLowerCase()) ||
          admin?.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
          admin?.rfc?.toLowerCase().includes(searchValue.toLowerCase()),
      );
      employeesFinderVar({
        ...employeesFinderVar(),
        data: { options: filteredAdmins, total: filteredAdmins.length },
        loading: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, findBySelected]);

  return {
    handleFetchCompanyAdmins,
  };
};
