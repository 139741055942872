import { useController } from 'react-hook-form';
import { TextInput } from '../../../newComponents/TextInput';
import type { ControlledDocumentTitleInputProps } from './ControlledDocumentTitleInput.types';

export function ControlledDocumentTitleInput({
  control,
  rules = {},
  textFieldProps = {},
}: ControlledDocumentTitleInputProps) {
  const name = 'title';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <TextInput
      id={textFieldProps.id || name}
      value={value}
      onChange={(e) => {
        const inputValue = e.target.value;
        onChange(inputValue);
      }}
      onBlur={onBlur}
      error={hasError}
      helperText={helperText}
      {...textFieldProps}
    />
  );
}
