// @ts-check
import { useState, useEffect, useMemo } from 'react';
import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Button } from '../../../../../newComponents/Button';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { DocumentPreviewDialog } from '../../DocumentCreator/DocumentPreviewDialog';
import { getHmtlFromCreator } from '../../DocumentCreator/helper';
import { TemplateSettigsUpdateForm } from './TemplateSettingsUpdateForm';
import { TemplateEditorUpdateForm } from './TemplateEditorUpdateForm';
import { useScreenSize, usePermissionChecker } from '../../../../../Hooks';
import { useTemplateUpdate } from '../TemplateHooks';
import { DEFAULT_FORM_VALUES } from './TemplateUpdateDialog.constants';
import { TemplateUnsavedChangesDialog } from '../TemplateUnsavedChangesDialog';
import {
  resetAllFilterTemplates,
  resetUpdateTemplateDialogVar,
  templateItemVar,
  updateTemplateDialogVar,
} from '../../../../MyCompany/Settings/Preferences/Templates/Templates.vars.js';
import { TemplateAuthorizersUpdateForm } from '../../../../MyCompany/Settings/Preferences/Templates/TemplateAuthorizers/TemplateAuthorizersUpdateForm.js';
import { verifyIfAuthorizersIsDirty } from '../../../../MyCompany/Settings/Preferences/Templates/Templates.helpers.js';

export const TemplateUpdateDialog = () => {
  const { isMobile } = useScreenSize();
  const { open, currentTemplate, updateType } = useReactiveVar(
    updateTemplateDialogVar,
  );
  const templateItem = useReactiveVar(templateItemVar);

  const { handleUpdateTemplate } = useTemplateUpdate();
  const [showConfirmCloseDialog, setShowConfirmCloseDialog] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const userHasPermissionToCreateOrEdit = usePermissionChecker({
    permission: 'templates',
    action: 'update',
  });

  const form = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const authorizersIsDirty = useMemo(() => {
    return verifyIfAuthorizersIsDirty({
      oldAuths: currentTemplate?.authorizers,
      currAuths: templateItem?.authorizers ?? [],
      oldOrder: currentTemplate?.authorizeInOrder,
      currOrder: templateItem?.isOrdered,
    });
  }, [currentTemplate, templateItem.authorizers, templateItem.isOrdered]);

  const formIsValid = form.formState.isValid;
  const isDirtyForm = form.formState.isDirty;
  const formIsDirty =
    updateType === 'AUTHORIZERS'
      ? isDirtyForm || authorizersIsDirty
      : isDirtyForm;

  const handleCloseDialog = (force?: boolean) => {
    if (force || !formIsDirty) {
      form.reset(DEFAULT_FORM_VALUES);
      setShowConfirmCloseDialog(false);
      resetUpdateTemplateDialogVar();
      resetAllFilterTemplates();
      return;
    }

    if (formIsDirty) {
      setShowConfirmCloseDialog(true);
    }
  };

  const handleTemplateUpdate = () => {
    handleUpdateTemplate({
      data: form.getValues(),
      onSuccess: () => {
        handleCloseDialog(true);
      },
      excludeUpdates: updateType !== 'AUTHORIZERS' ? ['authorizers'] : [],
      content: updateType,
    });
  };

  useEffect(() => {
    //AUTHORIZERS IS SETTED IN AUTHORIZERS FORM
    if (currentTemplate) {
      form.reset(currentTemplate);
    }
  }, [currentTemplate, form]);

  const createHtml = () => {
    const content = form.getValues().myEditor;
    return getHmtlFromCreator({ data: {}, content, preview: true });
  };

  const getMaxWidth = () => {
    if (updateType === 'SETTINGS') return 'xs';
    if (updateType === 'AUTHORIZERS') return 'sm';
    return 'xl';
  };

  return (
    <>
      <Dialog
        slideMode
        fullWidth
        open={open}
        maxWidth={getMaxWidth()}
        onClose={() => handleCloseDialog()}
        showCloseButton={false}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 3,
          }}
          component="div"
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Icon
              color="grey"
              icon="close_line"
              height="26px"
              onClick={() => handleCloseDialog()}
              style={{ cursor: 'pointer' }}
            />
            Editar plantilla
          </Stack>
          {updateType === 'CONTENT' && (
            <Stack>
              {isMobile ? (
                <IconButton
                  icon="eye_line"
                  color="default"
                  onClick={() => setShowPreviewDialog(true)}
                />
              ) : (
                <Button
                  variant="outlined"
                  color="default"
                  startIcon={<Icon icon="eye_line" />}
                  onClick={() => setShowPreviewDialog(true)}
                >
                  Previsualizar
                </Button>
              )}
            </Stack>
          )}
        </DialogTitle>
        <DialogContent dividers>
          {updateType === 'SETTINGS' && (
            <TemplateSettigsUpdateForm form={form} />
          )}
          {updateType === 'CONTENT' && <TemplateEditorUpdateForm form={form} />}
          {updateType === 'AUTHORIZERS' && (
            <TemplateAuthorizersUpdateForm form={form} />
          )}
        </DialogContent>
        <DialogActions sx={{ p: '24px' }}>
          <Button
            variant="contained"
            size="large"
            disabled={
              !formIsValid || !formIsDirty || !userHasPermissionToCreateOrEdit
            }
            onClick={form.handleSubmit(handleTemplateUpdate)}
            tooltipText={
              !userHasPermissionToCreateOrEdit &&
              'No cuentas con los permisos para esta acción'
            }
            tooltipArrow
            tooltipPlacement="top"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <TemplateUnsavedChangesDialog
        showDialog={showConfirmCloseDialog}
        closeDialog={() => setShowConfirmCloseDialog(false)}
        onConfirm={() => handleCloseDialog(true)}
      />
      <DocumentPreviewDialog
        open={showPreviewDialog}
        setOpen={setShowPreviewDialog}
        createHtml={createHtml}
      />
    </>
  );
};
