//@ts-check
import { Stack, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import ConfirmationDialog from '../../../../../components/Dialogs/ConfirmationDialog';
import { DefaultErrorComponent } from '../../../../../components/ErrorBoundary/ErrorBoundary';
import { withErrorBoundary } from '@sentry/react';
import { loggerUtil } from '../../../../../utils/loggerUtil';
import { Typography } from '../../../../../newComponents/Typography';
import { TextInput } from '../../../../../newComponents/TextInput';
import { Button } from '../../../../../newComponents/Button';
import { IconButton } from '../../../../../newComponents/IconButton';
import { WorkTitlesFinder } from '../../../../../businessComponents/WorkTitlesFinder';
import { WorkCentersFinder } from '../../../../../businessComponents/WorkCentersFinder';
import { GET_EMPLOYEE_INFO } from '../EmployeeInfo.gql';
import { useGetCompanyEmployeesInput } from '../../Hooks/useGetCompanyEmployeesInput.hooks';
import {
  ControlledSalaryInput,
  ControlledSocialSecurityNumberInput,
  ControlledPayrollIdInput,
  ControlledContractTypeInput,
  ControlledWorkDayTypeInput,
  ControlledEmploymentStartDateInput,
} from '../../../../../businessComponents/ControlledInputs';
import {
  GET_COMPANY_EMPLOYEES,
  GET_WORKCENTERS,
  UPDATE_EMPLOYEE_NOMINA_DATA,
} from '../../Employees.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import { getJobDataModified } from './EmployeeInfoGeneralData.utils';
import { updateMissingDataToVerifyEmployee } from '../../../Documents/CreateDocuments/CreateDocumentDialog.helpers';
import {
  selectedEmployeeWithErrorVar,
  setSelectedEmployeeWithErrorVar,
} from '../../../Documents/CreateDocuments/CreateDocumentDialog.vars';

export const EmployeeNominaDataForm = withErrorBoundary(
  /**
   * @param {object} props
   * @param {import('../EmployeeInfo.types').EmployeeInfo} props.employeeData
   * @param {import("../../../../../types/permissions.types").UserPermissions} props.userPermissions
   * @param {import('../EmployeeInfo.types').JobDataForm} props.employeeNominaDataForm
   * @param {() => void} props.resetEmployeeNominaDataForm
   */
  ({
    employeeData,
    userPermissions,
    employeeNominaDataForm,
    resetEmployeeNominaDataForm,
  }) => {
    const {
      control,
      handleSubmit,
      formState: { isDirty, errors, dirtyFields },
      reset,
    } = employeeNominaDataForm;
    const selectedEmployeeWithErrors = useReactiveVar(
      selectedEmployeeWithErrorVar,
    );
    const [originalWcTitle, setOriginalWcTitle] = useState('');
    const [newWcTitle, setNewWcTitle] = useState('');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [savedFormData, setSavedFormData] = useState({});
    const [isFormEnabled, setIsFormEnabled] = useState(false);
    const [jobSeniority, setJobSeniority] = useState('');

    const { data, loading } = useQuery(GET_WORKCENTERS);

    const { getCompanyEmployeesInput } = useGetCompanyEmployeesInput();

    const [updateEmployeeNominaData] = useMutation(
      UPDATE_EMPLOYEE_NOMINA_DATA,
      {
        refetchQueries: [
          { query: GET_WORKCENTERS },
          {
            query: GET_COMPANY_EMPLOYEES,
            variables: { input: getCompanyEmployeesInput },
          },
          {
            query: GET_EMPLOYEE_INFO,
            variables: { input: { employeeId: employeeData.userId } },
          },
        ],
      },
    );

    const handleUpdateMissingFields = (fields) => {
      const newMissingData = updateMissingDataToVerifyEmployee({
        missingData: selectedEmployeeWithErrors.errors ?? [],
        modifiedFields: fields,
      });

      setSelectedEmployeeWithErrorVar({
        modifiedErrors: newMissingData,
        errors: newMissingData,
      });
    };

    const updateDataToServer = async (formData) => {
      const showGenericError = () =>
        globalSnackbarVar({
          severity: 'error',
          show: true,
          message: 'Hubo un error. Contacte a Sora',
        });
      try {
        globalBackdropVar({
          text: 'Actualizando datos',
          clickable: false,
          open: true,
        });

        const modifiedFields = getJobDataModified({ dirtyFields, formData });

        const { data } = await updateEmployeeNominaData({
          variables: {
            input: {
              ...modifiedFields,
              _id: employeeData.userId,
              oldWorkCenterId: employeeData.workCenterId,
            },
          },
        });
        const resultType = data?.updateEmployeeNominaData?.__typename;
        switch (resultType) {
          case 'Success':
            globalSnackbarVar({
              severity: 'success',
              show: true,
              message: 'Datos actualizados',
            });
            reset(formData);
            handleUpdateMissingFields({ ...modifiedFields, jobSeniority });
            break;
          default:
            showGenericError();
            loggerUtil.error('Error updating nomina fields', resultType);
        }
      } catch (e) {
        loggerUtil.error(e);
        showGenericError();
      } finally {
        globalBackdropVar({ open: false });
        setSavedFormData({});
        setIsFormEnabled(false);
      }
    };

    const onSubmit = async (formData) => {
      const newWcTitleFromForm = (data?.allWorkCenters ?? []).find(
        (wc) => wc._id === formData.workCenterId,
      );
      const originalWcTitleFromForm = (data?.allWorkCenters ?? []).find(
        (wc) => wc._id === employeeData.workCenterId,
      );
      if (newWcTitleFromForm) {
        setNewWcTitle(newWcTitleFromForm.name);
      }
      if (originalWcTitleFromForm) {
        setOriginalWcTitle(originalWcTitleFromForm.name);
      }

      if (newWcTitleFromForm !== originalWcTitleFromForm) {
        setSavedFormData(formData);
        setOpenConfirmDialog(true);
        return;
      }
      await updateDataToServer(formData);
    };

    const onErrorSubmit = () => {
      globalSnackbarVar({
        severity: 'warning',
        message: 'Verifica los campos',
        show: true,
      });
    };

    if (loading) return null;

    const userCanEditForm = userPermissions && userPermissions?.mply?.update;

    return (
      <Stack>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Typography variant="h6" color="text">
              Datos laborales
            </Typography>
            {userCanEditForm && !isFormEnabled ? (
              <IconButton
                tooltipText={
                  userCanEditForm
                    ? 'Da click aquí para editar'
                    : 'Necesitas permisos para esta acción'
                }
                tooltipArrow
                tooltipPlacement="top"
                icon="edit_line"
                aria-label="edit"
                size="small"
                onClick={() => setIsFormEnabled(true)}
                disabled={!userCanEditForm}
              />
            ) : null}
          </Stack>
        </Stack>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={4}>
            <Controller
              name="workCenterId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <WorkCentersFinder
                  multiple={false}
                  loadWhenOpen={false}
                  disabled={!isFormEnabled}
                  workCenterIdSelected={value}
                  onSelectWorkCenter={(workCenter) => {
                    if (workCenter === null) return onChange('');
                    onChange(workCenter.id);
                  }}
                  TextFieldProps={{
                    id: 'workCenterId',
                    label: 'Centro de trabajo',
                    size: 'medium',
                    required: true,
                    error: Boolean(!!errors.workCenterId),
                    helperText: errors.workCenterId ? 'Campo requerido' : '',
                  }}
                />
              )}
            />
            {employeeData.workCenterId === null && (
              <Typography variant="body1">
                Este usuario no está registrado como empleado en ningún centro
                de trabajo
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              name="workTitle"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <WorkTitlesFinder
                  multiple={false}
                  loadWhenOpen={false}
                  disabled={!isFormEnabled}
                  workTitleNameSelected={value}
                  onSelectWorkTitle={(workTitle) => {
                    if (workTitle === null) return onChange('');
                    onChange(workTitle.name);
                  }}
                  TextFieldProps={{
                    size: 'medium',
                    label: 'Puesto',
                    required: true,
                    placeholder: '',
                    error: !!errors.workTitle,
                    helperText: errors.workTitle ? 'Campo requerido' : '',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledPayrollIdInput
              control={control}
              textFieldProps={{
                label: 'Número de Nómina',
                disabled: !isFormEnabled,
                fullWidth: true,
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledSalaryInput
              control={control}
              textFieldProps={{
                label: 'Salario',
                fullWidth: true,
                disabled: !isFormEnabled,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledSocialSecurityNumberInput
              control={control}
              textFieldProps={{
                label: 'Número de Seguro Social',
                disabled: !isFormEnabled,
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledContractTypeInput
              control={control}
              textFieldProps={{
                label: 'Tipo de contrato',
                disabled: !isFormEnabled,
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledWorkDayTypeInput
              control={control}
              textFieldProps={{
                label: 'Tipo de jornada',
                disabled: !isFormEnabled,
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ControlledEmploymentStartDateInput
              control={control}
              textFieldProps={{
                label: 'Fecha de ingreso',
                disabled: !isFormEnabled,
                fullWidth: true,
              }}
              onJobSeniorityChange={setJobSeniority}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              disabled
              label="Antigüedad"
              value={jobSeniority}
              fullWidth
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="end" gap={2} mt={2}>
          {isFormEnabled ? (
            <Button
              color="default"
              variant="outlined"
              onClick={() => {
                setIsFormEnabled(false);
                resetEmployeeNominaDataForm();
              }}
            >
              Cancelar
            </Button>
          ) : null}

          <Button
            tooltipText={
              userCanEditForm
                ? 'Editar datos de nomina'
                : 'Necesitas permisos para esta acción'
            }
            tooltipArrow
            tooltipPlacement="top"
            color="primary"
            variant="contained"
            style={{ alignSelf: 'flex-end' }}
            disabled={!userCanEditForm || !isDirty}
            onClick={handleSubmit(onSubmit, onErrorSubmit)}
          >
            Actualizar
          </Button>
          <ConfirmationDialog
            open={openConfirmDialog}
            title="Confirma el cambio de centro de trabajo"
            content={
              <span>
                ¿Quieres cambiar a este usuario de{' '}
                <strong>{originalWcTitle}</strong> a{' '}
                <strong>{newWcTitle}</strong>?
              </span>
            }
            acceptLabel="Confirmar cambio"
            onClose={async (result) => {
              if (result) await updateDataToServer(savedFormData);
              setOpenConfirmDialog(false);
            }}
          />
        </Stack>
      </Stack>
    );
  },
  {
    fallback: <DefaultErrorComponent />,
  },
);
