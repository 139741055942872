import { useMutation } from '@apollo/client';
import { DELETE_TEMPLATE } from '../../gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import { updateTemplatesVar } from '../../../../MyCompany/Settings/Preferences/Templates/Templates.vars.js';

export type DeleteTemplateInput = {
  data: { templateId: string };
  onSuccess: () => void;
};

export const useTemplateDeletion = () => {
  const [DeleteTemplate] = useMutation(DELETE_TEMPLATE);

  const handleDeleteTemplate = async (input: DeleteTemplateInput) => {
    try {
      const { data, onSuccess } = input;
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Eliminando plantilla...',
      });

      const deleteRes = await DeleteTemplate({
        variables: {
          _id: data.templateId,
        },
      });

      const { deleteTemplate } = deleteRes.data;

      if (deleteTemplate.__typename === 'ResourceNotFound') {
        return globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'La plantilla a eliminar ya no se encuentra registrada',
        });
      }

      if (deleteTemplate.__typename === 'TemplateDeleted') {
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Plantilla eliminada',
        });
        onSuccess();
      }

      updateTemplatesVar(!updateTemplatesVar());
    } catch (error) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleDeleteTemplate };
};
