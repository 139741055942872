import { DialogTitle, Stack, useTheme } from '@mui/material';
import { usePermissionChecker, useScreenSize } from '../../../../../Hooks';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Typography } from '../../../../../newComponents/Typography';
import { Button } from '../../../../../newComponents/Button';
import { Icon } from '../../../../../components/Icons/Icons';
import { useState } from 'react';
import { SimpleTemplateCreationDialog } from '../../Templates/TemplateCreationDialog';
import type { CreateDocumentDialogTitleProps } from '../CreateDocumentDialog.types';
import { createDocumentConterVar } from '../CreateDocumentDialog.vars';
import { useReactiveVar } from '@apollo/client';
import { PreviewTemplateButton } from '../../../../../businessComponents/PreviewTemplateButton';
import { TITLES } from '../CreateDocumentDialog.constants';

export const tooltipText = (editor: string, permission: boolean) => {
  if (!permission) {
    return 'No tienes permisos para realizar esta acción';
  }

  if (!editor) {
    return 'No hay contenido en la plantilla';
  }

  return '';
};

export const CreateDocumentDialogTitle = (
  props: CreateDocumentDialogTitleProps,
) => {
  const { onClose, form } = props;
  const theme = useTheme();
  const content = useReactiveVar(createDocumentConterVar);
  const userHasPermissionToUpdate = usePermissionChecker({
    permission: 'templates',
    action: 'update',
  });
  const { isMobile } = useScreenSize();
  const [openSimpleDialog, setOpenSimpleDialog] = useState(false);

  const values = form.getValues();

  const title = TITLES[content] || 'Crear Documento';

  return (
    <DialogTitle
      sx={{
        borderBottom: `1px solid ${theme.newPalette.divider}`,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <IconButton onClick={onClose} icon="close_line" color="grey" />
          {!isMobile && <Typography variant="h6">{title}</Typography>}
        </Stack>
        {content === 'EDITOR' && (
          <Stack direction="row" alignItems="center" gap={2}>
            {isMobile ? (
              <IconButton
                icon="save_2_line"
                color="text"
                onClick={() => setOpenSimpleDialog(true)}
                tooltipArrow
                tooltipText={
                  !values.myEditor ? 'No hay contenido en la plantilla' : ''
                }
                tooltipPlacement="left"
                disabled={!values.myEditor}
              />
            ) : (
              <Button
                variant="outlined"
                color="default"
                size={isMobile ? 'small' : 'medium'}
                startIcon={<Icon icon="save_2_line" />}
                onClick={() => setOpenSimpleDialog(true)}
                tooltipArrow
                tooltipText={tooltipText(
                  values?.myEditor,
                  userHasPermissionToUpdate,
                )}
                tooltipPlacement="left"
                disabled={!values.myEditor || !userHasPermissionToUpdate}
              >
                Guardar como plantilla
              </Button>
            )}
            <PreviewTemplateButton html={values.myEditor} />
          </Stack>
        )}
      </Stack>
      <SimpleTemplateCreationDialog
        showDialog={openSimpleDialog}
        closeDialog={() => setOpenSimpleDialog(false)}
        template={{
          title: values.title,
          myEditor: values.myEditor,
          category: values.categories?.category,
          subcategory: values.categories?.subcategory,
        }}
      />
    </DialogTitle>
  );
};
