import { styled } from '@mui/system';
import { Avatar } from '../../newComponents/Avatar/Avatar';
import { StatusBadge } from '../../newComponents/Badge/StatusBadge';
import { Tooltip } from '../../newComponents/Tooltip';
import type { CompanySignerAvatarProps } from './CompanySignerAvatar.types';

const AvatarImage = styled('img')(() => ({
  margin: '0 auto',
  width: '100%',
  height: '100%',
  textAlign: 'center',
  objectFit: 'cover',
  textIndent: '10000px',
}));

export const CompanySignerAvatar = (props: CompanySignerAvatarProps) => {
  const {
    profilePicture,
    name = '',
    size = 'small',
    color = 'primary',
    showBadge = false,
  } = props;

  if (profilePicture) {
    return (
      <StatusBadge
        overlap="circular"
        status="compSigner"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        invisible={!showBadge}
      >
        <Avatar size={size}>
          <Tooltip title={name}>
            <AvatarImage src={profilePicture} alt={name} />
          </Tooltip>
        </Avatar>
      </StatusBadge>
    );
  }

  return (
    <StatusBadge
      overlap="circular"
      status="compSigner"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      invisible={!showBadge}
    >
      <Avatar size={size} color={color}>
        <Tooltip title={name}>
          <span>{name.charAt(0)}</span>
        </Tooltip>
      </Avatar>
    </StatusBadge>
  );
};
