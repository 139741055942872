// @ts-check
import { Avatar } from '../../newComponents/Avatar/Avatar';
import { WorkTitle as WorkTitleIcon } from '../../components/Icons/Custom';
import { AvatarSelectedHover } from '../../newComponents/AvatarSelectedHover';

/** @param {import('./WorkTitleAvatar.types').WorkTitleAvatarProps} props */
export const WorkTitleAvatar = (props) => {
  const { color, selected = false, ...avatarProps } = props;

  return (
    <Avatar
      size="medium"
      style={{ backgroundColor: 'transparent' }}
      {...avatarProps}
    >
      <WorkTitleIcon width="32px" height="32px" color={color} />
      {selected && <AvatarSelectedHover />}
    </Avatar>
  );
};
