// @ts-check
import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import { Icon } from '../../../components/Icons/Icons';
import { emailRegex } from '../../../utils/validators';
import { applySanitizers } from '../../../utils/sanitizers';
import { StyledInput } from './ControlledEmailInput.styles';

/** @type {import('./ControlledEmailInput.types').Rules} */
const defaultRules = {
  pattern: {
    value: emailRegex,
    message: 'Correo electrónico inválido',
  },
};

/** @param {import('./ControlledEmailInput.types').ControlledEmailInputProps} props */
export const ControlledEmailInput = ({
  control,
  rules = {},
  textFieldProps = {},
}) => {
  const name = 'email';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { ...defaultRules, ...rules },
  });
  const [showShrink, setShowShrink] = useState(value !== '');

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <StyledInput
      id={textFieldProps.id || name}
      value={value}
      onChange={(e) => {
        const inputValue = applySanitizers({
          text: e.target.value,
          sanitizers: ['removeSpacesBetweenLetters', 'toLowerCase', 'trim'],
        });
        onChange(inputValue);
      }}
      error={hasError}
      helperText={helperText}
      {...textFieldProps}
      InputLabelProps={{ shrink: value ? true : showShrink }}
      onFocus={() => setShowShrink(true)}
      onBlur={() => {
        onBlur();
        setShowShrink(value !== '');
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon icon="mail_line" color="grey" height="24px" />
          </InputAdornment>
        ),
      }}
    />
  );
};
