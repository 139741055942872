import { uuidv4 } from '../../../utils/utils';

export const mimeTypesImages = ['image/jpeg', 'image/jpg', 'image/png'];

export const handleErrors = (allFiles) => {
  const MAX_SIZE = 25 * 1024 * 1024;
  let largeFile = false;
  let invalidType = false;
  let emptyFile = false;
  let limitUpload = false;
  if (allFiles.length) {
    if (allFiles.length > 100) {
      limitUpload = true;
    }
    for (let i = 0; i < allFiles.length; i++) {
      const file = allFiles[i];
      if (
        file.errors?.length &&
        file?.errors[0]?.code === 'file-invalid-type'
      ) {
        invalidType = true;
      }
      if (file.size > MAX_SIZE) {
        largeFile = true;
      }
      if (file.size === 0) {
        emptyFile = true;
      }
    }
  }

  const hasErrors = largeFile || invalidType || emptyFile || limitUpload;

  return {
    hasErrors,
    multipleErrors: {
      largeFile,
      invalidType,
      emptyFile,
      limitUpload,
    },
  };
};

const buildEmployeeObject = (employee) => {
  return {
    _id: employee.id,
    id: employee.id,
    fullName: employee.fullName,
    profilePicture: employee.profilePicture,
    workCenterName: employee.workCenterName,
    rfc: employee.rfc,
    email: employee.email,
    curp: employee.curp,
    payrollId: employee.payrollId,
    type: 'employees',
  };
};

const statusAssignedTo = (file, employee) => {
  if (file?.assignedTo === false) {
    return false;
  }

  if (file?.assignedTo?.length) {
    return file.assignedTo;
  }

  if (employee) {
    return [buildEmployeeObject(employee)];
  }

  return [];
};

const signersConfigFn = (signersConfig) => {
  return {
    signInOrder: Boolean(signersConfig?.signInOrder),
    employeeSignsFirst: Boolean(signersConfig?.employeeSignsFirst) || true,
    companySignersSignInOrder: Boolean(
      signersConfig?.companySignersSignInOrder,
    ),
  };
};

export const mapAllFiles = (files, employee) => {
  if (!files?.length) return { modifiedFilesArr: [], objectFiles: [] };
  const objectFiles = {};
  const modifiedFilesArr = [];
  files.forEach((file) => {
    const uuid = file?.id || uuidv4();
    const obj = {
      id: uuid,
      _id: uuid,
      name: file.name,
      actions: file?.actions ? file?.actions : employee ? 'onlySave' : '',
      // actions: file?.actions ? file?.actions : '',
      sendAllEmployees: Boolean(file.sendAllEmployees) || false,
      assignedTo: statusAssignedTo(file, employee),
      signersConfig: signersConfigFn(file.signersConfig),
      categories: file?.categories || '',
      highPriority: file?.highPriority || false,
      hideAfterSignature: file?.hideAfterSignature || false,
      disabledInputs: Boolean(
        mimeTypesImages?.includes(file?.file?.type || file?.type),
      ),
      file: file?.file || file,
    };

    objectFiles[uuid] = obj;
    modifiedFilesArr.push(obj);
  });

  return { modifiedFilesArr, objectFiles };
};

export const removePropertyFromArray = (
  propertyName,
  array = [],
  addProperty,
) => {
  const response = array.map(({ [propertyName]: removedProperty, ...rest }) => {
    const modifiedObject = { ...rest };
    if (addProperty) {
      modifiedObject.addProperty = addProperty;
    }
    return modifiedObject;
  });
  return response;
};

export const cleanText = (str) => {
  if (!str) return '';
  const separatorRegExp = /[,; \n\t\r]/g;
  const textTrim = str.trim();
  // Replace the separator characters with dots
  const result = textTrim.replace(separatorRegExp, ',');

  // Return the modified string
  return result;
};

export const getError = (error) => {
  if (error === 'file-too-large') return 'El archivo supera los 25 MB';
  if (error === 'file-invalid-type') return 'No tiene formato PDF o imagen';
  if (error === 'file-too-small') return 'El archivo no tiene contenido';
  return 'Ha ocurrido un error intentando procesar el archivo';
};

export const getTotalSigners = (rows, totalCompanyEmployees) => {
  if (!Boolean(rows.length)) return 0;
  let totalSigners = 0;

  rows.forEach((row) => {
    if (row.type === 'ALL_EMPLOYEES') {
      totalSigners += totalCompanyEmployees;
    }
    if (row.type === 'workCenters') {
      totalSigners += row.employeeCount;
    }
    if (row.type === 'companySigners') {
      totalSigners += 1;
    }
    if (row.type === 'employees') {
      const alreadyIncluded = belongsToSelectedWorkCenters(rows, row);
      if (!alreadyIncluded) {
        totalSigners += 1;
      }
    }
  });

  return totalSigners;
};

export const belongsToSelectedWorkCenters = (rows, employee) => {
  if (!rows?.length) return false;
  const selectedWorkCenters = rows.filter((row) => row.type === 'workCenters');
  return selectedWorkCenters.some((wc) => wc._id === employee?.workCenterId);
};

export const buildCsvFile = (data, translationMap) => {
  const csvRows = [];
  const headers = [
    'names',
    'lastNameP',
    'lastNameM',
    'rfc',
    'curp',
    'filename',
    'message',
  ];
  csvRows.push(headers.map((header) => translationMap[header]).join(','));

  for (const row of data) {
    const values = headers.map((header) => {
      return JSON.stringify(row[header]) || '-';
    });
    csvRows.push(values.join(','));
  }

  const csvRowsNew = csvRows.join('\n');
  return csvRowsNew;
};

export const downloadCsvFile = (data, name) => {
  const blob = new Blob([data], {
    type: 'text/csv',
  });
  const file = window.URL.createObjectURL(blob);
  const domElement = document.createElement('A');
  domElement.setAttribute('href', file);
  domElement.setAttribute('download', `${name}.csv`);
  document.body.appendChild(domElement);
  domElement.click();
};
