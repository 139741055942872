import { useEffect, useState } from 'react';
import { HeaderUserList } from './UsersList/HeaderUserList';
import { SelectedUsers } from './UsersList/SelectedUsers';
import { TableUsers } from './UsersList/TableUsers';
import { getTotal } from '../CreateDocumentDialog.helpers';
import { Paper, Stack, useTheme } from '@mui/material';
import { GET_EMPLOYEES_BY_COLLECTIVE_COUNT } from '../../gql';
import { useQuery, useReactiveVar } from '@apollo/client';
import { SignersTabs } from '../../PdfDocuments/Signers/SignersTabs';
import {
  createDocumentSignersCounterVar,
  setGlobalTotalsVar,
} from '../CreateDocumentDialog.vars';
import type { TabItem } from '../CreateDocumentDialog.types';
import {
  getForcedIndexSigners,
  getTitleAndSubHeaderSigners,
} from './CreateDocumentUsersForm.helpers';

export const SignersList = ({
  users = [],
  errors = [],
  orderSignature,
  employeeSignFirst,
  setUsers,
  sendAll = false,
  companySignersSignInOrder,
  companySignersLength = 0,
  defaultEmployee = null,
}) => {
  const theme = useTheme();
  const signersCounter = useReactiveVar(createDocumentSignersCounterVar);
  const [loadedRowCount, setLoadedRowCount] = useState(20);
  const [selectedSigners, setSelectedSigners] = useState([]);
  const [tab, setTab] = useState<TabItem>('success');

  const { data } = useQuery(GET_EMPLOYEES_BY_COLLECTIVE_COUNT, {
    variables: {
      input: {
        getAllEmployeesTotal: true,
      },
    },
  });

  const handleLoadMore = () => {
    setLoadedRowCount((prevRowCount) => prevRowCount + 20);
  };

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  const allEmployeesTotal = data?.getEmployeesByCollectiveCount ?? 0;
  const total = getTotal({ users, includeCompanySigners: orderSignature });
  const { title, subHeader } = getTitleAndSubHeaderSigners({
    employeeSignFirst,
    orderSignature,
  });

  const forcedIndex = getForcedIndexSigners({
    employeeSignFirst,
    orderSignature,
    companySignersSignInOrder,
    signersCounter,
  });

  const indexOrder = employeeSignFirst && orderSignature ? 1 : 2;

  useEffect(() => {
    if (!errors?.length) {
      setTab('success');
    }
  }, [errors]);

  useEffect(() => {
    setGlobalTotalsVar({ employees: sendAll ? allEmployeesTotal : total });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total, sendAll]);

  return (
    <Stack component={Paper} sx={{ boxShadow: theme.newPalette.shadow.card }}>
      <HeaderUserList
        avatarIcon="shuffle_line"
        title={title}
        subHeader={subHeader}
        total={sendAll ? allEmployeesTotal : total + companySignersLength}
        color={orderSignature ? 'primary' : 'default'}
        orderSignature={orderSignature}
        indexOrder={indexOrder}
      />
      <SignersTabs
        signersError={errors}
        signersList={users}
        tab={tab}
        handleChange={handleChangeTab}
      />
      <SelectedUsers
        users={tab === 'errors' ? errors : users}
        setUsers={setUsers}
        selected={selectedSigners}
        setSelected={setSelectedSigners}
        tab={tab}
        defaultEmployee={defaultEmployee}
      />
      <TableUsers
        users={tab === 'errors' ? errors : users}
        setUsers={setUsers}
        loadedRowCount={loadedRowCount}
        handleLoadMore={handleLoadMore}
        selected={selectedSigners}
        setSelected={setSelectedSigners}
        color={orderSignature ? 'primary' : 'default'}
        forcedIndex={forcedIndex}
        orderSignature={orderSignature}
        disabled
        defaultEmployee={defaultEmployee}
      />
    </Stack>
  );
};
