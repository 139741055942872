// @ts-check
import { ButtonGroup as MuiButtonGroup } from '@mui/material';

/**
 * @typedef {import('@mui/material/ButtonGroup').ButtonGroupProps} ButtonGroupProps
 * @typedef CustomProps
 * @property {'contained'|'outlined'|'text'|'soft'} [variant]
 * @property {'default'|'primary'|'secondary'|'info'|'success'|'warning'|'error'} [color]
 */

/**
 * @param {Omit<ButtonGroupProps,"variant"|"color"> & CustomProps} props
 */
export const ButtonGroup = (props) => {
  // @ts-ignore
  return <MuiButtonGroup {...props} />;
};
