//@ts-check
import { ListItem } from '../../../../../../newComponents/ListItem';
import { ListItemIcon } from '../../../../../../newComponents/ListItemIcon';
import { Checkbox } from '../../../../../../newComponents/Checkbox';
import { Typography } from '../../../../../../newComponents/Typography';
import { EmployeeAvatar } from '../../../../../../businessComponents/EmployeeAvatar';
import { Stack } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { templateItemVar } from '../Templates.vars';
import { Label } from '../../../../../../newComponents/Label';

export const AuthorizerOverlay = ({ template }) => {
  const templateItem = useReactiveVar(templateItemVar);
  const user = template?.user;
  const name = `${user?.names} ${user?.lastNameP} ${user?.lastNameM || ''}`;

  const authorizers = templateItem?.authorizers ?? [];
  const selectedAuths = templateItem?.selectedAuthorizers ?? [];
  const isChecked = selectedAuths.some((auth) => auth?._id === template?._id);
  const index = authorizers.findIndex((auth) => auth?._id === template?._id);

  return (
    <ListItem
      sx={{
        background: (theme) => theme.palette.background.paper,
        boxShadow: (theme) => theme.shadows[24],
        cursor: 'grabbing',
        height: 68,
      }}
    >
      <ListItemIcon>
        <Checkbox color="primary" checked={Boolean(isChecked)} />
      </ListItemIcon>
      <Stack direction="row" alignItems="center" spacing={2}>
        {templateItem?.isOrdered ? (
          <Label variant="outlined" color="default" label={index + 1} />
        ) : null}
        <EmployeeAvatar
          name={name}
          profilePicture={user?.profilePicture}
          size="medium"
        />
        <Typography variant="subtitle2">{name}</Typography>
      </Stack>
    </ListItem>
  );
};
