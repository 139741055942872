//@ts-check
import { ConfirmDialog } from '../../../../../../newComponents/Dialog';
import { useReactiveVar } from '@apollo/client';
import {
  confirmAuthorizersDialogVar,
  resetConfirmAuthorizersDialogVar,
} from '../Templates.vars';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../../newComponents/Typography';

export const ConfirmAuthorizersDialog = ({ onConfirm }) => {
  const { open } = useReactiveVar(confirmAuthorizersDialogVar);

  const handleClose = () => {
    resetConfirmAuthorizersDialogVar();
  };

  return (
    <ConfirmDialog
      open={open}
      onClose={() => handleClose()}
      confirmLabel="Continuar sin autorizadores"
      cancelLabel="Cancelar"
      onCancel={() => handleClose()}
      onConfirm={onConfirm}
      title="Atención"
      confirmButtonToRight
      handleCloseIconButton
      DialogProps={{
        fullScreen: false,
      }}
    >
      <Stack>
        <Typography variant="body1" color="text.secondary">
          No tienes ningún autorizador
        </Typography>
        <Typography variant="body1" color="text.secondary">
          ¿Quieres continuar sin asignar autorizadores?
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
};
