//@ts-check
import { format, formatDistanceToNowStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import { TimelineItem } from '../../../../../../../newComponents/TimelineItem';
import { TimelineSeparator } from '../../../../../../../newComponents/TimelineSeparator';
import { TimelineDot } from '../../../../../../../newComponents/TimelineDot';
import { Icon } from '../../../../../../../components/Icons/Icons';
import { TimelineConnector } from '../../../../../../../newComponents/TimelineConnector';
import { TimelineContent } from '../../../../../../../newComponents/TimelineContent';
import { Typography } from '../../../../../../../newComponents/Typography';
import { Stack } from '@mui/material';
import { TEMPLATE_ACTIONS } from '../../Templates.constants';

export const HistoryTemplateItem = ({
  action,
  comments,
  createdBy,
  timestamps,
}) => {
  const name = createdBy ? `${createdBy?.names} ${createdBy?.lastNameP}` : null;
  const date = timestamps?.createdAt ? new Date(timestamps?.createdAt) : null;

  let time = null;
  let formatDate = null;
  if (date) {
    time = formatDistanceToNowStrict(date, {
      locale: es,
    });
    formatDate = format(date, 'dd MMM yyyy', { locale: es });
  }

  if (action === 'UPDATED_AND_READY_TO_PUBLISH') {
    let message = 'Se actualizó la plantilla y está lista para publicar';
    if (createdBy) {
      message = `${name}: Actualizó la plantilla y está lista para publicar`;
    }
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="filled">
            <Icon icon="user_line" />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="subtitle2">{message}</Typography>
          <Stack spacing={1}>
            <Typography variant="caption" color="text.secondary">
              Actualizado hace {time}
            </Typography>
          </Stack>
        </TimelineContent>
      </TimelineItem>
    );
  }

  if (TEMPLATE_ACTIONS.includes(action)) {
    let message = 'Se envió la plantilla para autorización';
    if (createdBy) {
      message = `${name}: Envió plantilla para autorización`;
    }
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="filled">
            <Icon icon="user_line" />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="subtitle2">{message}</Typography>
          <Stack spacing={1}>
            <Typography variant="caption" color="text.secondary">
              Enviado hace {time}
            </Typography>
          </Stack>
        </TimelineContent>
      </TimelineItem>
    );
  }

  if (action === 'UPDATED') {
    let message = 'Se actualizó la plantilla';
    if (createdBy) {
      message = `${name} actualizó la plantilla`;
    }
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="primary" variant="filled">
            <Icon icon="user_line" />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="subtitle2">{message}</Typography>
          <Stack spacing={1}>
            <Typography variant="caption" color="text.secondary">
              Actualizada hace {time}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {comments}
            </Typography>
          </Stack>
        </TimelineContent>
      </TimelineItem>
    );
  }

  if (action === 'REJECTED') {
    let message = 'Se rechazó la plantilla';
    if (createdBy) {
      message = `${name}: Rechazó la plantilla`;
    }
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="error" variant="filled">
            <Icon icon="close_circle_line" />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="subtitle2">{message}</Typography>
          <Stack spacing={1}>
            <Typography variant="caption" color="text.secondary">
              Hace {time}
            </Typography>
            <Typography variant="caption" color="error">
              Motivo: {comments}
            </Typography>
          </Stack>
        </TimelineContent>
      </TimelineItem>
    );
  }

  if (action === 'APPROVED') {
    let message = 'Se aprobó la plantilla';
    if (createdBy) {
      message = `${name}: Aprobó la plantilla`;
    }
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="success" variant="filled">
            <Icon icon="checkbox_circle_line" fill="white" />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="subtitle2">{message}</Typography>
          <Stack spacing={1}>
            <Typography variant="caption" color="text.secondary">
              Hace {time}
            </Typography>
          </Stack>
        </TimelineContent>
      </TimelineItem>
    );
  }

  if (action === 'READY_TO_PUBLISH') {
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="success" variant="filled">
            <Icon icon="checkbox_circle_line" fill="white" />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="subtitle2">
            La plantilla está lista para publicar
          </Typography>
          <Stack spacing={1}>
            <Typography variant="caption" color="text.secondary">
              Hace {time}
            </Typography>
          </Stack>
        </TimelineContent>
      </TimelineItem>
    );
  }

  if (action === 'PUBLISHED') {
    let message = 'Se publicó la plantilla';
    if (createdBy) {
      message = `${name}: Publicó la plantilla`;
    }
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="success" variant="filled">
            <Icon icon="checkbox_circle_line" fill="white" />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="subtitle2">{message}</Typography>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {formatDate}
            </Typography>
            <Stack>
              <Typography variant="caption" color="text.secondary">
                {comments ? 'Resumen de cambios:' : ''}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {comments}
              </Typography>
            </Stack>
          </Stack>
        </TimelineContent>
      </TimelineItem>
    );
  }

  return null;
};
