// @ts-check
import React, { memo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Stack } from '@mui/material';
import { Label } from '../../../../../../newComponents/Label';
import { AuthorizerTableRow, AuthorizersTableCell } from '../Templates.styles';
import { Checkbox } from '../../../../../../newComponents/Checkbox';
import { useReactiveVar } from '@apollo/client';
import { setTemplateItemVar, templateItemVar } from '../Templates.vars';
import { EmployeeAvatar } from '../../../../../../businessComponents/EmployeeAvatar';
import { Typography } from '../../../../../../newComponents/Typography';
import { IconButton } from '../../../../../../newComponents/IconButton';
import { Chip } from '../../../../../../newComponents/Chip';

const getCursor = ({ templateItem, disabledItem }) => {
  if (disabledItem && templateItem?.isOrdered) {
    return 'not-allowed';
  }

  if (!disabledItem && templateItem?.isOrdered) {
    return 'grab';
  }

  return 'auto';
};

export const TableAuthorizersRow = memo((props) => {
  const templateItem = useReactiveVar(templateItemVar);

  //@ts-ignore
  const { style, index, data } = props;
  const row = data[index];
  const status = row?.status;
  const workSpaceStatus = row?.user?.workSpaceStatus;

  const disabledItem = status === 'AUTHORIZED';
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: row?._id,
    data: row,
    disabled: disabledItem || !templateItem?.isOrdered,
  });

  const cursor = getCursor({ templateItem, disabledItem });
  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: cursor,
  };

  const onCheckboxChange = (isChecked) => {
    const currSelected = templateItem?.selectedAuthorizers ?? [];
    if (isChecked) {
      setTemplateItemVar({ selectedAuthorizers: [...currSelected, row] });
      return;
    }

    const filteredItems = currSelected.filter((auth) => auth?._id !== row?._id);

    setTemplateItemVar({ selectedAuthorizers: filteredItems });
  };

  const removeItem = (item) => {
    if (!item) return;
    const currAuthorizers = templateItem?.authorizers ?? [];
    const currSelected = templateItem?.selectedAuthorizers ?? [];

    const filteredAuths = currAuthorizers.filter(
      (auth) => auth?._id !== item?._id,
    );

    if (currSelected?.length) {
      const filteredSelected = currSelected.filter(
        (auth) => auth?._id !== item?._id,
      );

      setTemplateItemVar({
        authorizers: filteredAuths,
        selectedAuthorizers: filteredSelected,
      });
      return;
    }

    setTemplateItemVar({ authorizers: filteredAuths });
  };

  const user = row?.user;
  const name = user?.fullName || '';
  const isSelected = templateItem?.selectedAuthorizers?.some(
    (auth) => auth?._id === row?._id,
  );

  return (
    <AuthorizerTableRow key={row?._id} sx={{ ...style, alignItems: 'center' }}>
      <Stack
        ref={setNodeRef}
        style={itemStyle}
        {...attributes}
        {...listeners}
        direction="row"
        alignItems="center"
        width="100%"
        sx={{
          visibility: isDragging ? 'hidden' : 'visible',
          '&:hover .MuiButton-root': { visibility: 'visible' },
          '&:hover .MuiIconButton-root': { visibility: 'visible' },
        }}
      >
        <AuthorizersTableCell>
          <Checkbox
            color="primary"
            checked={isSelected}
            onChange={(e) => onCheckboxChange(e.target.checked)}
          />
        </AuthorizersTableCell>
        <AuthorizersTableCell
          sx={{
            flex: 1,
            // minWidth: 250,
            paddingLeft: 1,
            width: '100%',
            opacity:
              (disabledItem && templateItem?.isOrdered) ||
              workSpaceStatus === 'BAJA'
                ? 0.48
                : 1,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2} width="100%">
            {templateItem?.isOrdered ? (
              <Label variant="outlined" color="default" label={index + 1} />
            ) : null}

            <Stack width="100%" direction="row" alignItems="center" spacing={2}>
              <EmployeeAvatar
                name={name}
                profilePicture={user?.profilePicture || row?.profilePicture}
                size="medium"
              />
              <Stack direction="column" alignItems="flex-start" flex="1 0 0">
                <Typography variant="subtitle2">{name}</Typography>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {workSpaceStatus === 'BAJA' ? (
                    <Chip
                      variant="soft"
                      color="error"
                      label="Baja"
                      size="small"
                    />
                  ) : null}
                  {templateItem?.isOrdered && status === 'AUTHORIZED' ? (
                    <Chip
                      variant="soft"
                      color="success"
                      label="Autorizado"
                      size="small"
                    />
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </AuthorizersTableCell>

        <AuthorizersTableCell
          sx={{
            minWidth: '50px',
            textAlign: 'right',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            icon="delete_bin_5_line"
            color="error"
            sx={{ visibility: 'hidden' }}
            onClick={() => removeItem(row)}
          />
        </AuthorizersTableCell>
      </Stack>
    </AuthorizerTableRow>
  );
});
