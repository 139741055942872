//@ts-check
import { useReactiveVar } from '@apollo/client';
import { usePermissionChecker, useScreenSize } from '../../../../../../Hooks';
import { userVar } from '../../../../../../cache.reactiveVars';
import { Label } from '../../../../../../newComponents/Label';
import { Button } from '../../../../../../newComponents/Button';
import { Icon } from '../../../../../../components/Icons/Icons';
import { helperMessage } from '../Templates.helpers';

export const ApproveOrRejectTemplateActions = ({
  template,
  rejectTemplate,
  approveTemplate,
}) => {
  const { isMobile } = useScreenSize();
  const user = useReactiveVar(userVar);
  const userHasPermissionToAuthorize = usePermissionChecker({
    permission: 'authorizeTemplates',
    action: 'update',
  });
  const isOrdered = Boolean(template?.orderConfig?.authorizeInOrder);

  const authorizations = template?.authorizers || [];

  const currAuthorizer = authorizations?.find(
    (auth) => auth?.user?._id === user?._id,
  );

  const buttonProps = helperMessage({
    userHasPermissionToAuthorize,
    isOrdered,
    currAuthorizer,
    orderConfig: template?.orderConfig,
  });

  if (!authorizations?.length || !currAuthorizer) {
    return null;
  }

  if (template?.status === 'REJECTED') {
    return <Label label="Plantilla rechazada" color="error" variant="filled" />;
  }

  if (isOrdered && currAuthorizer && currAuthorizer?.status === 'AUTHORIZED') {
    return null;
  }

  return (
    <>
      <Button
        variant="soft"
        color="error"
        startIcon={<Icon icon="close_circle_line" />}
        onClick={rejectTemplate}
        disabled={buttonProps.disabled}
        tooltipText={buttonProps.message}
        tooltipArrow
        tooltipPlacement="top"
        fullWidth={isMobile}
        tooltipStyle={{ width: '100%' }}
      >
        Rechazar
      </Button>
      <Button
        variant="soft"
        color="success"
        startIcon={<Icon icon="checkbox_circle_line" />}
        onClick={approveTemplate}
        disabled={buttonProps.disabled}
        tooltipText={buttonProps.message}
        tooltipArrow
        tooltipPlacement="top"
        fullWidth={isMobile}
        tooltipStyle={{ width: '100%' }}
      >
        Aprobar
      </Button>
    </>
  );
};
