//@ts-check
import { Stack } from '@mui/material';
import { useState } from 'react';
import { FormControlLabel } from '../../../../../../newComponents/FormControlLabel';
import { Switch } from '../../../../../../newComponents/Switch';
import { setTemplateItemVar, templateItemVar } from '../Templates.vars';
import { useReactiveVar } from '@apollo/client';

export const OrderAuthorizersComponent = () => {
  const templateItem = useReactiveVar(templateItemVar);
  const [isOrdered, setIsOrdered] = useState(Boolean(templateItem?.isOrdered));

  const orderHandleChange = () => {
    setIsOrdered(!isOrdered);
    setTemplateItemVar({
      isOrdered: !isOrdered,
    });
  };

  return (
    <Stack width="100%" alignItems="center" justifyContent="center">
      <FormControlLabel
        value={isOrdered}
        control={<Switch color="primary" checked={isOrdered} />}
        label="Activar orden de autorizado"
        labelPlacement="end"
        onChange={orderHandleChange}
      />
    </Stack>
  );
};
