//@ts-check
import { TemplatesActions } from './TemplatesActions';
import { Stack } from '@mui/material';
import { Document as DocumentName } from '../../../../../../components/Icons/Custom';
import { Typography } from '../../../../../../newComponents/Typography';
import { Label } from '../../../../../../newComponents/Label';
import { HistoricalComponent } from './HistoryTemplate/HistoricalComponent';
import { Tooltip } from '../../../../../../newComponents/Tooltip';
import { EmployeeAvatar } from '../../../../../../businessComponents/EmployeeAvatar';
import { AvatarGroupComponent } from './AvatarGroupComponent';
import { globalStatusAuthorizers } from '../Templates.constants';

const TEMPLATE_ACTIONS = ({ width = 60 }) => ({
  field: 'actions',
  headerName: '',
  hideable: true,
  width: width,
  sortable: false,
  align: 'center',
  renderCell: (params) => {
    const { row } = params;
    return <TemplatesActions template={row} />;
  },
});

const TEMPLATE_NAME = ({ width = 350, handlePreviewTemplateDialog }) => ({
  field: 'templateName',
  headerName: 'Nombre de la plantilla',
  hideable: true,
  width: width,
  sortable: false,
  renderCell: (params) => {
    const { row } = params;
    const { title, color } = row;
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        onClick={() => handlePreviewTemplateDialog(row)}
        width="100%"
        sx={{ cursor: 'pointer' }}
      >
        <DocumentName
          svgProps={{ width: '32px' }}
          primaryColor={color.primary}
          secondaryColor={color.secondary}
        />
        <Typography variant="body2">{title}</Typography>
      </Stack>
    );
  },
});

const TEMPLATE_STATUS = ({ width = 250 }) => ({
  field: 'status',
  headerName: 'Estatus',
  hideable: true,
  width: width,
  sortable: false,
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    const { row } = params;
    const infoStatus = globalStatusAuthorizers[row.status];
    return (
      <Label
        variant="soft"
        color={infoStatus?.color}
        label={infoStatus?.text || ''}
        startIcon={infoStatus?.icon}
      />
    );
  },
});

const TEMPLATE_CREATED_BY = ({ width = 200 }) => ({
  field: 'createdBy',
  headerName: 'Creador',
  hideable: true,
  sortable: false,
  headerAlign: 'center',
  align: 'center',
  width: width,
  renderCell: (params) => {
    const { row } = params;
    const fullName = row?.createdBy?.fullName;
    return (
      <Tooltip title={fullName} placement="top">
        <span>
          <EmployeeAvatar
            profilePicture={row?.createdBy?.profilePicture}
            name={fullName}
            size="tiny"
          />
        </span>
      </Tooltip>
    );
  },
});

const TEMPLATE_AUTHORIZERS = ({ width = 250 }) => ({
  field: 'TEMPLATE_',
  headerName: 'Autorizadores',
  hideable: true,
  width: width,
  sortable: false,
  headerAlign: 'center',
  align: 'center',
  renderCell: (params) => {
    return <AvatarGroupComponent row={params.row} />;
  },
});

const TEMPLATE_HISTORY = ({ width = 200, headerName = '' }) => ({
  field: 'historical',
  headerName: headerName,
  hideable: true,
  sortable: false,
  headerAlign: 'center',
  align: 'center',
  width: width,
  renderCell: (params) => {
    const { row } = params;
    return <HistoricalComponent template={row} />;
  },
});

/**
 * @param {object} params
 * @param {Function} params.handlePreviewTemplateDialog
 * @returns {import('../Templates.types').CustomGridColDef[]}
 */
export const TEMPLATES_TABLE_COLUMNS = ({ handlePreviewTemplateDialog }) => [
  TEMPLATE_ACTIONS({ width: 60 }),
  TEMPLATE_NAME({ width: 350, handlePreviewTemplateDialog }),
  TEMPLATE_STATUS({ width: 250 }),
  TEMPLATE_AUTHORIZERS({ width: 250 }),
  TEMPLATE_HISTORY({ width: 200, headerName: 'Historial' }),
  TEMPLATE_CREATED_BY({ width: 200 }),
];

/**
 * @param {object} params
 * @param {Function} params.handlePreviewTemplateDialog
 * @returns {import('../Templates.types').CustomGridColDef[]}
 */
export const TEMPLATES_TABLE_COLUMNS_PUBLISHED = ({
  handlePreviewTemplateDialog,
}) => [
  TEMPLATE_ACTIONS({ width: 60 }),
  TEMPLATE_NAME({ width: 600, handlePreviewTemplateDialog }),
  TEMPLATE_CREATED_BY({ width: 200 }),
  TEMPLATE_AUTHORIZERS({ width: 250 }),
  TEMPLATE_HISTORY({ width: 200, headerName: 'Versión' }),
];

/**
 * @param {object} params
 * @param {Function} params.handlePreviewTemplateDialog
 * @returns {import('../Templates.types').CustomGridColDef[]}
 */
export const TEMPLATES_TABLE_SIMPLE_COLUMNS = ({
  handlePreviewTemplateDialog,
}) => [
  TEMPLATE_NAME({ width: 800, handlePreviewTemplateDialog }),
  TEMPLATE_STATUS({ width: 350 }),
  TEMPLATE_CREATED_BY({ width: 300 }),
];
