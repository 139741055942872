import { InputAdornment, Stack } from '@mui/material';
import { Fragment, useState } from 'react';
import { Icon } from '../../../../../components/Icons/Icons';
import { Typography } from '../../../../../newComponents/Typography';
import { Divider } from '../../../../Landing/Development/Components/Divider/Divider';
import { useScreenSize } from '../../../../../Hooks';
import { useReactiveVar } from '@apollo/client';
import { TextInput } from '../../../../../newComponents/TextInput';
import { EmployeeRecordTag } from '../../../../../layoutComponents/EmployeeRecordTag/EmployeeRecordTag';
import type { CreateDocumentSettingsFormProps } from '../CreateDocumentDialog.types';
import { currentCompanyVar } from '../../../../../cache.reactiveVars';
import { ValiditySelector } from '../../Common/ValiditySelector';
import { FormControlLabel } from '../../../../../newComponents/FormControlLabel';
import { Switch } from '../../../../../newComponents/Switch';
import { Controller } from 'react-hook-form';
import { globalTotalsVar } from '../CreateDocumentDialog.vars';
import { PreviewTemplateButton } from '../../../../../businessComponents/PreviewTemplateButton';

export const CreateDocumentSettingsForm = (
  props: CreateDocumentSettingsFormProps,
) => {
  const { isMobile } = useScreenSize();
  const company = useReactiveVar(currentCompanyVar);
  const globalTotal = useReactiveVar(globalTotalsVar);

  const [validity, setValidity] = useState(null);

  const employeeRecordsTags = company.configs?.employeeRecordsTags ?? [];
  const { form } = props;
  const [categories, watchSignable, title, errors, scheduledAt, myEditor] =
    form.watch([
      'categories',
      'signable',
      'title',
      'verifyErrors',
      'scheduledFor',
      'myEditor',
    ]);

  const categoryFound = employeeRecordsTags?.find(
    (tag) => tag?.category === categories?.category,
  );

  const verifyIfIsSignable = (isSignable: boolean) => {
    if (!isSignable) {
      form.setValue('hideable', false);
      form.setValue('highPriority', false);
    }
  };

  const handleSetValidity = (value: Date | null) => {
    setValidity(value);
    form.setValue('validity', value ? value : null);
  };

  const handleScheduledFor = (value: Date | null) => {
    form.setValue('scheduledFor', value ? value : null);
  };

  return (
    <Fragment>
      <Stack width="100%" spacing={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Icon icon="ic_document" width="40px" height="60px" />
          <PreviewTemplateButton html={myEditor} />
        </Stack>
        <Typography variant="h6">{title}</Typography>
      </Stack>
      <Divider />
      <TextInput
        label="Tipo de documento"
        variant="outlined"
        fullWidth
        size={isMobile ? 'small' : 'medium'}
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <EmployeeRecordTag
                employeeRecordTag={{
                  category: categories.category,
                  subcategory: categories.subcategory,
                  color: categoryFound?.color,
                }}
                columnWidth={400}
              />
            </InputAdornment>
          ),
        }}
        disabled
      />
      <Stack width="100%" spacing={2}>
        <Typography variant="subtitle2">Configuraciones</Typography>
        <Stack direction="row" alignItems="center" spacing={5} width="100%">
          <Stack width="25%">
            <Typography variant="caption" color="text.secondary">
              Vigencia
            </Typography>
          </Stack>
          <ValiditySelector
            fullWidth
            value={validity}
            setValue={handleSetValidity}
            label=""
            undefinedLabel="Vigencia"
            hideIcon
            size={isMobile ? 'small' : 'medium'}
          />
        </Stack>
        {scheduledAt && (
          <Stack direction="row" alignItems="center" spacing={5} width="100%">
            <Stack width="25%">
              <Typography variant="caption" color="text.secondary">
                Envió programado
              </Typography>
            </Stack>
            <ValiditySelector
              fullWidth
              value={scheduledAt}
              setValue={handleScheduledFor}
              label=""
              undefinedLabel="Programado para"
              dateFormat="d MMM yyyy h:mm"
              hideIcon
              size={isMobile ? 'small' : 'medium'}
            />
          </Stack>
        )}
        <Stack width="100%" spacing={1.5}>
          <Controller
            name="signable"
            control={form.control}
            render={({ field: { onChange, value, ...rest } }) => {
              return (
                <Stack>
                  <FormControlLabel
                    value={value}
                    onChange={() => {
                      onChange(!value);
                      verifyIfIsSignable(!value);
                    }}
                    control={<Switch color="primary" checked={value} />}
                    label={
                      <Typography variant="body2">Solicitar firma</Typography>
                    }
                    labelPlacement="end"
                  />
                </Stack>
              );
            }}
          />
          <Controller
            name="hideable"
            control={form.control}
            render={({ field: { onChange, value } }) => {
              return (
                <Stack>
                  <FormControlLabel
                    value={value}
                    onChange={onChange}
                    control={
                      <Switch
                        color="primary"
                        checked={value}
                        disabled={!watchSignable}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Ocultar tras firma del empleado
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <Typography variant="caption" color="text.secondary">
                    Al activar esta opción este documento se ocultará únicamente
                    al empleado.
                  </Typography>
                </Stack>
              );
            }}
          />
          <Controller
            name="highPriority"
            control={form.control}
            render={({ field: { onChange, value, ...rest } }) => {
              return (
                <Stack>
                  <FormControlLabel
                    value={value}
                    onChange={onChange}
                    control={
                      <Switch
                        color="primary"
                        checked={value}
                        disabled={!watchSignable}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Documento de alta prioridad
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <Typography variant="caption" color="text.secondary">
                    Este documento aparecerá tras inicio de sesión del empleado
                    y solo podrá continuar hasta que lo haya firmado.
                  </Typography>
                </Stack>
              );
            }}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack spacing={1} width="100%">
        <Typography variant="subtitle2">Usuarios asignados</Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack width="50%">
            <Typography variant="caption" color="text.secondary">
              Empleados
            </Typography>
          </Stack>
          <Stack width="50%">
            <Typography variant="caption">
              {(globalTotal.employees ?? 0) - (errors?.length ?? 0)}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack width="50%">
            <Typography variant="caption" color="text.secondary">
              Firmantes de empresa
            </Typography>
          </Stack>
          <Stack width="50%">
            <Typography variant="caption">
              {globalTotal?.companySigners ?? 0}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Fragment>
  );
};
