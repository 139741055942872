//@ts-check
import { ConfirmDialog } from '../../../../../../newComponents/Dialog';
import {
  approveTemplateDialogVar,
  resetApproveTemplateDialogVar,
  resetOpenPreviewTemplateDialogVar,
  updateTemplatesVar,
} from '../Templates.vars';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../../newComponents/Typography';
import { APPROVE_TEMPLATE } from '../Templates.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../../../../cache.reactiveVars';

export const AuthorizeTemplateDialog = () => {
  const { open, templateId } = useReactiveVar(approveTemplateDialogVar);
  const user = useReactiveVar(userVar);
  const [approveTemplate] = useMutation(APPROVE_TEMPLATE);

  const handleCloseAll = () => {
    resetApproveTemplateDialogVar();
    resetOpenPreviewTemplateDialogVar();
  };

  const handleClose = () => {
    resetApproveTemplateDialogVar();
  };

  const approveTemplateFn = async () => {
    if (!user?._id || !templateId) {
      return globalSnackbarVar({
        show: true,
        message: 'Faltan datos para rechazar la plantilla.',
        severity: 'error',
        duration: 5000,
      });
    }

    try {
      globalBackdropVar({ open: true, text: 'Aprobando plantilla...' });
      const response = await approveTemplate({
        variables: {
          input: {
            userId: user?._id,
            templateId,
          },
        },
      });

      const data = response?.data?.approveTemplate;

      if (data?.__typename === 'ErrorToApproveTemplate') {
        globalSnackbarVar({
          show: true,
          message: data?.message,
          severity: 'error',
          duration: 5000,
        });

        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Plantilla aprobada con éxito',
        severity: 'success',
        duration: 5000,
      });

      updateTemplatesVar(!updateTemplatesVar());
      handleCloseAll();
    } catch (error) {
      console.log(error);
      globalSnackbarVar({
        show: true,
        message: 'Error al rechazar la aprobar. Contacta a SORA',
        severity: 'error',
        duration: 5000,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return (
    <ConfirmDialog
      open={open}
      onClose={() => handleClose()}
      confirmLabel="Autorizar plantilla"
      cancelLabel="Cancelar"
      onCancel={() => handleClose()}
      onConfirm={approveTemplateFn}
      title="Autorizar plantilla"
      handleCloseIconButton
      DialogProps={{
        fullScreen: false,
        PaperProps: { sx: { width: 370, borderRadius: 2 } },
      }}
      confirmButtonToRight
    >
      <Stack spacing={2} width="100%">
        <Typography variant="body1" color="text.secondary">
          ¿Estas seguro que quieres autorizar el uso de esta plantilla?
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
};
