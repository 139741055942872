//@ts-check
import { ListItem } from '../../../../../../../newComponents/ListItem';
import { Label } from '../../../../../../../newComponents/Label';
import { Stack } from '@mui/material';
import { EmployeeAvatar } from '../../../../../../../businessComponents/EmployeeAvatar';
import { Typography } from '../../../../../../../newComponents/Typography';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { authorizationStatus } from '../../Templates.constants';

const getDate = (date) => {
  if (!date) return '';
  return format(new Date(date), 'dd MMM yyyy hh:mm a', {
    locale: es,
  });
};

export const ListAuthorizersStatus = ({ authorizations = [], isOrdered }) => {
  return (
    <>
      {authorizations?.map((auth, index) => {
        const user = auth?.user;
        const fullName = user?.fullName || '';
        const status = auth?.status;
        const mapStatus = authorizationStatus[status];
        let updatedAt = auth?.timestamps?.updatedAt;
        const rejectedAt = auth?.timestamps?.rejectedAt;
        const approvedAt = auth?.timestamps?.approvedAt;

        if (status === 'AUTHORIZED' && approvedAt) {
          updatedAt = approvedAt;
        }

        if (status === 'REJECTED' && rejectedAt) {
          updatedAt = approvedAt;
        }

        return (
          <ListItem key={index} sx={{ height: 68, gap: 2, width: '100%' }}>
            {isOrdered ? (
              <Label label={index + 1} color="default" variant="outlined" />
            ) : null}
            <Stack direction="row" alignItems="center" width="100%" spacing={2}>
              <EmployeeAvatar
                name={fullName}
                profilePicture={auth.profilePicture}
                size="medium"
              />
              <Stack>
                <Typography variant="subtitle2">{fullName}</Typography>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="body2" color={mapStatus?.colorText}>
                    {mapStatus?.text || ''}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {status === 'AUTHORIZED' || status === 'REJECTED'
                      ? getDate(updatedAt)
                      : null}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </ListItem>
        );
      })}
    </>
  );
};
