import { CardHeader, useTheme } from '@mui/material';
import { Avatar } from '../../../../../../newComponents/Avatar';
import { Icon } from '../../../../../../components/Icons/Icons';
import { Typography } from '../../../../../../newComponents/Typography';
import { Chip } from '../../../../../../newComponents/Chip';
import type { HeaderUserListProps } from '../../CreateDocumentDialog.types';

export const HeaderUserList = (props: HeaderUserListProps) => {
  const {
    avatarIcon,
    title = '',
    subHeader = '',
    total = 0,
    color,
    orderSignature,
    indexOrder,
  } = props;
  const theme = useTheme();
  let colorLight = theme.newPalette.primary.lighter;
  let mainColor = theme.newPalette.primary.main;

  if (color !== 'default') {
    colorLight = theme.newPalette[color]?.lighter;
    mainColor = theme.newPalette[color]?.main;
  }

  if (color === 'default') {
    colorLight = theme.newPalette.grey.transparent24;
    mainColor = theme.newPalette.text.secondary;
  }

  return (
    <CardHeader
      sx={{
        '&.MuiCardHeader-root': {
          py: 3,
          pl: 3,
          pr: 2,
          '& .MuiCardHeader-action': {
            margin: 0,
            alignSelf: 'auto',
          },
          borderBottom: `1px solid ${theme.newPalette.grey.transparent24}`,
        },
      }}
      title={<Typography variant="subtitle2">{title}</Typography>}
      subheader={
        <Typography variant="body2" color="text.secondary">
          {subHeader}
        </Typography>
      }
      avatar={
        <Avatar
          sx={{
            '&.MuiAvatar-colorDefault': {
              backgroundColor: colorLight,
            },
          }}
        >
          {orderSignature ? (
            <Typography variant="h4" color={mainColor}>
              {indexOrder}
            </Typography>
          ) : (
            <Icon icon={avatarIcon} width="24px" fill={mainColor} />
          )}
        </Avatar>
      }
      action={
        <Chip
          label={`${total} firmantes`}
          variant="soft"
          size="small"
          color={color === 'default' ? 'primary' : color || 'primary'}
        />
      }
    />
  );
};
