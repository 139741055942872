// @ts-check
import { useRef } from 'react';
import { Stack, Box } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { TextEditor } from '../../Common/TextEditor';
import { InsertVariablesButton } from '../../../../../businessComponents/InsertVariablesButton';
import { useScreenSize } from '../../../../../Hooks';
import type { TemplateCreationEditorFormProps } from './TemplateCreationDialog.types';

export const TemplateCreationEditorForm = (
  props: TemplateCreationEditorFormProps,
) => {
  const { form } = props;
  const editorRef = useRef(null);
  const { isMobile } = useScreenSize();

  return (
    <Stack flex="1" gap={2}>
      <Stack direction="row" alignItems="center" gap={1}>
        {isMobile && <Typography variant="caption">Insertar</Typography>}
        <Box>
          <InsertVariablesButton editorRef={editorRef} />
        </Box>
      </Stack>
      <TextEditor form={form} editorRef={editorRef} showTitle={false} />
    </Stack>
  );
};
