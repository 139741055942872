//@ts-check
import { useState } from 'react';
import { IconButton } from '../../../../../../../newComponents/IconButton';
import { Popover } from '../../../../../../../newComponents/Popover';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../../../newComponents/Typography';
import { useScreenSize } from '../../../../../../../Hooks';
import { ResumeAuthorizersMobile } from './ResumeAuthorizersMobile';
import { ListAuthorizersStatus } from './ListAuthorizersStatus';

export const ResumeAuthorizersPopover = ({ row }) => {
  const { isMobile } = useScreenSize();
  const [anchorEl, setAnchorEl] = useState(null);

  const isOrdered = Boolean(row?.orderConfig?.authorizeInOrder);
  const authorizations = row?.authorizations ?? [];

  const handleClick = (event, position) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'absence-simple-popover' : undefined;

  if (isMobile) {
    return (
      <Stack>
        <IconButton
          icon={isOrdered ? 'list_ordered' : 'shuffle_line'}
          onClick={handleClick}
        />
        <ResumeAuthorizersMobile
          open={open}
          onClose={handleClose}
          authorizations={authorizations}
          template={row}
          isOrdered={isOrdered}
        />
      </Stack>
    );
  }

  return (
    <>
      <IconButton
        icon={isOrdered ? 'list_ordered' : 'shuffle_line'}
        size="xtraSmall"
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        arrowDirection="right"
      >
        <Stack spacing={1} sx={{ width: 370, pb: 3 }}>
          {/* HEADER */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={3}
          >
            <Stack spacing={1}>
              <Typography variant="h6">
                {isOrdered
                  ? 'Autorizado por orden de lista'
                  : 'Autorizado de forma aleatoria'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {row?.title}
              </Typography>
            </Stack>
            <IconButton icon="close_line" onClick={handleClose} />
          </Stack>

          {/* BODY */}
          <Stack width="100%" sx={{ maxHeight: 250, overflow: 'auto' }}>
            <ListAuthorizersStatus
              authorizations={authorizations}
              isOrdered={isOrdered}
            />
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};
