//@ts-check
import { ConfirmDialog } from '../../../../../../newComponents/Dialog';
import { Stack } from '@mui/material';
import { Typography } from '../../../../../../newComponents/Typography';
import { TextInput } from '../../../../../../newComponents/TextInput';
import { useReactiveVar } from '@apollo/client';
import {
  publishTemplateDialogVar,
  resetOpenPreviewTemplateDialogVar,
  resetPublishTemplateDialogVar,
} from '../Templates.vars';
import { useState } from 'react';
import { usePublishTemplate } from '../Hooks/usePublishTemplate';

export const PublishTemplateDialog = () => {
  const { open, templateId } = useReactiveVar(publishTemplateDialogVar);
  const [comments, setComments] = useState('');
  const { handlePublishTemplate } = usePublishTemplate();

  const handleCloseAll = () => {
    resetPublishTemplateDialogVar();
    resetOpenPreviewTemplateDialogVar();
  };

  const handleClose = () => {
    resetPublishTemplateDialogVar();
  };

  const publishTemplateFn = async () => {
    handlePublishTemplate({
      data: {
        templateId,
        comments,
      },
      onSuccess: () => {
        handleCloseAll();
      },
    });
  };

  const handleChangeComments = (event) => {
    const { value } = event.target;
    setComments(value);
  };

  return (
    <ConfirmDialog
      open={open}
      onClose={() => handleClose()}
      confirmLabel="Publicar plantilla"
      cancelLabel="Cancelar"
      onCancel={() => handleClose()}
      onConfirm={publishTemplateFn}
      title="Publicar plantilla"
      handleCloseIconButton
      DialogProps={{
        fullScreen: false,
        PaperProps: { sx: { width: 370, borderRadius: 2 } },
      }}
      confirmButtonToRight
      ConfirmButtonProps={{
        disabled: !comments?.length || comments.length > 120,
      }}
    >
      <Stack spacing={2} width="100%">
        <Typography variant="body1" color="text.secondary">
          Escribe el resumen de los cambios de esta plantilla
        </Typography>
        <TextInput
          variant="outlined"
          value={comments}
          onChange={handleChangeComments}
          fullWidth
          multiline
          rows={3}
          helperText={`Máximo 120 caracteres. ${comments.length === 0 ? '' : `(${comments.length})`}`}
          error={comments?.length > 120}
        />
      </Stack>
    </ConfirmDialog>
  );
};
