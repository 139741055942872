import { HEXADECIMAL_COLOR_PALETTE } from '../../../../../newComponents/ColorSelector';

export const DEFAULT_TEMPLATE_SETTINGS = {
  title: '',
  description: '',
  color: HEXADECIMAL_COLOR_PALETTE[0].primaryColor,
  secondaryColor: HEXADECIMAL_COLOR_PALETTE[0].secondaryColor,
  category: '',
  subcategory: '',
};

export const DEFAULT_TEMPLATE_EDITOR = {
  myEditor: '',
};

export const DEFAULT_AUTHORIZATIONS = {
  authorizers: [],
  authorizeInOrder: false,
};
