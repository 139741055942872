import { gql } from '@apollo/client';

export const GET_ALL_TEMPLATES_COUNTERS = gql`
  query getAllTemplatesCounters($filter: GetAllTemplatesCountersInput) {
    getAllTemplatesCounters(filter: $filter) {
      ... on TemplatesCountersResult {
        total
        pending
        rejected
        ready_to_publish
        published
      }
    }
  }
`;

export const REJECT_TEMPLATE = gql`
  mutation rejectTemplate($input: RejectTemplateInput) {
    rejectTemplate(input: $input) {
      __typename
      ... on ErrorToRejectTemplate {
        message
      }
      ... on TemplateRejected {
        message
        success
      }
    }
  }
`;

export const APPROVE_TEMPLATE = gql`
  mutation approveTemplate($input: ApproveTemplateInput) {
    approveTemplate(input: $input) {
      __typename
      ... on ErrorToApproveTemplate {
        message
      }
      ... on TemplateApproved {
        message
        success
      }
    }
  }
`;

export const PUBLISH_TEMPLATE = gql`
  mutation publishTemplate($input: PublishTemplateInput) {
    publishTemplate(input: $input) {
      __typename
      ... on ErrorToPublishTemplate {
        message
      }
      ... on TemplatePublished {
        message
        success
      }
    }
  }
`;
