import { Stack } from '@mui/material';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  closestCorners,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { FixedSizeList } from 'react-window';
import { UserItemWrapper } from './UserItemWrapper';
import { ROW_HEIGHT } from '../../CreateDocumentDialog.constants';
import { List } from '../../../../../../newComponents/List';
import { TableHeaderUsers } from './TableHeaderUsers';
import { UserOverlay } from './UserOverlay';
import { useState } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import type { TableUsersProps } from '../../CreateDocumentDialog.types';
import { getUserIdByType } from '../../CreateDocumentDialog.helpers';

export const TableUsers = (props: TableUsersProps) => {
  const {
    users = [],
    setUsers,
    loadedRowCount,
    handleLoadMore,
    selected = [],
    setSelected,
    color,
    forcedIndex,
    orderSignature,
    disabled = false,
    defaultEmployee = null,
  } = props;
  const [activeUser, setActiveUser] = useState(null);
  const { setNodeRef } = useDroppable({ id: 'USERS_LIST' });

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } }),
  );

  const handleDragStart = (event) => {
    const { active } = event;
    const newUser = active.data?.current;
    if (newUser) {
      setActiveUser({ listName: 'USERS_LIST', ...newUser });
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const copiedUsers = [...users];
      const oldIndex = copiedUsers?.findIndex(
        (item) => getUserIdByType(item) === active.id,
      );
      const newIndex = copiedUsers?.findIndex(
        (item) => getUserIdByType(item) === over.id,
      );
      if (oldIndex < 0 || newIndex < 0 || oldIndex === newIndex) return;
      const modifiedAuths = arrayMove(copiedUsers, oldIndex, newIndex);
      setActiveUser(null);
      setUsers(modifiedAuths);
    }
  };

  const mappedRows = users?.map((row) => {
    return {
      ...row,
      id: getUserIdByType(row),
    };
  });

  if (!mappedRows?.length) return null;

  return (
    <Stack>
      <DndContext
        collisionDetection={closestCorners}
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          id="USERS_LIST"
          items={mappedRows}
          strategy={verticalListSortingStrategy}
        >
          <List
            ref={setNodeRef}
            sx={{
              overflowY: 'auto',
              overflowX: 'auto',
              mb: 1,
            }}
          >
            <TableHeaderUsers
              color={color}
              users={users}
              selected={selected}
              setSelected={setSelected}
              defaultEmployee={defaultEmployee}
            />
            <InfiniteLoader
              isItemLoaded={(index) => index < loadedRowCount}
              itemCount={users.length}
              threshold={0}
              loadMoreItems={handleLoadMore}
            >
              {() => {
                return (
                  <FixedSizeList
                    height={
                      mappedRows?.length
                        ? Math.min(mappedRows.length * 76, 372)
                        : 400
                    }
                    width="100%"
                    itemSize={ROW_HEIGHT}
                    itemKey={(index) =>
                      //@ts-ignore
                      mappedRows[index]._id || mappedRows[index].id
                    }
                    itemCount={mappedRows.length}
                    itemData={mappedRows}
                    overscanCount={0}
                  >
                    {({ index, style }) => (
                      <UserItemWrapper
                        index={index}
                        style={style}
                        data={mappedRows}
                        selected={selected}
                        setSelected={setSelected}
                        users={users}
                        setUsers={setUsers}
                        forcedIndex={forcedIndex}
                        orderSignature={orderSignature}
                        disabled={disabled}
                        defaultEmployee={defaultEmployee}
                      />
                    )}
                  </FixedSizeList>
                );
              }}
            </InfiniteLoader>
          </List>
        </SortableContext>

        <DragOverlay>
          {activeUser && (
            <UserOverlay
              selected={activeUser}
              isOrdered={orderSignature}
              users={users}
              selectedUsers={selected}
              forcedIndex={forcedIndex}
            />
          )}
        </DragOverlay>
      </DndContext>
    </Stack>
  );
};
