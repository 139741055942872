// @ts-check
import React from 'react';
import { useController } from 'react-hook-form';
import { Autocomplete } from '@mui/material';
import { TextInput } from '../../../newComponents/TextInput';

export const GENDER_MAP = {
  MALE: 'Masculino',
  FEMALE: 'Femenino',
  OTHER: 'Otro',
};

const OPTIONS = Object.entries(GENDER_MAP).map(([value, label]) => ({
  value,
  label,
}));

/** @param {import('./ControlledGenderInput.types').ControlledGenderInputProps} props */
export const ControlledGenderInput = ({
  control,
  rules,
  textFieldProps = {},
}) => {
  const name = 'gender';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <Autocomplete
      id={textFieldProps.id || name}
      options={OPTIONS}
      getOptionLabel={(option) => option.label}
      value={OPTIONS.find((option) => option.value === value) || null}
      onChange={(_, newValue) => {
        if (!newValue) return onChange('');
        onChange(newValue.value);
      }}
      onBlur={onBlur}
      disabled={textFieldProps.disabled}
      renderInput={(params) => (
        <TextInput
          {...params}
          error={hasError}
          helperText={helperText}
          {...textFieldProps}
        />
      )}
    />
  );
};
