// @ts-check
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Button } from '../../../../../newComponents/Button';
import { Typography } from '../../../../../newComponents/Typography';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { useTemplateDeletion } from '../TemplateHooks';
import { useReactiveVar } from '@apollo/client';
import {
  deleteTemplateDialogVar,
  resetDeleteTemplateDialogVar,
} from '../../../../MyCompany/Settings/Preferences/Templates/Templates.vars.js';

export const TemplateDeleteConfirmationDialog = () => {
  const { open, templateId, onComplete } = useReactiveVar(
    deleteTemplateDialogVar,
  );
  const { handleDeleteTemplate } = useTemplateDeletion();

  const handleClose = () => {
    resetDeleteTemplateDialogVar();
  };

  const handleConfirm = () => {
    if (!templateId) return;
    handleDeleteTemplate({
      data: { templateId },
      onSuccess: () => {
        handleClose();
        if (onComplete) onComplete();
      },
    });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={handleClose}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{ display: 'flex', alignItems: 'center', p: '24px' }}
        component="div"
      >
        <Icon
          icon="delete_bin_line"
          height="26px"
          onClick={handleClose}
          sx={{ cursor: 'pointer' }}
          color="error"
        />
        <Typography
          variant="h6"
          color="error"
          sx={{ marginLeft: '16px', fontSize: '18px' }}
        >
          Eliminar plantilla
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: '0px 20px' }}>
        <Typography variant="body1">
          ¿Estás seguro que quieres eliminar esta plantilla?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button
          variant="contained"
          color="error"
          onClick={handleConfirm}
          sx={{
            height: '36px',
            padding: '6px 16px',
            borderRadius: '8px',
          }}
        >
          Eliminar
        </Button>
        <Button color="default" onClick={handleClose} variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
