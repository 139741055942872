// @ts-check
import React from 'react';
import { Skeleton, useTheme } from '@mui/material';
import { CHECKBOX_WIDTH } from './ScrollableTable.constants';
import { StyledTableRow, StyledTableCell } from './ScrollableTable.styles';

/** @param {import('./ScrollableTable.types').ScrollableTableRowProps} props */
export const ScrollableTableRow = ({ index, style, data }) => {
  const theme = useTheme();
  const {
    columns,
    rows,
    onSelect,
    isSelected,
    getRowId,
    checkboxSelection,
    renderRowCheckbox,
    addHoverRowStyle,
  } = data;

  const row = rows[index];
  if (!row) {
    return (
      <StyledTableRow style={style}>
        <StyledTableCell sx={{ width: '100%' }}>
          <Skeleton variant="rounded" width="100%" />
        </StyledTableCell>
      </StyledTableRow>
    );
  }

  const rowId = getRowId(row);
  const selected = isSelected(rowId);

  return (
    <StyledTableRow
      style={{ ...style }}
      sx={{
        ...(addHoverRowStyle && {
          '&:hover': {
            background: theme.newPalette.actionStates.hover,
          },
        }),
      }}
    >
      {checkboxSelection && (
        <StyledTableCell sx={{ width: CHECKBOX_WIDTH }}>
          {renderRowCheckbox({
            checked: selected,
            onChange: (event) => onSelect({ id: rowId, event }),
          })}
        </StyledTableCell>
      )}

      {columns.map((column, columnIndex) => {
        const {
          field,
          renderCell,
          align,
          minWidth,
          width,
          maxWidth,
          flex,
          type,
        } = column;
        const value = row[field];
        const sx = { px: '10px', boxSizing: 'border-box' };
        if (minWidth) sx.minWidth = minWidth;
        if (width) sx.width = width;
        if (maxWidth) sx.maxWidth = maxWidth;
        if (flex) sx.flex = flex;
        if (align === 'left') sx.justifyContent = 'flex-start';
        if (align === 'right') sx.justifyContent = 'flex-end';
        if (align === 'center') sx.justifyContent = 'center';

        return (
          <StyledTableCell
            className={type === 'actions' ? 'ScrollableTableCell-actions' : ''}
            key={`ScrollableTableCell-${field}-${columnIndex}`}
            sx={sx}
          >
            {renderCell ? renderCell({ row }) : value}
          </StyledTableCell>
        );
      })}
    </StyledTableRow>
  );
};
