import { useMutation, useReactiveVar } from '@apollo/client';
import { VERIFY_EMPLOYEE_DATA } from '../../gql';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export type EmployeeDataToVerify = {
  sendAll: boolean;
  employeeIds: string[];
  workCenterIds: string[];
  workTitles: string[];
  groups: string[];
  selectedVariables: string[];
};

export type VerifyEmployeeDataInput = {
  data: EmployeeDataToVerify;
  onSuccess: (response?: any) => void;
};

export const useVerifyEmployeeDataToCreateData = () => {
  const company = useReactiveVar(currentCompanyVar);
  const [verifyEmployeeData] = useMutation(VERIFY_EMPLOYEE_DATA);

  const handleVerifyEmployeeData = async (input: VerifyEmployeeDataInput) => {
    try {
      const { data, onSuccess } = input;

      if (!company?._id) {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: 'Falta información para validar los usuarios.',
        });
        return;
      }

      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Validando información...',
      });

      const res = await verifyEmployeeData({
        variables: {
          input: {
            companyId: company._id,
            employeeIds: data.employeeIds,
            workCenterIds: data.workCenterIds,
            workTitles: data.workTitles,
            groups: data.groups,
            selectedVariables: data.selectedVariables,
            sendAll: data.sendAll,
          },
        },
      });

      const response = res.data?.verifyEmployeeData;
      if (response?.__typename === 'ErrorToVerifyEmployeeData') {
        globalSnackbarVar({
          show: true,
          severity: 'error',
          message: response?.message || 'No se pudo validar la información',
        });
        return;
      }

      const errors = response?.errorsData;
      const success = response?.successData;
      if (!errors?.length) {
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Empleados asignados correctamente',
        });
      }

      onSuccess({ errors, success });
    } catch (error) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error, contacte a Sora',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleVerifyEmployeeData };
};
