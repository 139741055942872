//@ts-check
import { useReactiveVar } from '@apollo/client';
import { AdminsAutocomplete } from './AdminsAutocomplete';
import { setTemplateItemVar, templateItemVar } from '../Templates.vars';
import { buildAdminAuthorizer } from '../Templates.helpers';

export const SearchAuthorizers = () => {
  const templateItem = useReactiveVar(templateItemVar);
  const authorizersList = templateItem?.authorizers ?? [];
  const currTemplate = templateItem?.templateToUpdate ?? {};
  const oldOrderConfig = currTemplate?.orderConfig ?? {};
  const authorizeInOrder = Boolean(oldOrderConfig?.authorizeInOrder);

  const handleAdd = (admin) => {
    const selectedTemplate = templateItem?.templateToUpdate;
    const templateAuthorizations = selectedTemplate?.authorizations ?? [];

    const userFound = templateAuthorizations.find(
      (auth) => auth.user?._id === admin._id,
    );

    const currAuthorizers = templateItem?.authorizers ?? [];

    const adminFormatted = buildAdminAuthorizer({
      admin,
      statusAuth: userFound?.status,
    });

    setTemplateItemVar({
      authorizers: [...currAuthorizers, adminFormatted],
    });

    if (!authorizeInOrder && templateItem.isOrdered) {
      setTemplateItemVar({
        updateAferSelectAdmin: !templateItem.updateAferSelectAdmin,
      });
    }
  };

  const unSelectItem = (admin) => {
    const currAuthorizers = templateItem?.authorizers ?? [];
    const currSelectedAuthorizers = templateItem?.selectedAuthorizers ?? [];
    const newSelectedAuthorizers = currAuthorizers.filter(
      (selectedAdmin) => selectedAdmin._id !== admin._id,
    );

    if (currSelectedAuthorizers?.length) {
      const newSelected = currSelectedAuthorizers.filter(
        (selectedId) => selectedId !== admin._id,
      );
      setTemplateItemVar({
        authorizers: newSelectedAuthorizers,
        selectedAuthorizers: newSelected,
      });
      return;
    }

    setTemplateItemVar({ authorizers: newSelectedAuthorizers });
  };

  return (
    <AdminsAutocomplete
      handleAdd={handleAdd}
      unSelectItem={unSelectItem}
      items={authorizersList}
    />
  );
};
