//@ts-check
import { Stack } from '@mui/material';
import { SearchAuthorizers } from './SearchAuthorizers';
import { OrderAuthorizersComponent } from './OrderAuthorizersComponent';
import { TableAuthorizers } from './TableAuthorizers';
import { useReactiveVar } from '@apollo/client';
import { setTemplateItemVar, templateItemVar } from '../Templates.vars';
import { useEffect } from 'react';
import {
  sortAuthorizersByStatus,
  sortAuthorizersDefault,
} from '../Templates.helpers';

export const TemplateAuthorizersUpdateForm = ({ form }) => {
  const templateItem = useReactiveVar(templateItemVar);
  const currTemplate = templateItem?.templateToUpdate ?? {};
  const oldOrderConfig = currTemplate?.orderConfig ?? {};
  const authorizations = currTemplate?.authorizations ?? [];
  const authorizeInOrder = Boolean(oldOrderConfig?.authorizeInOrder);

  useEffect(() => {
    form.setValue('authorizers', templateItem.authorizers ?? []);
    form.setValue('authorizeInOrder', Boolean(templateItem.isOrdered));
  }, [templateItem.authorizers, templateItem.isOrdered, form]);

  useEffect(() => {
    const allAuthorized = templateItem.authorizers?.some(
      (auth) => auth.status === 'AUTHORIZED',
    );

    if (!allAuthorized) return;

    if (!authorizeInOrder && templateItem.isOrdered) {
      const sorted = sortAuthorizersByStatus({
        authorizers: templateItem.authorizers,
      });
      setTemplateItemVar({ authorizers: sorted });
      return;
    } else {
      if (!authorizations?.length) return;
      const currAuthorizers = templateItem?.authorizers ?? [];
      const sorted = sortAuthorizersDefault({
        oldAuths: authorizations,
        currAuths: currAuthorizers,
      });

      setTemplateItemVar({ authorizers: sorted });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    templateItem.isOrdered,
    authorizeInOrder,
    templateItem.updateAferSelectAdmin,
  ]);

  return (
    <Stack spacing={2} width="100%">
      <SearchAuthorizers />
      <OrderAuthorizersComponent />
      <TableAuthorizers />
    </Stack>
  );
};
