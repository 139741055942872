import { useEffect, useRef } from 'react';
import { Stack, Box } from '@mui/material';
import { usePermissionChecker, useScreenSize } from '../../../../../Hooks';
import { Typography } from '../../../../../newComponents/Typography';
import { InsertVariablesButton } from '../../../../../businessComponents/InsertVariablesButton';
import { Button } from '../../../../../newComponents/Button';
import { Icon } from '../../../../../components/Icons/Icons';
import { TextEditor } from '../../Common/TextEditor';
import type {
  CreateDocumentDialogEditorFormProps,
  HandleInsertTemplateParams,
} from '../CreateDocumentDialog.types';
import { TemplatesListContainerDialog } from '../../../../MyCompany/Settings/Preferences/Templates/TemplatesListDialog/TemplatesListContainerDialog';
import { openTemplatesListDialogVar } from '../../../../MyCompany/Settings/Preferences/Templates/Templates.vars';
import {
  currentCompanyVar,
  globalBackdropVar,
} from '../../../../../cache.reactiveVars';
import { useReactiveVar } from '@apollo/client';
import { setCreateDocumentDialogVar } from '../CreateDocumentDialog.vars';
import { applySanitizers } from '../../../../../utils/sanitizers';

export const CreateDocumentEditorForm = (
  props: CreateDocumentDialogEditorFormProps,
) => {
  const { form } = props;
  const { isMobile } = useScreenSize();
  const userHasPermissionToRead = usePermissionChecker({
    permission: 'templates',
    action: 'read',
  });
  const company = useReactiveVar(currentCompanyVar);
  const editorRef = useRef(null);
  const watchMyEditor = form.watch('myEditor');

  const openTemplatesList = () => {
    globalBackdropVar({ open: true, text: 'Obteniendo plantillas...' });
    openTemplatesListDialogVar({ open: true });
  };

  const handleInsertTemplate = (data: HandleInsertTemplateParams) => {
    if (!data) return;
    const employeeRecordsTags = company.configs?.employeeRecordsTags ?? [];
    const categories = data?.categories;

    const currCategory = employeeRecordsTags.find((tag) => {
      if (tag.category) {
        const text = applySanitizers({
          text: tag.category,
          sanitizers: ['capitalize'],
        });
        return text === categories?.category;
      }

      return null;
    });

    if (currCategory) {
      const categoryId = currCategory?._id;
      const subCategories = currCategory?.subcategories ?? [];
      const subcategoryFound = subCategories.find((sub) => {
        if (sub?.subcategory) {
          const text = applySanitizers({
            text: sub.subcategory,
            sanitizers: ['capitalize'],
          });
          return text === categories?.subcategory;
        }
        return null;
      });
      if (subcategoryFound) {
        const subcategoryId = subcategoryFound?._id;
        const combinedId = `${categoryId}-${subcategoryId}`;
        form.setValue('categories', {
          category: currCategory.category,
          subcategory: subcategoryFound.subcategory,
        });
        setCreateDocumentDialogVar({
          employeeRecordTag: [
            {
              category: currCategory?.category,
              categoryId: categoryId,
              color: currCategory?.color,
              combinedId,
              subcategory: subcategoryFound?.subcategory,
              subcategoryId,
            },
          ],
        });
      }
    }

    form.setValue('title', data.title);
    form.setValue('myEditor', data.htmlContent);
  };

  useEffect(() => {
    if (!watchMyEditor) {
      form.setValue('selectedVariables', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchMyEditor]);

  return (
    <Stack flex="1" gap={2}>
      <Typography variant="body2" color="grey">
        Contenido
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={isMobile ? 'flex-end' : 'flex-start'}
        gap={1}
      >
        {isMobile && <Typography variant="caption">Insertar</Typography>}
        <Box>
          <InsertVariablesButton editorRef={editorRef} />
        </Box>
        <Button
          variant="contained"
          color="default"
          size="small"
          startIcon={<Icon icon="file_list_3_line" height="18px" />}
          onClick={openTemplatesList}
          disabled={!userHasPermissionToRead}
          tooltipText={
            userHasPermissionToRead
              ? ''
              : 'No tienes permisos para realizar esta acción'
          }
          tooltipArrow
          tooltipPlacement="top"
        >
          {isMobile ? 'Plantilla' : 'Insertar plantilla'}
        </Button>
      </Stack>
      <TextEditor form={form} editorRef={editorRef} showTitle={false} />
      <TemplatesListContainerDialog onOpenTemplate={handleInsertTemplate} />
    </Stack>
  );
};
