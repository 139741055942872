//@ts-check
import { usePermissionChecker, useScreenSize } from '../../../../../../Hooks';
import { globalSnackbarVar } from '../../../../../../cache.reactiveVars';
import { Button } from '../../../../../../newComponents/Button';
import { helperMessagePublishTemplate } from '../Templates.helpers';
import {
  publishTemplateDialogVar,
  resetOpenPreviewTemplateDialogVar,
  resetOpenTemplatesListDialogVar,
} from '../Templates.vars';

export const PublishTemplateAction = ({ template, onOpenTemplate }) => {
  const { isMobile } = useScreenSize();
  const userHasPermissionToPublish = usePermissionChecker({
    permission: 'publishTemplates',
    action: 'update',
  });

  const buttonProps = helperMessagePublishTemplate({
    userHasPermissionToAuthorize: userHasPermissionToPublish,
    status: template?.status,
  });

  const publishTemplate = () => {
    if (!template?._id) {
      return globalSnackbarVar({
        show: true,
        message: 'No se encontró la plantilla a publicar',
        severity: 'error',
        duration: 5000,
      });
    }
    publishTemplateDialogVar({
      open: true,
      templateId: template?._id,
    });
  };

  const handleSelectTemplate = () => {
    if (onOpenTemplate) {
      onOpenTemplate(template);
    }
    resetOpenPreviewTemplateDialogVar();
    resetOpenTemplatesListDialogVar();
  };

  if (onOpenTemplate) {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={handleSelectTemplate}
        // disabled={buttonProps.disabled}
        // tooltipText={buttonProps.message}
        // tooltipArrow
        // tooltipPlacement="top"
      >
        {isMobile ? 'Seleccionar' : 'Seleccionar plantilla'}
      </Button>
    );
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={publishTemplate}
      disabled={buttonProps.disabled}
      tooltipText={buttonProps.message}
      tooltipArrow
      tooltipPlacement="top"
    >
      {isMobile ? 'Publicar' : 'Publicar plantilla'}
    </Button>
  );
};
