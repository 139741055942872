//@ts-check
import { Box, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { DataGrid } from '../../../../../../newComponents/DataGrid';
import { openPreviewTemplateDialogVar } from '../Templates.vars';
import { TEMPLATES_TABLE_SIMPLE_COLUMNS } from '../TemplatesTable/TemplatesTableColumns';
import { gridTemplatesTableStyle } from '../Templates.styles';
import { esES } from '@mui/x-data-grid';

export const TemplatesSimpleTable = ({
  templates = { total: 0, templates: [] },
  page,
  perPage,
  setPage,
  setPerPage,
  loading,
}) => {
  const theme = useTheme();

  const handlePreviewTemplateDialog = useCallback((template) => {
    if (!template) return null;
    openPreviewTemplateDialogVar({
      open: true,
      template: template,
    });
  }, []);

  const columns = useMemo(() => {
    return TEMPLATES_TABLE_SIMPLE_COLUMNS({
      handlePreviewTemplateDialog,
    });
  }, [handlePreviewTemplateDialog]);

  return (
    <Box sx={{ display: 'flex', height: 600 }}>
      <DataGrid
        columns={columns}
        rows={templates.templates}
        rowCount={templates.total}
        getRowId={(row) => row._id || row.id}
        page={page}
        pageSize={perPage}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPerPage(newPageSize)}
        rowsPerPageOptions={[10, 25, 50]}
        rowBuffer={10}
        paginationMode="client"
        loading={loading}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        checkboxSelection={false}
        rowHeight={60}
        headerHeight={56}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        hideFooterSelectedRowCount
        disableSelectionOnClick
        sx={{ ...gridTemplatesTableStyle({ theme }) }}
      />
    </Box>
  );
};
