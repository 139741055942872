// @ts-check
import React from 'react';
import { useController } from 'react-hook-form';
import { Autocomplete } from '../../../newComponents/Autocomplete';
import { TextInput } from '../../../newComponents/TextInput';

export const MARITAL_STATUS_MAP = {
  SINGLE: 'Soltero',
  MARRIED: 'Casado',
  FREE_UNION: 'Unión libre',
  SEPARATED: 'Separado',
  DIVORCED: 'Divorciado',
  WIDOW: 'Viudo',
};

const OPTIONS = Object.entries(MARITAL_STATUS_MAP).map(([value, label]) => ({
  value,
  label,
}));

/** @param {import('./ControlledMaritalStatusInput.types').ControlledMaritalStatusInputProps} props */
export const ControlledMaritalStatusInput = ({
  control,
  rules,
  textFieldProps = {},
}) => {
  const name = 'maritalStatus';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  });

  const hasError = Boolean(error);
  const helperText = error?.message || '';

  return (
    <Autocomplete
      id={textFieldProps.id || name}
      options={OPTIONS}
      getOptionLabel={(option) => option.label}
      value={OPTIONS.find((option) => option.value === value) || null}
      onChange={(_, newValue) => {
        if (!newValue) return onChange('');
        onChange(newValue.value);
      }}
      onBlur={onBlur}
      disabled={textFieldProps.disabled}
      renderInput={(params) => (
        <TextInput
          {...params}
          error={hasError}
          helperText={helperText}
          {...textFieldProps}
        />
      )}
    />
  );
};
