import { Stack, useTheme } from '@mui/material';
import { useState } from 'react';
import { Checkbox } from '../../../../../../newComponents/Checkbox';
import { Typography } from '../../../../../../newComponents/Typography';
import type { TableHaderUsersProps } from '../../CreateDocumentDialog.types';
import { getUserIdByType } from '../../CreateDocumentDialog.helpers';

export const TableHeaderUsers = (props: TableHaderUsersProps) => {
  const { color, users = [], selected = [], setSelected } = props;
  const theme = useTheme();
  const lighterColor = theme.newPalette[color]?.lighter;
  const mainColor = theme.newPalette[color]?.main;
  const [checked, setChecked] = useState(false);

  const handleSelectionModelChange = (isChecked) => {
    if (!isChecked && users?.length === selected?.length) {
      setChecked(false);
      setSelected([]);
      return;
    }

    let selectedUsers = [];

    if (users?.length) {
      selectedUsers = users.map((user) => getUserIdByType(user));
    }

    if (!isChecked && users?.length !== selected?.length) {
      setChecked(true);
      setSelected(selectedUsers);
      return;
    }

    if (isChecked && users?.length && !selected?.length) {
      setChecked(true);
      setSelected(selectedUsers);
      return;
    }

    if (isChecked && users?.length !== selected?.length) {
      setChecked(true);
      setSelected(selectedUsers);
      return;
    }
  };

  const allItemsSelected = selected?.length
    ? users?.length === selected?.length
    : false;

  if (!users?.length) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        px: 2,
        py: 2,
        background: lighterColor || theme.newPalette.grey.transparent24,
        height: 56,
      }}
    >
      <Checkbox
        sx={{
          '&.MuiCheckbox-root': {
            color: mainColor,
          },
        }}
        value={checked}
        checked={Boolean(allItemsSelected)}
        indeterminate={
          users?.length !== selected?.length && Boolean(selected?.length)
        }
        onChange={(e) => handleSelectionModelChange(e.target.checked)}
      />
      <Typography
        variant="subtitle2"
        color={color === 'default' ? 'text.secondary' : color || 'primary'}
      >
        Nombre
      </Typography>
    </Stack>
  );
};
