//@ts-check
import { DialogContent, Stack } from '@mui/material';
import { Dialog } from '../../../../../../../components/Dialogs/Dialog';
import { menuActionsStyles } from '../../../../../../RHPod/Absence/EmployeeAbsence.styles';
import { Timeline } from '../../../../../../../newComponents/Timeline';
import { HistoryTemplateItem } from './HistoryTemplateItem';
import { Typography } from '../../../../../../../newComponents/Typography';
import { IconButton } from '../../../../../../../newComponents/IconButton';

export const HistoryTemplateMobile = ({
  open,
  onClose,
  history = [],
  template,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      onCloseXButton={false}
      showCloseButton={false}
      sx={{
        ...menuActionsStyles,
      }}
      zoomTransition={false}
    >
      <DialogContent sx={{ p: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h6">{template?.title}</Typography>
          </Stack>
          <IconButton icon="close_line" onClick={onClose} />
        </Stack>
        <Stack height={400} overflow="auto">
          <Timeline
            sx={{
              '& .MuiTimelineItem-root:before': {
                flex: 0,
                padding: 0,
              },
              '&.MuiTimeline-root': {
                padding: 0,
              },
            }}
          >
            {history?.map((item, index) => (
              <HistoryTemplateItem
                key={index}
                action={item.action}
                comments={item.comments}
                createdBy={item.createdBy}
                timestamps={item.timestamps}
              />
            ))}
          </Timeline>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
